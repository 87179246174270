import {
  OUTLETS_LIST, OUTLETS_ERROR, OUTLETS_LOADING, OUTLETS_ITEM
} from '../state-constants';
import api, { OUTLET_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';
import { setUsersLoading } from './users';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  outlet: null,
  outletsList: []
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case OUTLETS_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case OUTLETS_ITEM:
      return {
        ...state,
        outlet: action.outlet
      };

    case OUTLETS_LIST:
      return {
        ...state,
        outletsList: action.outletsList
      };

    case OUTLETS_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null,
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: OUTLETS_ERROR, error: error?.response?.data?.message ?? error });
};

export const setOutletLoading = loading => {
  return dispatch => dispatch({ type: OUTLETS_LOADING, loading });
};

export const setOutletItem = outlet => {
  return dispatch => dispatch({ type: OUTLETS_ITEM, outlet });
};

export const fetchAllOutlets = (type, offset, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOutletLoading(true));
      api.get(type ? OUTLET_APIS.outlets : `${OUTLET_APIS.outletsAll}?offset=${offset ? offset : 0}&key=${key ? key : null}&sortConfig=${sortConfig ? sortConfig : null}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: OUTLETS_LIST,
            outletsList: data?.data
          });

          dispatch(setOutletLoading(false));
          resolve(data);
        })
        .catch(err => {
          dispatch(setOutletLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setOutletLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const addOutlet = (payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOutletLoading(true));
      api.post(OUTLET_APIS.outlets, payload)
        .then(response => {
          if (response.status > 399) {
            // dispatch(clearError());
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(setOutletLoading(false));
          if (!searchQuery) dispatch(fetchAllOutlets());
          resolve({});
        })
        .catch(err => {
          dispatch(setOutletLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setOutletLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updateOutlet = (id, payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOutletLoading(true));
      api.put(`${OUTLET_APIS.outlets}/${id}`, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          } else if (response?.data?.status && response?.data?.data && !searchQuery) {
            dispatch(fetchAllOutlets());
          }
          dispatch(setOutletLoading(false));
          resolve({ response });
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const deleteOutlet = (id, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOutletLoading(true));
      api.delete(`${OUTLET_APIS.outlets}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          if (!searchQuery) dispatch(fetchAllOutlets());
          dispatch(setOutletLoading(false));
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const fetchOutletById = (id) => {
  return dispatch => new Promise((resolve, reject) => {
    if (id) {
      try {
        dispatch(setOutletLoading(true));
        api.get(`${OUTLET_APIS.outlets}/${id}`)
          .then(response => {
            if (response.status > 399) {
              dispatch(setUsersLoading(false));
              handleErr(dispatch, COMMON_API_ERR_MSG);
              handle400Rejections(response.status, reject);
              return;
            }

            const { data } = response;

            if (!data?.data) {
              resolve({});
              return;
            }

            dispatch(setOutletLoading(false));
            dispatch(setOutletItem(data?.data));

            resolve(data?.data);
          })
          .catch(err => {
            dispatch(setOutletLoading(false));
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        dispatch(setOutletLoading(false));
        handleErr(dispatch, error);
        reject(error);
      }
    } else {
      dispatch(setOutletItem(null));
    }
  });
};

export const searchOutlets = (keyword, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    setOutletLoading(true);
    try {
      api
        .post(OUTLET_APIS?.searchOutlet, { keyword, key, sortConfig })
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (data) {
            dispatch({ type: OUTLETS_LIST, outletsList: data?.data });
            dispatch(setOutletLoading(false));
            resolve(data?.data);
            return;
          }

          if (!data?.data) {
            resolve({});
            return;
          }
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    }
    catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  });
};
