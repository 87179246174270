import {
  OFFERS_LIST, OFFERS_ERROR, OFFERS_LOADING, OFFERS_ITEM
} from '../state-constants';
import api, { OFFERS_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';
import { setUsersLoading } from './users';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  offer: null,
  offersList: []
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case OFFERS_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case OFFERS_ITEM:
      return {
        ...state,
        offer: action.offer
      };

    case OFFERS_LIST:
      return {
        ...state,
        offersList: action.offersList
      };

    case OFFERS_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null,
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: OFFERS_ERROR, error: error?.response?.data?.message ?? error });
};

export const setOfferLoading = loading => {
  return dispatch => dispatch({ type: OFFERS_LOADING, loading });
};

export const setOfferItem = offer => {
  return dispatch => dispatch({ type: OFFERS_ITEM, offer });
};

export const fetchAllOffers = () => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOfferLoading(true));
      api.get(OFFERS_APIS.offers)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: OFFERS_LIST,
            offersList: data?.data
          });

          dispatch(setOfferLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setOfferLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setOfferLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updatePromotionalOffer = id => {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(setOfferLoading(true));
    api.put(OFFERS_APIS.updatePromotionalOffer + `/${id}`)
      .then(response => {
        resolve(response?.data);
      })
    // dispatch(setOfferLoading(false));
  })
}

export const addOffer = payload => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOfferLoading(true));
      api.post(OFFERS_APIS.offers, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          payload.id = response?.data?.data;

          dispatch(setOfferLoading(false));
          dispatch(setOfferItem(payload));
          resolve(payload.id);
        })
        .catch(err => {
          dispatch(setOfferLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setOfferLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updateOffer = (id, payload) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOfferLoading(true));
      api.put(`${OFFERS_APIS.offers}/${id}`, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(setOfferLoading(false));
          dispatch(fetchAllOffers());
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const deleteOffer = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOfferLoading(true));
      api.delete(`${OFFERS_APIS.offers}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(fetchAllOffers());
          dispatch(setOfferLoading(false));
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const fetchOfferById = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setOfferLoading(true));
      api.get(`${OFFERS_APIS.offers}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch(setOfferLoading(false));
          dispatch(setOfferItem(data?.data));

          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setOfferLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setOfferLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};
