import api, { Notification_APIS } from "../../config/api-config";
import { NATIFICATION_ERROR,NATIFICATION_LOADING,NATIFICATION_SUCCESS } from "../state-constants";

const initialState = {
  isError: false,
  isLoading: false,
  notification: null,
};
export const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NATIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
        notification: null,
        isLoading: false,
      };
    case NATIFICATION_LOADING:
      return {
        ...state,
        notification: null,
        isLoading: action.isLoading,
        error:null
      };
    case NATIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
        notification: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
export const addNotification = (params) => async(dispatch) => {
    dispatch({ type:NATIFICATION_LOADING });
    api.post(Notification_APIS.notifications,params).then(res=>{
    if(res.data.status){
      dispatch({ type:NATIFICATION_SUCCESS});
    }
    })
  .catch( (error)=> {
    console.log("error: ", error);
    dispatch({ type: NATIFICATION_ERROR, error });
  })
};
export const deleteNotification = (params) => async(dispatch) => {
    dispatch({ type:NATIFICATION_LOADING });
    api.delete(Notification_APIS.notifications+"/"+params).then(res=>{
    if(res.data.status){
      dispatch({ type:NATIFICATION_SUCCESS});
    }
    })
  .catch( (error)=> {
    console.log("error: ", error);
    dispatch({ type: NATIFICATION_ERROR, error });
  })
};
export const editNotification = (formdata, id) => async(dispatch) => {
    dispatch({ type:NATIFICATION_LOADING });
    api.put(Notification_APIS.notifications+"/"+id,formdata).then(res=>{
    if(res.data.status){
      dispatch({ type:NATIFICATION_SUCCESS});
    }
    })
  .catch( (error)=> {
    console.log("error: ", error);
    dispatch({ type: NATIFICATION_ERROR, error });
  })
};
