import React, { useEffect, useState } from "react";
import Address from "./component/address";
import Package from "./component/package";
import Payment from "./component/payment";
import Loader from "../../components/loader";
import { useParams } from "react-router-dom";
import "./component/styles.scss";



const UpdateVendor = ({ user }) => {
    const params = useParams();

    const initialData = {
        vendor: user,
        selectedPlan: null,
        listingNumber: 2
    }

    const [isLoading, setisLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialData);

    useEffect(() => {
        if (!user?.address || !user?.city || !user?.country) {
            setStep(1);
        } else {
            setStep(2);
        }
        if (params.type === "2") {
            setFormData({ ...formData, listingNumber: 1 })
        }
    }, [])


    return (
        <div>
            {isLoading && <Loader />}

            {step === 1 && <Address
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
                setisLoading={setisLoading}
            />}

            {step === 2 && <Package
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
                setisLoading={setisLoading}
                type={params.type}
                user={user}
            />}

            {step === 3 && <Payment
                formData={formData}
                setFormData={setFormData}
                setisLoading={setisLoading}
                type={params.type}
            />}
        </div>
    );
};

export default UpdateVendor;
