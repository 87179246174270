import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Typography, AppBar, Toolbar, Modal, TextField, Autocomplete, Breadcrumbs } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as CurrencyFormat from 'react-currency-format';
import { ArrowBackIosNew, MoreHoriz } from '@mui/icons-material';
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { baseURL, PACKAGES } from "../../../config/constants";
import { PLANS_APIS } from "../../../config/api-config";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Package = ({ setStep, formData, setFormData, setisLoading, type, user }) => {
    const navigate = useNavigate();

    const [plans, setPlans] = useState([]);
    const [ignorePackage, setIgnorePackage] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);

    useEffect(() => {
        setisLoading(true)

        axios.get(baseURL + PLANS_APIS.all).then(response => {
            if (response.status === 200) {
                setPlans(response.data.data)
            }
            setisLoading(false)
        }).catch(err => {
            setisLoading(false)
        })

        if (type === "2") {
            setIgnorePackage([PACKAGES.SINGLE_LISTING, "7cc2g350-9d9c-51ed-9484-5dfe7f6834df"]);
        } else if (type === "3") {
            if (user?.package === PACKAGES.SINGLE_LISTING) {
                setIgnorePackage([PACKAGES.SINGLE_LISTING]);
            } else if (user?.package === PACKAGES.MULTI_LISTING) {
                setIgnorePackage([PACKAGES.SINGLE_LISTING, PACKAGES.MULTI_LISTING]);
            } else {
                setIgnorePackage([PACKAGES.SINGLE_LISTING, PACKAGES.MULTI_LISTING, "7cc2g350-9d9c-51ed-9484-5dfe7f6834df"]);
            }
        }
    }, [])

    const onSelectPlan = (selectedPlanId) => {
        const selectedPlan = plans?.find((plan) => plan?.id === selectedPlanId);

        if (!selectedPlan) {
            Swal.fire('Invalid', 'please select plans', 'error');
            return;
        }

        setFormData({ ...formData, selectedPlan: selectedPlan });
        if (selectedPlanId === PACKAGES.MULTI_LISTING) {
            setModalStatus(true);
        } else {
            setStep(3);
        }
    };

    const handleChange = (event, value) => {
        if (value >= 2 && value <= 10) {
            setFormData({ ...formData, listingNumber: value });
        }
    };

    const handleSave = () => {
        setModalStatus(false);
        setStep(3);
    };

    return (
        <div className="main-package">
            <AppBar position="static" className="appbar">
                <Toolbar>
                    <img src="images/lets.png" alt="Logo" />
                </Toolbar>
            </AppBar>
            <div className="bradcrumb" role="presentation">
                <Grid container>
                    <Grid item xs={11} sm={11} lg={11} className="flex items-center">
                        <div className="mr4 c-pointer left-arrow ">
                            <span onClick={() => {
                                if (type === "2" || type === "3") {
                                    navigate("/venues");
                                } else {
                                    navigate("/");
                                }
                            }}><ArrowBackIosNew /></span>
                        </div>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <span className="c-pointer" onClick={() => {
                                if (type === "2" || type === "3") {
                                    navigate("/venues");
                                } else {
                                    navigate("/");
                                }
                            }}>Back</span>
                            <Typography>Choose Package</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
                        <MoreHoriz />
                    </Grid>
                </Grid>
            </div>
            <Grid item container sm={12} md={12} lg={9} className="m-auto mb-3 packages-holder-main flex items-center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="mt-3 register-vendor-header">
                    <h2 className="h1 weight-semi-bold">
                        {type === "1" && <span>Select your preferred option</span>}
                        {type === "2" && <span>Upgrade your listing number</span>}
                        {type === "3" && <span>Upgrade your package</span>}

                    </h2>
                    <Typography variant="body1" gutterBottom className="p-0 pt-1">
                        Please choose the right package for your venue from the options below
                    </Typography>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="packages-holder-box flex items-center justify-center">
                    {plans?.map((plan) => {
                        if (!ignorePackage.includes(plan.id)) {
                            return <Grid key={plan.id} item xs={12} sm={12} md={6} lg={4} className="single-package">
                                <Item className="single-package-inner">
                                    <h5 variant="h6" className="p-0 pt-3" style={{ color: "#000" }}>
                                        {plan.title}
                                    </h5>
                                    <ul className="package-list">
                                        {plan?.features.map((feature, key) => (
                                            <MenuItem key={key} className="p-0" >
                                                {feature?.value}
                                            </MenuItem>
                                        ))}
                                    </ul>
                                    <div className="per-month pt-3 pb-3">
                                        <CurrencyFormat value={plan.pricePerYear} displayType={'text'} thousandSeparator={true} prefix={plan.currency + ' '} renderText={
                                            value => <p variant="p" className="p-0 m-0"> {value} for 12 months</p>
                                        } />
                                    </div>
                                    <div className="select-btn">
                                        <button className="btn" onClick={() => onSelectPlan(plan.id)} >SELECT</button>
                                    </div>
                                </Item>
                            </Grid>
                        }
                    })}
                </Grid>
            </Grid>
            <Modal open={modalStatus} onClose={() => setModalStatus(false)} className="flex items-center justify-center">
                <div className="modal-content">
                    <h2 className="flex items-center justify-center">{type === "2" ? <span>Add number of listings</span> : <span>Select number of listings</span>}</h2>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={8}>
                            <Autocomplete
                                disablePortal
                                options={type === "2" ? Array.from({ length: 10 }, (_, index) => index + 1) : Array.from({ length: 9 }, (_, index) => index + 2)}
                                getOptionLabel={(option) => option.toString()}
                                value={formData.listingNumber}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} label="Select number of listing" />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <div className="select-btn">
                                <button className="modal-submit" onClick={handleSave} >Save</button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
};

export default Package;
