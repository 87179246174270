import React, { useEffect, useState } from "react";
import { Grid, Typography, AppBar, Toolbar } from "@mui/material";
import axios from "axios";
import Swal from 'sweetalert2';
import { baseURL, PACKAGES } from "../../../config/constants";
import { VENDOR_APIS } from "../../../config/api-config";
import { useNavigate } from "react-router-dom";

const Payment = ({ formData, setisLoading, called }) => {
    const [price, setPrice] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        window.afterPageLoad(onTokenization)
    }, [called]);

    useEffect(() => {
        if (!formData?.vendor || !formData?.selectedPlan) {
            Swal.fire("", "Something went wrong. Please contact to admin.", "error");
        }

        if (formData.selectedPlan.id === PACKAGES.MULTI_LISTING) {
            setPrice(formData.selectedPlan.pricePerYear * formData.listingNumber);
        } else {
            setPrice(formData.selectedPlan.pricePerYear);
        }
    }, [])

    const onTokenization = (token) => {
        setisLoading(true);

        axios.post(baseURL + VENDOR_APIS.payment, { token: token, userId: formData?.vendor?.id, planId: formData?.selectedPlan?.id, numberOfListings: formData.listingNumber }).then(response => {
            setisLoading(false);
            console.log(response)
            if (response?.data?.status) {
                Swal.fire({
                    title: '',
                    text: 'Payment done',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                        return;
                    }
                })
            } else {
                Swal.fire("", "Something went wrong. Please contact admin.", "error");
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire("", "Something went wrong. Please contact admin.", "error");
        })
    }

    const invoiceRequest = () => {
        if (!formData?.vendor || !formData?.selectedPlan) {
            Swal.fire("", "Something went wrong. Please contact to admin.", "error");
            return;
        }

        axios.post(baseURL + VENDOR_APIS.payment, { userId: formData?.vendor?.id, planId: formData?.selectedPlan?.id, isRequest: true }).then(response => {
            setisLoading(false);
            if (response?.data?.status) {
                Swal.fire({
                    title: '',
                    text: 'Request sent to admin.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                        return;
                    }
                })
            } else {
                Swal.fire("", "Something went wrong. Please contact admin.", "error");
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire("", "Something went wrong. Please contact admin.", "error");
        })
    }

    return (
        <div className="main-package main-payment">
            <AppBar position="static" className="appbar">
                <Toolbar>
                    <img src="images/lets.png" alt="Logo" />
                </Toolbar>
            </AppBar>
            <Grid item container sm={12} md={12} lg={9} className="m-auto mb-3 packages-holder-main">
                <Grid item xs={12} sm={12} md={6} lg={6} className="mt-3 ">
                    <h2 className="h1 weight-semi-bold">Please make payment</h2>
                    <Typography variant="body1" gutterBottom className="p-0 pt-">
                        Please confirm your package and choose your payment method below
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container sm={12} md={12} lg={9} className="m-auto mb-3 packages-holder-main payment-mini-table">
                <Grid item xs={12} sm={12} md={6} lg={6} className="register-vendor-header">
                    <table>
                        <tbody>
                            <tr>
                                <td className="p-1" style={{ color: '#a19899', fontSize: '18px' }}>
                                    <Typography style={{ fontSize: '18px' }} variant="body1" gutterBottom className="p-0">
                                        <b>Package:</b>
                                    </Typography>
                                </td>
                                <td className="p-1" style={{ fontSize: '18px' }}>
                                    <Typography style={{ fontSize: '18px' }} variant="body1" gutterBottom className="p-0">
                                        <b>{formData.selectedPlan.title}</b>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1" style={{ color: '#a19899', fontSize: '18px' }}>
                                    <Typography style={{ fontSize: '18px' }} variant="body1" gutterBottom className="p-0">
                                        <b>Price:</b>
                                    </Typography>
                                </td>
                                <td className="p-1" style={{ fontSize: '18px' }}>
                                    <Typography style={{ fontSize: '18px' }} variant="body1" gutterBottom className="p-0">
                                        <b>AED {price}</b>
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>

            <Grid item container sm={12} md={12} lg={9} className="m-auto mb-3 packages-holder-main payment-mini-table">
                <div id="paymennt-frame" className="card-frame"></div>
                <div className="error-message"></div>
                <div className="info-message"></div>
                <button id="pay-button" className="btn">Pay</button>
            </Grid>

            <div className="form-holder request-invoice-area">
                <h4 className="weight-semi-bold">Bank Transfer</h4>
                <Typography variant="body1" gutterBottom className="p-0 pt-">
                    We can also send you an invoice so you can pay by bank transfer. If you'd prefer this option please click below .
                </Typography>
                <div className="requestBtn">
                    <button onClick={invoiceRequest} className="btn">REQUEST AN INVOICE</button>
                </div>
            </div>
        </div>
    )
};

export default Payment;
