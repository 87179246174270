import React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import DateAdapter from '@mui/lab/AdapterMoment';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import createStore from './redux/create-store';
import Routes from './Routes';
const history = createBrowserHistory();
const {store, persistor} = createStore(history, window.__INITIAL_STATE__);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
       <LocalizationProvider dateAdapter={AdapterMoment}>
        <Routes history={history} />
       </LocalizationProvider> 
      </PersistGate>
    </Provider>
  );
}

export default App;
