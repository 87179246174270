import { Button, Container } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import api, { USER_APIS } from "../../config/api-config";

export default function ForgetPasswordPage() {
  const [forgetPasswrd, setforgetPasswrd] = useState({
    email: ""
  });
  const _forgetPasswrd = async () => {
    try {
      const response = await api.get(
        USER_APIS.forgetPasswrd + forgetPasswrd.email
      );
      if (response.data.status) {
        Swal.fire("Message", response.data.message, "success");
      }
      else{
        Swal.fire("Message", response.data.message,"error");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <Container maxWidth="sm">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        <h1 style={{ fontWeight: "bolder" }}>Forget Password ?</h1>
        <input
          className="w-80 forgot-button"
          type="text"
          placeholder="Email Address"
          style={{ margin: "1rem 0" }}
          value={forgetPasswrd.email}
          onChange={(evt) =>
            setforgetPasswrd({ ...forgetPasswrd, email: evt.target.value })
          }
        />
        <Button className="btn-primary w-70 forgot-button" onClick={() => _forgetPasswrd()}>
          Forget Password
        </Button>
        <a
          href="/"
          style={{
            margin: "1rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
            textDecoration: "none",
          }}
        >
          <i className="fa-solid fa-circle-arrow-left fa-2x"></i>
          <p style={{ marginLeft: "5px",marginBottom:"0" }}>Go Back</p>
        </a>
      </div>
    </Container>
  );
}
