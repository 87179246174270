import React from "react";
import { Grid, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./home.scss";

const OffersList = ({ title, list, color }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <div style={{ padding: 20, background: "#fff", borderRadius: "8px" }}>
        <div className="flex justify-between items-center">
          <h4 className="ma0" style={{ color }}>
            {title}{" "}
          </h4>
          {/*<div className="text-grey"><MoreVert /></div>*/}
        </div>
        <div className="offer-list">
          <ul className="dashboard-item">
            {list?.map((listItem, index) => (
              <li
                key={index}
                onClick={() =>
                  navigate(
                    listItem?.type === "event"
                      ? `/events/edit/${listItem?.id}`
                      : `/outlets/edit/${listItem?.id}`
                  )
                }
              >
                <div className="flex my-2">
                  <Avatar src={listItem?.bannerImage ?? "/broken-image.jpg"} />
                  <span className="ml2">{listItem?.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <a className="button-sm normal-btn-space" style={{textDecoration:"none"}} href="/featured">See More</a> */}
      </div>
    </Grid>
  );
};

export default OffersList;
