import { AUTH_LOADING, AUTH_ERROR, AUTH_USER } from '../state-constants';
import api from '../../config/api-config';
import { AUTH_APIS, storageToken } from '../../config/constants';
import { handle400Rejections } from '../../config/constants';

const initialState = {
  error: null,
  isError: false,
  isLoading: false,
  user: null
};

const reducer = (state = initialState, action = {}) => {

  switch (action.type) {
    default:
      return state;

    case AUTH_LOADING:
      return {
        ...state,
        error: null,
        isError: false,
        isLoading: true
      };

    case AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        isError: true,
        isLoading: false
      };

    case AUTH_USER:
      return {
        ...state,
        error: null,
        isError: false,
        isLoading: false,
        user: action.user
      };
  }
};

export default reducer;

const handleErr = (dispatch, err) => {
  dispatch({
    type: AUTH_ERROR,
    error: err
  });
}

export const authenticateUser = (username, password) => {
  return dispatch => new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOADING });
    try {
      api.post(AUTH_APIS.login, { username, password, deviceToken: 'browser', osType: 'web' })
        .then(response => {
          // console.log(response);
          // console.log(response?.data.unauthorised);
          if (response && response?.data.unauthorised) {
            handleErr(dispatch, null);
            reject({ message: 'Unauthorised', unauthorised: true });
          }
          else if (!response || !response?.data?.data) {
            handleErr(dispatch, null);
            reject(response?.data?.message ?? 'Unauthorised');
          }
          const { status, data } = response;
          // localStorage.setItem('userData', JSON.stringify(data.data))
          if (!status || !data?.data) {
            handleErr(dispatch, response?.data?.message);
            reject(response?.data?.message || handle400Rejections(410));
          }

          const { data: loginData } = data;

          const {
            authenticated,
            exists,
            user
          } = loginData;

          loginData.isAdmin = false;

          if (authenticated && exists) {
            if (user) {
              if (user.role === 'admin' || user.role === 'moderator' || user.role === 'vendor') {
                loginData.isAdmin = true;
              }
            }
          }

          dispatch({
            type: AUTH_USER,
            user: { ...loginData?.user }
          });
          resolve(loginData);
          return;
        })
        .catch(error => {
          handleErr(dispatch, error);
          reject(error?.response?.data?.message);
        });
    } catch (err) {
      handleErr(dispatch, err);
      reject(err?.response?.data?.message);
    }
  });
};

export const changePassword = (oldPassword, newPassword) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      api
        .post('/change-password', { oldPassword, newPassword })
        .then(response => {
          resolve(response?.data);
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        })
    } catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  });
};

export const logout = () => {
  return dispatch => {
    dispatch({
      type: AUTH_USER,
      user: null
    });

    window.localStorage.removeItem(storageToken);
    window.localStorage.clear();
    window.sessionStorage.clear();
  }
};
