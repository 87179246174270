import React, { useState } from "react";
import { Button, Grid, Typography, AppBar, Toolbar, Autocomplete, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import api from '../../../config/api-config';
import { NATIONALITIES_LIST } from "../../../config/constants";

const Address = ({ setStep, formData, setFormData, setisLoading }) => {

    const initialAddressData = {
        address: formData?.vendor?.address || "",
        city: formData?.vendor?.city || "",
        country: formData?.vendor?.country || 'AE'
    }

    const [vendorAddress, setVendorAddress] = useState(initialAddressData);
    const [country, setCountry] = useState("AE");

    const handleChange = (e) => {
        setVendorAddress({ ...vendorAddress, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        if (!vendorAddress.address || !vendorAddress.city || !vendorAddress.country) {
            Swal.fire("Required", "All fields are mandatory", "error");
            return;
        }

        if (!(vendorAddress.country.length >= 2 && vendorAddress.country.length <= 3)) {
            Swal.fire("Invalid", "Invalid country code", "error");
            return;
        }

        setisLoading(true);
        let vendorData = formData.vendor;
        vendorData.address = vendorAddress.address;
        vendorData.city = vendorAddress.city;
        vendorData.country = vendorAddress.country;

        setFormData({ ...formData, vendor: vendorData });
        await api.post("/update-address", vendorAddress).then((res) => {
            console.log(res)
            setisLoading(false);
            setStep(2);
        }).catch((err) => {
            setisLoading(false);
            Swal.fire({ title: "", text: "Something went wrong. Please try again.", icon: "error" });
        })
    }
    return (
        <div>
            <AppBar position="static" className="appbar">
                <Toolbar>
                    <img src="images/lets.png" alt="Logo" />
                </Toolbar>
            </AppBar>
            <Grid container className="m-auto mb-3 flex items-center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="mt-3 register-vendor-header">
                    <h2 className="h1 weight-semi-bold">Address!</h2>
                    <Typography variant="body1" gutterBottom className="p-0 pt-3">
                        Simply complete the details below, select your preferred subscription address and we'll set
                        you up with an account
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className="m-auto register-adress-form flex items-center justify-center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input type="text" name="address" value={vendorAddress.address} onChange={handleChange} placeholder="Address *" className="w-100 input-square" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input type="text" name="city" value={vendorAddress?.city} onChange={handleChange} placeholder="City *" className="w-100 input-square" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <Autocomplete
                        disablePortal
                        options={NATIONALITIES_LIST}
                        value={NATIONALITIES_LIST.find(countryData => countryData.en_short_name === country)}
                        onChange={(event, value) => {
                            if (value) {
                                setVendorAddress({ ...vendorAddress, country: value.value })
                                setCountry(value.value);
                            }
                        }}
                        renderInput={params => <TextField {...params} label="Select country" />}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className="flex items-center justify-center" style={{ margin: "0 auto" }} >
                <div className="mr3 mt-4 m-4 select-btn">
                    <Button type="button" onClick={handleSubmit} className="normal-btn-space box-shadow flex items-center btn" >
                        Next Step
                    </Button>
                </div>
            </Grid>
        </div>
    )
}

export default Address
