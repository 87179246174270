import React from "react";
import Navbar from "../../../components/navbar";
import "./styles.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { Grid, Autocomplete, TextField } from "@mui/material";
import Swal from "sweetalert2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = ["05", "10", "15", "20", "25", "30"];

export const data = {
  labels,
  datasets: [
    {
      data: [10, 40, 30.45, 23, 56, 13],
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      barThickness: 10,
    },
  ],
};
export default function Report(props) {
  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="d-flex justify-content-evenly flex-wrap">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Report icons 1.png"
              style={{ width: "100px", height: "100px" }}
              alt="report icon"
            />
            <h6 style={{ fontWeight: "bolder" }}>All Listing</h6>
            <label>{props?.outlets?.length}</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Report icons 2.png"
              style={{ width: "100px", height: "100px" }}
              alt="report icon"
            />
            <h6 style={{ fontWeight: "bolder" }}>Let's Eat & Drink</h6>
            <label>{props.count?.restaurantsCount}</label>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Report icons 3.png"
              style={{ width: "100px", height: "100px" }}
              alt="report icon"
            />
            <h6 style={{ fontWeight: "bolder" }}>Let's Drink</h6>
            <label>{props.count?.drinkCount}</label>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Report icons 4.png"
              style={{ width: "100px", height: "100px" }}
              alt="report icon"
            />
            <h6 style={{ fontWeight: "bolder" }}>Let's Brunch</h6>
            <label>{props.count?.brunchCount}</label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Report icons 5.png"
              style={{ width: "100px", height: "100px" }}
              alt="report icon"
            />
            <h6 style={{ fontWeight: "bolder" }}>Let's Explore</h6>
            <label>{props.count?.exploreCount}</label>
          </div>
        </div>

        <div
          className="d-flex justify-content-between m-5 grap-wrapper"
        >
          {props.isAdmin && <><div>
            <div className="d-flex justify-content-between">
              <h6>TOTAL USERS</h6>
              <div className="d-flex">
                <div
                  style={{ marginRight: "5px", cursor: "pointer" }}
                  onClick={() => {
                    props.setselected({ ...props.selected, total_user: false });
                  }}
                >
                  {"Daily |"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.setselected({ ...props.selected, total_user: true });
                  }}
                >
                  {"Monthly"}
                </div>
              </div>
            </div>
            {props.totalUsrData && (
              <div className="graph" style={{ width: "450px" }}>
                <Bar
                  options={options}
                  data={
                    props.selected.total_user
                      ? props.totalUsrData
                      : props.total_user_perDay
                  }
                />
              </div>
            )}
          </div>

            <div>
              <div className="d-flex justify-content-between">
                <h6>ACTIVE USERS</h6>
                <div className="d-flex">
                  <div
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() => {
                      props.setselected({
                        ...props.selected,
                        active_user: false,
                      });
                    }}
                  >
                    {"Daily |"}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setselected({ ...props.selected, active_user: true });
                    }}
                  >
                    {"Monthly"}
                  </div>
                </div>
              </div>
              {props.active_user && (
                <div className="graph" style={{ width: "450px" }}>
                  <Bar
                    options={options}
                    data={
                      props.selected.active_user
                        ? props.active_user
                        : props.active_user_perDay
                    }
                  />
                </div>
              )}
            </div>

            <div className="mt-3">
              <div className="d-flex justify-content-between">
                <h6>APP VISITS</h6>
                <div className="d-flex">
                  <div
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() => {
                      props.setselected({ ...props.selected, app_visits: false });
                    }}
                  >
                    {"Daily |"}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setselected({ ...props.selected, app_visits: true });
                    }}
                  >
                    {"Monthly"}
                  </div>
                </div>
              </div>
              {props.app_visit && (
                <div className="graph" style={{ width: "450px" }}>
                  <Bar
                    options={options}
                    data={
                      props.selected.app_visits
                        ? props.app_visit
                        : props.app_visit_perDay
                    }
                  />
                </div>
              )}
            </div></>}

          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <h6>REDEMPTIONS</h6>
              <div className="d-flex">
                <div
                  style={{ marginRight: "5px", cursor: "pointer" }}
                  onClick={() => {
                    props.setselected({ ...props.selected, redemption: false });
                  }}
                >
                  {"Daily |"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.setselected({ ...props.selected, redemption: true });
                  }}
                >
                  {"Monthly"}
                </div>
              </div>
            </div>
            {props.remdData && (
              <div className="graph" style={{ width: "450px" }}>
                <Bar
                className="graph"
                  options={options}
                  data={
                    props.selected.redemption
                      ? props.remdData
                      : props.redem_perDay
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-evenly buttons-wrapper">
          {/* <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-lg dropdown-toggle rem "
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {props.selectedVenue ? props.selectedVenue.venueName : "Venue (Hotel/Area)"}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {props.venuesList.map((venue) => (
                <li key={venue._id}>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      props.fetchOutlet(venue.id);
                      props.setselectedVenue({
                        id: venue.id,
                        venueName: venue.venueName,
                      });
                    }}
                  >
                    {venue.venueName}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}

          {/* <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-lg dropdown-toggle rem"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {props.filter.name !== "" ? props.filter.name : "Listings"}
            </button>
            <ul className="dropdown-menu dropdown-scroll" aria-labelledby="dropdownMenuButton1">
              {props.outlets.map((outlet) => (
                <li key={outlet.id}>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      props.setfilter({
                        ...props.filter,
                        name: outlet.outletName,
                        id: outlet.id,
                      });
                    }}
                  >
                    {outlet.outletName}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}

          <Grid item xs={4} sm={4} md={4} lg={4}>
            <div className="flex relative">
              <Autocomplete
                value={
                  props?.filter?.id
                    ? props.outlets.find(
                      (item) => item?.id === props?.filter?.id
                    )
                    : null
                }
                options={props.outlets}
                getOptionLabel={(option) => option.outletName}
                filterSelectedOptions={true}
                renderOption={(props, option) => (
                  <li key={option.id} {...props}>{option?.outletName}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label=""
                    placeholder="Choose listing by name"
                  />
                )}
                onChange={(evt, outlet) => {
                  props.setfilter({
                    ...props.filter,
                    name: outlet.outletName,
                    id: outlet.id,
                  });
                  props.setRedemptionFilter({
                    ...props.redemptionFilter,
                    outletId: outlet.id
                  })
                }}
              />
            </div>
          </Grid>

          <input
            type={"date"}
            className="btn btn-outline-secondary btn-lg rem"
            onChange={(e) => {
              props.setfilter({
                ...props.filter,
                startDate: moment(e.target.value).format("YYYY-MM-DD")
              });
              props.setRedemptionFilter({
                ...props.redemptionFilter,
                startDate: moment(e.target.value).valueOf()
              })
            }}
            value={props.filter.startDate}
            max={["", "Invalid date"].includes(props.filter.endDate) ? "" : props.filter.endDate}
          />
          <input
            type={"date"}
            className="btn btn-outline-secondary btn-lg rem"
            onChange={(e) => {
              props.setfilter({
                ...props.filter,
                endDate: moment(e.target.value).format("YYYY-MM-DD")
              });
              props.setRedemptionFilter({
                ...props.redemptionFilter,
                endDate: moment(e.target.value).valueOf()
              })
            }}
            value={props.filter.endDate}
            max={new Date().toISOString().slice(0, 10)}
          />
          <button
            className="btn btn-outline-secondary btn-lg rem"
            onClick={() => {
              if (props.filter.startDate === "Invalid date" || props.filter.endDate === "Invalid date") {
                Swal.fire("Error", "Start and End date are required", "error");
                return;
              }
              props._showEvent()
            }}>
            SUBMIT
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginRight: "6.6rem",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >

        </div>
        {props.offers?._event?.length ? (
          <>
            <div
              className="d-flex justify-content-between"
              style={{ width: "82%", marginLeft: "6rem", marginTop: "10px" }}
            >
              <label>{`${moment(props.filter.startDate, "YYYY-MM-DD").format(
                "DD[th] MMMM YYYY"
              )} to ${moment(props.filter.endDate, "YYYY-MM-DD").format(
                "DD[th] MMMM YYYY"
              )}`}</label>
              <div style={{ flex: 0.5, justifyContent: "space-between", display: "flex" }}>
                <button
                  className="btn btn-warning rem"
                  onClick={() => {
                    const input = document.getElementById("pdf-element");
                    const pdf = new jsPDF();
                    pdf.html(input, {
                      html2canvas: { scale: 0.2 },
                      callback: function (doc) {
                        var pageCount = doc.internal.getNumberOfPages();
                        for (
                          let index = pageCount - 6 + 1;
                          index <= pageCount;
                          index++
                        ) {
                          // console.log("called");
                          doc.deletePage(index);
                        }
                        doc.save();
                      },
                      x: 10,
                      y: 10,
                    });
                  }}
                >
                  DOWNLOAD PDF
                </button>
                <button className="btn btn-warning rem" onClick={() => {
                  let cpy = []
                  props.offers._event.map((offer) => {
                    cpy.push({
                      Outlet: props.filter.name,
                      Offer: offer.offerName,
                      Views: offer.views ?? "0",
                      Favrouites: offer.favrouites ?? "0",
                      Redemptions: offer.redemptions ?? "0"
                    })
                  })
                  exportFromJSON({
                    data: cpy,
                    fileName: "Offers",
                    exportType: exportFromJSON.types.csv,
                  });
                }}>DOWNLOAD CSV</button>
              </div>
            </div>
            <div className="m-5">
              <div id="pdf-element">
                <h1>{props.filter.name}</h1>
                <div className="mb-4 mt-4">
                  <div
                    className="btn btn-outline-secondary btn-lg rem"
                    style={{ marginRight: "10px" }}
                  >
                    {props.offers.total_views ?? "0"} total pages views
                  </div>
                  <div
                    className="btn btn-outline-secondary btn-lg rem"
                    style={{ marginRight: "10px" }}
                  >
                    {props.offers.total_favrouite ?? "0"} total pages favourites
                  </div>
                  <div className="btn btn-outline-secondary btn-lg rem">
                    {props.offers.total_redemption ?? "0"} total redemptions
                  </div>
                </div>

                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Deal</th>
                      <th scope="col">Page Views</th>
                      <th scope="col">Favourited</th>
                      <th scope="col">Redemptions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.offers._event.map((offer, index) => (
                      <tr key={index}>
                        <th scope="row">{offer.offerName ?? "-"}</th>
                        <td>{offer.views ?? "-"}</td>
                        <td>{offer.favrouites ?? "-"}</td>
                        <td>{offer.redemptions ?? "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

// {
/*


import React from 'react';
import {
Chart as ChartJS,
CategoryScale,
LinearScale,
BarElement,
Title,
Tooltip,
Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
CategoryScale,
LinearScale,
BarElement,
Title,
Tooltip,
Legend
);

export const options = {
responsive: true,
plugins: {
  legend: {
    position: 'top' as const,
  },
  title: {
    // display: true,
    // text: 'Chart.js Bar Chart',
  },
},
scales: {
  y: {
    ticks: {
      display: false,
    },
    grid: {
      display: false
    }
  },
  x:{
    grid: {
      display: false
    }
  }
}
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
labels,
datasets: [
  {
    label: 'Dataset 1',
    data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
  // {
  //   label: 'Dataset 2',
  //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
  // },
],
};

export function App() {
return <Bar options={options} data={data} />;
}


*/
// }
