import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchEventById, setEventItem } from "../../redux/reducers/events";
import moment from "moment";

const TableList = ({ item, search }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>
        <div className="flex items-center">
          {" "}
          <img
            src={item?.logoImage ?? "/images/feat-img.png"}
            className="user-img"
            alt="item logo"
          />
          {item?.offer?.offerName}{" "}
        </div>
      </TableCell>
      <TableCell>{item?.eventName}</TableCell>
      <TableCell>
        {item?.offer?.expiryDate && moment(item?.offer?.expiryDate).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell>{item?.redemptionsCount ?? 0}</TableCell>
      <TableCell>
        {item?.isDraft ? "Draft" : item?.status ? "Published" : "Un-Published"}
      </TableCell>
      <TableCell>
        <div
          onClick={() => {
            dispatch(setEventItem(item));
            dispatch(fetchEventById(item?.id));
            search ? navigate(`/events/edit/${item?.id}?search=${search}`) : navigate(`/events/edit/${item?.id}`)
          }}
        >
          <Edit />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableList;
