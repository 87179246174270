import React, { useEffect } from "react";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { TableCell, TableRow, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import { getAppUpdateSetting } from "../../redux/reducers/dashboard";

const AppUpdateSetting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appUpdateSetting } = useSelector(
        (store) => ({
            appUpdateSetting: store.dashboard.appUpdateSetting
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(getAppUpdateSetting())
    }, [])

    useEffect(() => {
    }, [appUpdateSetting])
    return (
        <div>
            <Navbar />
            <BreadcrumbDouble breadFirst="Home" breadLast="App Setting" linkFirst="/" />
            <div className="mt4 table-main">
                <TableContainer>
                    <Table className="w-100" sx={{ minWidth: 991 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Platform</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Sub Title</TableCell>
                                <TableCell>Build Version</TableCell>
                                <TableCell>Build Code</TableCell>
                                <TableCell>Force Update</TableCell>
                                <TableCell>Updated At</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appUpdateSetting && appUpdateSetting?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item?.platform == 0 ? "IOS" : "ANDROID"}</TableCell>
                                    <TableCell>{item?.title}</TableCell>
                                    <TableCell>{item?.subTitle}</TableCell>
                                    <TableCell>{item?.buildVersion}</TableCell>
                                    <TableCell>{item?.buildCode}</TableCell>
                                    <TableCell>{item?.forceUpdate}</TableCell>
                                    <TableCell>
                                        {item?.updatedAt && moment(item?.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            onClick={() => {
                                                navigate(`/appUpdateSetting/edit/${item?.id}`);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Edit />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {!appUpdateSetting && <TableRow ><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell align="center">No Data Found</TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default AppUpdateSetting;
