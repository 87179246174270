import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllVenues } from "../../redux/reducers/venues";
import { updateVendorVenuesListing } from "../../redux/reducers/venues";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  CircularProgress,
  Chip,
} from "@mui/material";
import {
  Call,
  CameraAlt,
  Person,
  Email,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { initReducer, uploadFile } from "../../utils/common-methods";
import { NATIONALITIES_LIST } from "../../config/constants";
import Loader from "../../components/loader";
import Navbar from "../../components/navbar";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import TableList from "./table-list";
// import EditUser from "./edit";
import "./users.scss";
import {
  addUser,
  deleteUser,
  fetchUserDetails,
  fetchUsersList,
  searchUsers,
  setUser,
  updateUser,
} from "../../redux/reducers/users";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { useLocation } from "react-router-dom";
import { storageToken, PACKAGES } from "../../config/constants";
import jwt_decode from "jwt-decode";
import api, {
  CATEGORY,
  OUTLET_APIS,
  PLANS_APIS,
} from "../../config/api-config";
import { element } from "prop-types";

const currencies = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "others",
    label: "Others",
  },
];

const residency = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const userType = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "vendor",
    label: "Vendor",
  },
  {
    value: "admin",
    label: "Administrator",
  },
];

const Users = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search");

  const [cnt, setcnt] = useState(0);
  const navigate = useNavigate();
  const [searchArry, setsearchArry] = useState([]);
  const [packages, setPackages] = useState([]);
  const { isLoading, user, usersList, exportUsersList, venuesList } =
    useSelector(
      (store) => ({
        isLoading: store.users.isLoading,
        user: store.users.user,
        usersList: store.users.usersList,
        exportUsersList: store.users.exportUsersList,
        venuesList: store?.venues?.venuesList,
      }),
      shallowEqual
    );

  const [filterDialog, setfilterDialog] = useState(false);
  const [isExportDialog, setIsExportDialog] = useState(false);
  const [selected, setselected] = useState(null);
  const [indies, setindies] = useState(null);
  const [venues, setVenues] = useState([]);
  let [outletsList, setoutletList] = useState([]);
  const [originalOutletsList, setOriginaloutletList] = useState([]);
  const [selectedListing, setSelectedListing] = useState([]);
  const [listingLoader, setListingLoader] = useState(true);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [categories, setCategories] = useState([]);
  const initialState = {
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    profilePic: "/images/logo.svg",
    dob: "",
    gender: "",
    nationality: "AE",
    uaeResidency: false,
    role: "",
    isActive: true,
    listingNumber: 2,
  };
  const [formData, setFormData] = useReducer(initReducer, initialState);
  const [showLoader, setShowLoader] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  const [exportType, setExportType] = useState("");
  const [search, setSearch] = useState(searchQuery ?? "");
  const [nationalityList, setnationalityList] = useState(NATIONALITIES_LIST);
  // const [nationSearch, setnationSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [sortConfig, setSortConfig] = React.useState(null);
  const [filter, setFilter] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [listingItems, setListingItems] = React.useState([]);

  useEffect(() => {
    if (sortConfig) {
      dispatch(
        fetchUsersList(offset, sortConfig.key, sortConfig.direction, "sort")
      ).then((res) => {
        setcnt(res.count);
        setItems(res.data);
      });
    } else if (filter) {
      dispatch(
        fetchUsersList(
          offset,
          Object.keys(filter)[0],
          Object.values(filter)[0],
          "filter"
        )
      ).then((res) => {
        setcnt(res.count);
        setItems(res.data);
      });
    } else if (search?.length) {
      dispatch(searchUsers(search)).then((res) => {
        setItems(res);
        setcnt(res.length);
      });
    } else {
      dispatch(fetchUsersList(offset)).then((res) => {
        setcnt(res.count);
        setItems(res.data);
      });
    }
  }, [offset, sortConfig, filter]);

  useEffect(() => {
    if (venuesList && venuesList.length > 0) {
      let data = [];
      let existingVenueName = [];
      let selectedVenues=[];
      for (let venue of venuesList) {
        if (!existingVenueName.includes(venue.venueName)) {
          // Check if venueName already exists
          data.push({ label: venue?.venueName, id: venue.id });
          existingVenueName.push(venue.venueName); // Add venueName to existing venueName
        }
      }
      setSelectedVenues(selectedVenues);
      setVenues(data);
    }
  }, [venuesList]);

  // const { items, requestSort, sortConfig } = useSortableData(searchArry, offset);
  useEffect(() => {
    setsearchArry(usersList);
    if (user) {
      setFormData({
        fullName: user?.fullName ?? "",
        firstName: user?.firstName ?? user?.fullName?.split(" ")[0],
        lastName: user?.lastName
          ? user?.lastName
          : user?.fullName?.split(" ")?.length > 1
          ? user?.fullName?.split(" ")[1]
          : "",
        email: user?.email ?? "",
        password: "",
        confirmPassword: "",
        phone: user?.phone ?? "",
        profilePic: user?.profilePic ?  user?.profilePic : "/images/logo.svg",
        dob: user?.dob
          ? moment(user?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        gender: user?.gender ?? "",
        nationality: user?.nationality ?? "AE",
        uaeResidency: user?.uaeResidency ?? false,
        role: user?.role ?? "user",
        isActive: user?.isActive ?? true,
        listingNumber: user?.listingNumber ?? 2,
      });
    }
  }, [user, usersList]);

  useEffect(() => {
    const userToken = window.localStorage.getItem(storageToken);
    const userData = jwt_decode(userToken);
    dispatch(fetchUserDetails(userData.id));
    dispatch(fetchAllVenues("all"));
    setFormData({
      fullName: user?.fullName ?? "",
      firstName: user?.firstName ?? userData?.fullName?.split(" ")[0],
      lastName: user?.lastName
        ? user?.lastName
        : user?.fullName?.split(" ")?.length > 1
        ? user?.fullName?.split(" ")[1]
        : "",
      email: user?.email ?? "",
      password: "",
      confirmPassword: "",
      phone: user?.phone ?? "",
      profilePic: user?.profilePic ?? "/images/logo.svg",
      dob: moment(user?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        ? moment(user?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      gender: user?.gender ?? "",
      nationality: user?.nationality ?? "AE",
      uaeResidency: user?.uaeResidency ?? false,
      role: user?.role ?? "user",
      isActive: user?.isActive ?? true,
      listingNumber: user?.listingNumber,
    });

    api.get(CATEGORY.all).then((response) => {
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    });

    api
      .get(OUTLET_APIS.outlets)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.data && response?.data?.data?.length > 0) {
            let data = [];
            let selectedListing = [];
            let existingListingName = [];
            for (let listing of response.data.data) {
              if (!existingListingName.includes(listing.outletName)) {
                data.push({
                  label: listing.outletName,
                  id: listing.id,
                  associateVenueId: listing.associatedVenue,
                });
                existingListingName.push(listing.outletName);
              }
            }
            setOriginaloutletList(data);
            setoutletList(selectedListing)
            setSelectedListing(selectedListing);
          }
          setListingLoader(false);
        }
      })
      .catch((err) => {
        setListingLoader(false);
        console.log("err: ", err);
      });

    api.get(PLANS_APIS.all).then((response) => {
      if (response.status === 200) {
        setPackages(response.data.data);
      }
    });
  }, []);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = () => {
    if (search?.length) {
      dispatch(searchUsers(search)).then((res) => {
        setItems(res);
        setcnt(res.length);
      });
    } else {
      dispatch(fetchUsersList()).then((res) => {
        setcnt(res.count);
      });
    }
  };

  useEffect(() => {
    // console.log('exportUsersList: ', exportUsersList.length);
    // setList(exportUsersList)
    if (exportFlag) {
      // console.log('EXPORT USEEFFECT')
      exportFile(exportType);
      setExportFlag(false);
    }
  }, [exportUsersList]);

  const handleExportPress = (type) => {
    // console.log('type: ', type);
    setExportFlag(true);
    dispatch(fetchUsersList(type));
    setIsExportDialog(!isExportDialog);
    setExportType(type);
    // exportFile(exportType)
  };

  const exportFile = (type) => {
    let list = exportUsersList;

    let properData = [];
    list.map((data, index) => {
      delete data._id;
      // delete data.createdAt;
      delete data.deviceToken;
      delete data.password;
      delete data.updatedAt;
      data.createdAt = moment(data.createdAt)?.format("DD MMM YYYY");
      properData.push(data);
    });
    // console.log('properData.length: ', properData.length);
    if (properData.length > 0) {
      exportFromJSON({
        data: properData,
        fileName: "user-list ",
        exportType: exportFromJSON.types.csv,
      });
    }
  };

  // useEffect(() => {
  //   if (search.length === 0) handleSearch();
  // }, [search]);

  const uploadProfilePic = async (evt) => {
    const files = evt.target.files;
    if (!files || !files.length) return;

    setShowLoader(true);
    try {
      if (files.length) {
        const response = await uploadFile(files[0]);
        if (response?.filePath) {
          setFormData({ profilePic: response?.filePath });
          Swal.fire("Success", "Profile pic uploaded", "success");
        }
        setShowLoader(false);
      }
    } catch (error) {
      Swal.fire("Failed", "Profile pic upload failed. Try again.", "error");
      setShowLoader(false);
    }
  };
  const addUpdate = () => {
    const {
      password,
      confirmPassword,
      email,
      phone,
      profilePic,
      dob,
      gender,
      nationality,
      uaeResidency,
      role,
      isActive,
      firstName,
      lastName,
    } = formData;

    if (!firstName) {
      Swal.fire("Required", "First Name is a required field", "error");
      return;
    }
    if (!lastName) {
      Swal.fire("Required", "Last Name is a required field", "error");
      return;
    }
    if (!email) {
      Swal.fire("Required", "Email is a required field", "error");
      return;
    }
    if (!dob) {
      Swal.fire("Required", "Date of birth is a required field", "error");
      return;
    }
    if (!gender) {
      Swal.fire("Required", "Please select Gender", "error");
      return;
    }
    if (!role) {
      Swal.fire("Required", "Please select Role", "error");
      return;
    }
    if (!nationality) {
      Swal.fire("Required", "Plase select Nationality", "error");
      return;
    }
    // Regular expression pattern to match email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (email && !emailRegex.test(email)) {
    Swal.fire("Invalid Email", "Please enter a valid email address", "error");
    return;
  }

    if (!password || !confirmPassword) {
      Swal.fire(
        "Required",
        "Password and confrim password are required fields",
        "error"
      );
      return;
    }
    let venueWithoutListing = [];

    for (let venue of selectedVenues) {
      const listingData = originalOutletsList.some(
        (item) => item.associateVenueId === venue.id
      );
    
      if (listingData) {
        const hasMatchingAssociatedVenueId = listingItems.some(
          (item) => item.associateVenueId === venue.id
        );
        if (!hasMatchingAssociatedVenueId) {
          venueWithoutListing.push(venue.label);
        }
      } else {
        Swal.fire(
          "Required",
          `Please select another venue. No listing is available for :- ${venue.label}`,
          "error"
        );
        return;
      }
    }
    
    if (venueWithoutListing.length > 0) {
      Swal.fire(
        "Required",
        `Please select a listing regarding the selected venue(s):- ${venueWithoutListing.join(", ")}`,
        "error"
      );
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire(
        "No match",
        "Password and Confirm Password do not match. Passwords are case-sensitive, please make sure you enter correct values.",
        "error"
      );
      return;
    }
    if (!/^[a-zA-Z0-9]*$/.test(password)) {
      Swal.fire(
        "Error",
        "Password must not contain any special characters. Please enter only alpha numeric values",
        "error"
      );
      return;
    }
    if (password && password.length < 6) {
      Swal.fire(
        "Error",
        "Password must not contain any special characters. Please enter only alpha numeric values",
        "error"
      );
      return;
    }
    const payload = {
      fullName: firstName + " " + lastName,
      email,
      password,
      dob,
      gender,
      lastName,
      nationality,
      uaeResidency,
      role,
      isActive,
      firstName,
    };
    if (formData?.role === "vendor") {
      payload.businessName = formData?.businessName ?? "";
      payload.category = formData?.category ?? "";
      payload.package = formData?.package ?? "";
      payload.startDate = formData?.startDate ?? "";
      payload.endDate = formData?.endDate ?? "";
      payload.country = formData?.country ?? "";
      payload.city = formData?.city ?? "";
      payload.address = formData?.address ?? "";
      payload.listingNumber = formData?.listingNumber ?? 2;
    }

    if (payload?.package && (!payload.startDate || !payload.endDate)) {
      Swal.fire("", "Package start and end date are required", "error");
      return;
    }

    if (profilePic) payload.profilePic = profilePic;
    if (phone) payload.phone = phone;
    if (user?.length || Object.keys(user)?.length) {
      dispatch(updateUser(user?._id, payload))
        .then((res) => {
          if (formData?.role === "vendor")
          dispatch(
            updateVendorVenuesListing(
              res?.data?.data?.id,
              selectedVenues,
              listingItems
            )
          );
          // let userDetail=JSON.parse(localStorage.getItem("userData"))
          // userDetail.user=payload
          // console.log('userDetail: ', userDetail);
          // localStorage.setItem("userData",userDetail)
          Swal.fire("Success", "User updated successfully.", "success");
          dispatch(fetchUsersList());
          setFormData();
          dispatch(setUser(null));
        })
        .catch((err) => {
          console.log("ERROR UPDATING USER: ", err);
          Swal.fire(
            "Error",
            "Error adding a new user. Please try again.",
            "error"
          );
        });
    } else {
      dispatch(addUser(payload))
        .then((res) => {
          console.log("res",res,res?.data?.data?.id,)
          if (formData?.role === "vendor")
            dispatch(
              updateVendorVenuesListing(
                res?.data?.data?.id,
                selectedVenues,
                listingItems
              )
            );
          Swal.fire("Success", "New user added successfully.", "success");
          // setSelectedVenues([])
          // setListingItems([])
          // setSelectedListing([])
          if (search?.length) {
            dispatch(searchUsers(search)).then((res) => {
              setItems(res);
              setcnt(res.length);
            });
          } else {
            dispatch(fetchUsersList(offset)).then((res) => {
              setcnt(res.count);
              setItems(res.data);
            });
          }
          // dispatch(fetchUsersList());
          setFormData(initialState);
        })
        .catch((err) => {
          console.log("ERROR ADDING USER: ", err);
          Swal.fire(
            "Error",
            "Error adding a new user. Please try again.",
            "error"
          );
        });
    }
  };

  const handleEdit = (item) => {
    // dispatch(setUser(item));
    search
      ? navigate(`/users/edit?search=${search}`, { state: { userData: item } })
      : navigate("/users/edit", { state: { userData: item } });
  };
  const handleDelete = (item) => {
    if (item?.role === "admin") {
      Swal.fire("Error", "You cannot delete admin account", "error");
      return;
    }
    Swal.fire({
      title: "Are you sure you want to delete this User ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Keep",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(item?._id, search))
          .then(() => {
            Swal.fire({
              title: "User successfully deleted.",
              timer: 2500,
              icon: "success",
            });
          })
          .catch(() => {
            Swal.fire({
              title: "There was error deleting User. Try again.",
              timer: 3000,
              icon: "error",
            });
          });
      }
    });
  };
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  // const sortData = (type, sortBy) => {
  //   let list = usersList;
  //   let filterData = list.filter(
  //     (user) => user?.[type]?.toLowerCase() === sortBy.toLowerCase()
  //   );
  //   setsearchArry(filterData);
  // };

  return (
    <div>
      <Navbar />
      <BreadcrumbDouble breadFirst="Home" breadLast="Users" linkFirst="/" />
      <div className="container-main">
        {showLoader || (isLoading && <Loader />)}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h1 className="header mb3">{"Profile"}</h1>
          <Button
            type="button"
            className="btn-primary"
            onClick={() => {
              dispatch(setUser([]));
            }}
          >
             <span>Create New User</span>
          </Button>
        </div>
        <div className="user relative w-30 tc" style={{ overflow: "hidden" }}>
          <img
            src={
              formData?.profilePic
                ? formData?.profilePic
                : "/images/logo.svg"
            }
            alt="user"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="user-add">
            <CameraAlt />
            <div
              className="upload"
              onClick={() => {
                if (document) {
                  document?.getElementById("user-image-upload").click();
                }
              }}
            >
              Upload User Photo
            </div>
            <input
              type="file"
              id="user-image-upload"
              onChange={uploadProfilePic}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <form>
          <Grid
            item
            container
            sm={12}
            md={12}
            lg={9}
            spacing={2}
            className="m-auto mt5"
            style={{ width: '100%' }}
          >
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <div className="flex relative">
                <input
                  type="text"
                  placeholder="First Name *"
                  className="w-100 input-square"
                  value={formData?.firstName ?? ""}
                  onChange={(evt) =>
                    setFormData({ firstName: evt.target.value })
                  }
                  autoComplete="Off"
                />
                <span className="cl-icon absolute">
                  <Person />{" "}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <div className="flex relative">
                <input
                  type="text"
                  placeholder="Last Name *"
                  className="w-100 input-square"
                  value={formData?.lastName}
                  onChange={(evt) =>
                    setFormData({ lastName: evt.target.value })
                  }
                  autoComplete="Off"
                />
                <span className="cl-icon absolute">
                  <Person />{" "}
                </span>
              </div>
            </Grid>
            {/* {!user && ( */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
                <input
                  type="password"
                  placeholder="Password *"
                  className="w-100 input-square"
                  value={formData?.password}
                  onChange={(evt) =>
                    setFormData({ password: evt.target.value })
                  }
                />
                <span className="cl-icon absolute">
                  <Person />{" "}
                </span>
              </div>
            </Grid>
            {/* )} */}
            {/* {!user && ( */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
                <input
                  type="password"
                  placeholder="Confirm Password *"
                  className="w-100 input-square"
                  value={formData?.confirmPassword}
                  onChange={(evt) =>
                    setFormData({ confirmPassword: evt.target.value })
                  }
                />
                <span className="cl-icon absolute">
                  <Person />{" "}
                </span>
              </div>
            </Grid>
            {/* )} */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
                <input
                  type="email"
                  placeholder="Email Address *"
                  className="w-100 input-square"
                  value={formData?.email}
                  onChange={(evt) => setFormData({ email: evt.target.value })}
                />
                <span className="cl-icon absolute">
                  <Email />{" "}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
              <input
                    type="text"
                    className="w-100 input-square"
                    placeholder="Phone"
                    value={formData?.phone}
                    onChange={(evt) => {
                      const inputValue = evt.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      const limitedValue = inputValue.slice(0, 10); // Limit the input to 10 characters
                      setFormData({ ...formData, phone: limitedValue });
                    }}
                />
                <span className="cl-icon absolute">
                  <Call />{" "}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
                <input
                  type="date"
                  placeholder="Date of Birth *"
                  className="w-100 input-square date-inp"
                  value={formData?.dob}
                  onChange={(evt) => {
                    var years = moment(evt.target.value)
                      .fromNow()
                      .split(" ")[0];
                    // console.log('years: ', years);
                    if (
                      years >= 21 &&
                      "years" ===
                        moment(evt.target.value).fromNow().split(" ")[1]
                    ) {
                      setFormData({
                        ...formData,
                        dob: evt.target.value,
                      });
                    } else {
                      alert("Sorry but you need to be over 21 years of age");
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="flex relative">
                <select
                  placeholder="Nationality * (eg: Arabic)"
                  className="w-100 input-square"
                  value={formData?.nationality}
                  onChange={(evt) =>
                    setFormData({ nationality: evt.target.value })
                  }
                >
                  {NATIONALITIES_LIST?.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Gender *"
                select
                value={formData?.gender}
                onChange={(evt) => setFormData({ gender: evt.target.value })}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                fullWidth
                label="UAE Residency *"
                select
                value={formData?.uaeResidency}
                onChange={(evt) =>
                  setFormData({ uaeResidency: evt.target.value })
                }
              >
                {residency.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TextField
                fullWidth
                label="User Type *"
                select
                value={formData?.role}
                onChange={(evt) => setFormData({ role: evt.target.value })}
              >
                {userType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formData?.role === "vendor" && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    placeholder="Business Name"
                    className="w-100 input-square"
                    value={formData?.businessName}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        businessName: evt.target.value,
                      })
                    }
                  />
                </div>
              </Grid>
            )}
            {formData?.role === "vendor" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="package">Package</InputLabel>
                    <Select
                      name="package"
                      labelId="package"
                      value={formData.package}
                      label="Package"
                      onChange={(event) => {
                        if (event?.target?.value === "none") {
                          setFormData({
                            ...formData,
                            startDate: "",
                            endDate: "",
                            package: "",
                          });
                        } else {
                          setFormData({
                            ...formData,
                            package: event?.target?.value,
                          });
                        }
                      }}
                    >
                      <MenuItem key="none" value="none">
                        None
                      </MenuItem>
                      {packages.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}

            {formData?.role === "vendor" &&
              !["none", "", undefined, null].includes(formData?.package) && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="startDate"
                    label="Package Start Date Time *"
                    type="datetime-local"
                    value={formData?.startDate}
                    onChange={(evt) => {
                      setFormData({ ...formData, startDate: evt.target.value });
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: formData?.endDate }}
                    fullWidth
                  />
                </Grid>
              )}
            {formData?.role === "vendor" &&
              !["none", "", undefined, null].includes(formData?.package) && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="endDate"
                    label="Package End Date Time *"
                    type="datetime-local"
                    value={formData?.endDate}
                    onChange={(evt) => {
                      setFormData({ ...formData, endDate: evt.target.value });
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: formData?.startDate }}
                    fullWidth
                  />
                </Grid>
              )}
            {formData?.role === "vendor" &&
              formData.package === PACKAGES.MULTI_LISTING && (
                <>
                  <Grid item xs={6}>
                    <Autocomplete
                      disablePortal
                      options={Array.from(
                        { length: 9 },
                        (_, index) => index + 2
                      )}
                      getOptionLabel={(option) => option.toString()}
                      value={formData.listingNumber}
                      onChange={(evt, value) => {
                        if (value >= 2 && value <= 10) {
                          setFormData({ ...formData, listingNumber: value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select number of listing"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="flex relative">
                      <input
                        type="text"
                        placeholder="Address"
                        className="w-100 input-square"
                        value={formData?.address}
                        onChange={(evt) =>
                          setFormData({
                            ...formData,
                            address: evt.target.value,
                          })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="flex relative">
                      <input
                        type="text"
                        placeholder="City"
                        className="w-100 input-square"
                        value={formData?.city}
                        onChange={(evt) =>
                          setFormData({ ...formData, city: evt.target.value })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="flex relative">
                      <input
                        type="text"
                        placeholder="Country"
                        className="w-100 input-square"
                        value={formData?.country}
                        onChange={(evt) =>
                          setFormData({
                            ...formData,
                            country: evt.target.value,
                          })
                        }
                      />
                    </div>
                  </Grid>
                </>
              )}
            {formData?.role === "vendor" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="category">Venue Category</InputLabel>
                    <Select
                      name="category"
                      labelId="category"
                      value={formData.category}
                      label="Venue Category *"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          category: event?.target?.value,
                        });
                      }}
                    >
                      {categories.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}

            {formData?.role === "vendor" && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  multiple
                  disablePortal
                  value={selectedVenues}
                  options={venues}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, venue, reason) => {
                    setSelectedVenues(venue);
                    if (reason !== "removeOption") {
                      // existing select list -> if any listing is there which is not linked with above venue , need to remove from select list
                      // filter temp list which assied to listing
                      if (venue) {
                        let existingListingId = [];
                        let tempFilteredData = [];
                        for (let venues of venue) {
                          tempFilteredData = originalOutletsList.filter(
                            (outlet) => outlet.associateVenueId === venues.id
                          );
                        }
                        
                        for (const outlet of tempFilteredData) {
                          if (!existingListingId.includes(outlet.id)) {
                            // Check if listing id already exists
                            outletsList.push(outlet);
                            existingListingId.push(outlet.id); // Add  listing id to existing  listing
                          }
                        }
                      } else {
                        setSelectedListing(originalOutletsList); // If no venue is selected, show all listings
                      }
                    } else {
                      const removedItem = selectedVenues.find(
                        (item) => !venue.includes(item)
                      );
                      outletsList = outletsList.filter(
                        (outlet) => outlet.associateVenueId !== removedItem.id
                      );
                      setoutletList(outletsList);
                      let tempListingData = listingItems.filter(
                        (outlet) => outlet.associateVenueId !== removedItem.id
                      );

                      setListingItems(tempListingData);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select venues" />
                  )}
                />
              </Grid>
            )}

            {formData?.role === "vendor" && (
              <Grid
                item={!listingLoader}
                container={listingLoader}
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                {listingLoader ? (
                  <CircularProgress />
                ) : (
                  <Autocomplete
                    multiple
                    disablePortal
                    value={listingItems}
                    options={outletsList}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, outlet) => {
                      setListingItems(outlet);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select listing" />
                    )}
                    disabled={selectedVenues.length === 0} // Disable if parent Autocomplete is empty
                  />
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className="flex items-center justify-center"
            >
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    defaultChecked
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={<img src="/images/chck.svg" alt="check" />}
                    checked={formData.isActive}
                    onChange={() =>
                      setFormData({ isActive: !formData.isActive })
                    }
                  />
                }
                label="Make User Active"
              />
            </Grid>
          </Grid>
        </form>
        <div className="w-100 mt5 mb5 tc btn-space">
          <Button type="button" className="btn-primary" onClick={addUpdate}>
            SAVE &amp; UPDATE
          </Button>
        </div>
        <h1 className="header mb3">All Users</h1>
        <div className="flex justify-between items-center main-search-box">
          <div className="flex items-center search-box">
            <input
              type="text"
              className="inpt-width"
              placeholder="Search by First Name, Last Name or Email Address..."
              onChange={(evt) => {
                setSearch(evt?.target?.value);
                if (!evt?.target?.value) {
                  dispatch(fetchUsersList(offset)).then((res) => {
                    setcnt(res.count);
                    setItems(res.data);
                  });
                }
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
              value={search}
            />
            <Button
              className="search flex justify-center items-center"
              onClick={() => {
                handleSearch();
              }}
            >
              <img src="/images/search.png" alt="search" />
            </Button>
          </div>
            <div className="text-grey font-16-semiBold ml3">
              <span>{cnt} </span>Results
            </div>
          <div className=" flex">
            <div className="sq-box shadow flex justify-center mr4 items-center">
              <img
                src="/images/filter.png"
                alt="box"
                onClick={() => setfilterDialog(!filterDialog)}
              />
            </div>
            {filterDialog && (
              <div
                style={{
                  position: "absolute",
                  margin: "50px 0px 0px -200px",
                  background: "#fff",
                  zIndex: 999,
                }}
                className="drop-box"
              >
                <div className="drop_1">
                  <label
                    className="label_txt color"
                    style={{ color: selected?.nationality && "#000" }}
                    onClick={() =>
                      setselected({
                        nationality: !selected?.nationality ?? true,
                      })
                    }
                  >
                    • Filter by Nationality
                  </label>
                  <label
                    className="label_txt color"
                    style={{ color: selected?.gender && "#000" }}
                    onClick={() =>
                      setselected({ gender: !selected?.gender ?? true })
                    }
                  >
                    • Filter by Gender
                  </label>
                  {/* <label
                    className="label_txt color"
                    style={{ color: selected?.usage && "#000" }}
                    onClick={() =>
                      setselected({ usage: !selected?.usage ?? true })
                    }
                  >
                    • Filter by Usage
                  </label> */}
                </div>
                {selected?.gender && (
                  <div className="drop_2">
                    <label
                      className="label_txt"
                      onClick={() => {
                        // sortData("gender", "male");
                        setSortConfig(null);
                        setFilter({ gender: "male" });
                        setindies(-1);
                        setfilterDialog(false);
                        setSearch("");
                      }}
                      style={{ color: indies === -1 ? "black" : "gray" }}
                    >
                      Male
                    </label>
                    <label
                      className="label_txt"
                      onClick={() => {
                        // sortData("gender", "female");
                        setSortConfig(null);
                        setFilter({ gender: "female" });
                        setindies(-2);
                        setfilterDialog(false);
                        setSearch("");
                      }}
                      style={{ color: indies === -2 ? "black" : "gray" }}
                    >
                      Female
                    </label>
                    <label
                      className="label_txt"
                      onClick={() => {
                        // sortData("gender", "other");
                        setSortConfig(null);
                        setFilter({ gender: "others" });
                        setindies(-3);
                        setfilterDialog(false);
                        setSearch("");
                      }}
                      style={{ color: indies === -3 ? "black" : "gray" }}
                    >
                      Other
                    </label>
                  </div>
                )}
                {selected?.nationality && (
                  <div
                    className="drop_2"
                    style={{ height: "320px", overflow: "auto" }}
                  >
                    {/* <input
                      placeholder="Search..."
                      value={nationSearch}
                      onChange={(evt) => {
                        setnationSearch(evt.target.value);
                        let nations = [...NATIONALITIES_LIST];
                        console.log('nations: ', nations);
                        let filterData = nations.filter(
                          (x) =>{return(
                          x.nationality.toLowerCase() ===
                          evt.target.value.toLowerCase()
                          )});
                          console.log('filterData: ', filterData);
                          // setnationalityList(filterData)
                      }}
                    /> */}
                    {nationalityList.map((item, index) => (
                      <label
                        key={index}
                        className="label_txt "
                        onClick={() => {
                          // sortData("nationality", item.nationality);
                          setSortConfig(null);
                          setFilter({ nationality: item.value });
                          setindies(index);
                          setfilterDialog(false);
                          setSearch("");
                        }}
                        style={{ color: indies === index ? "black" : "gray" }}
                      >
                        {item.label}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="mr3">
              <Button
                type="button"
                className="button-sm normal-btn-space bg-secondry-yellow "
                onClick={() => {
                  // window.location.reload();
                  window.location.href = "/users";
                }}
              >
                CLEAR
              </Button>
            </div>

            <Button
              type="button"
              className="button-sm normal-btn-space"
              onClick={() => {
                // handleExportPress()
                setIsExportDialog(!isExportDialog);
              }}
            >
              EXPORT
            </Button>
            {isExportDialog && (
              <div
                style={{
                  position: "absolute",
                  margin: "50px 0px 0px 0px",
                  background: "#fff",
                  zIndex: 999,
                }}
              >
                <div className="drop_1">
                  <label
                    className="label_txt color"
                    style={{ color: selected?.nationality && "#000" }}
                    onClick={() => handleExportPress("today")}
                  >
                    • Export by Today
                  </label>
                  <label
                    className="label_txt color"
                    style={{ color: selected?.gender && "#000" }}
                    onClick={() => handleExportPress("7days")}
                  >
                    • Export by 7 Days
                  </label>
                  <label
                    className="label_txt color"
                    style={{ color: selected?.usage && "#000" }}
                    onClick={() => handleExportPress("30days")}
                  >
                    • Export by 30 Days
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt4 table-main">
          {items?.length ? (
            <>
              <TableContainer>
                <Table className="w-100" sx={{ minWidth: 991 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        onClick={() => requestSort("firstName")}
                        className={getClassNamesFor("firstName")}
                      >
                        First Name
                        {sortConfig ? (
                          sortConfig.key === "firstName" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => requestSort("lastName")}
                        className={getClassNamesFor("lastName")}
                      >
                        Last Name
                        {sortConfig ? (
                          sortConfig.key === "lastName" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => requestSort("email")}
                        className={getClassNamesFor("email")}
                      >
                        Email
                        {sortConfig ? (
                          sortConfig.key === "email" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => requestSort("createdAt")}
                        className={getClassNamesFor("createdAt")}
                      >
                        Created
                        {sortConfig ? (
                          sortConfig.key === "createdAt" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => requestSort("nationality")}
                        className={getClassNamesFor("nationality")}
                      >
                        Nationality
                        {sortConfig ? (
                          sortConfig.key === "nationality" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => requestSort("gender")}
                        className={getClassNamesFor("gender")}
                      >
                        Gender
                        {sortConfig ? (
                          sortConfig.key === "gender" &&
                          (sortConfig.direction === "descending" ||
                            sortConfig.direction === undefined) ? (
                            <i className="fa-solid fa-sort-down ml"></i>
                          ) : (
                            <i className="fa-solid fa-sort-up ml"></i>
                          )
                        ) : (
                          <i className="fa-solid fa-sort ml"></i>
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item) => (
                      <TableList
                        key={item?.id}
                        item={item}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="button"
                  className="btn"
                  onClick={() => {
                    if (offset <= 0) {
                      setOffset(0);
                    } else {
                      setOffset(offset - 10);
                    }
                  }}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </Button>
                <Button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setOffset(offset + 10);
                  }}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h2>NO USER FOUND</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
