import { combineReducers } from 'redux';

import auth from './reducers/auth-user';
import dashboard from './reducers/dashboard';
import events from './reducers/events';
import offers from './reducers/offers';
import outlets from './reducers/outlets';
import redemptions from './reducers/redemptions';
import users from './reducers/users';
import venues from './reducers/venues';
import {notificationReducer} from './reducers/notification'
const reducers = combineReducers({
  auth,
  dashboard,
  events,
  offers,
  outlets,
  redemptions,
  users,
  venues,
  notificationReducer
});

export default reducers;