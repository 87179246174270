import React, { useEffect, useState } from "react";
import { Avatar, Grid, Button, Accordion, AccordionDetails, AccordionSummary, Typography, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { fetchAllEvents, setEventItem, updateEvent } from "../../../redux/reducers/events";
import { fetchAllOutlets, fetchOutletById } from "../../../redux/reducers/outlets";
import api, { DASHBOARD_APIS, EVENT_APIS } from "../../../config/api-config";
import { setOutletItem } from "../../../redux/reducers/outlets";
import { setVenueItem } from "../../../redux/reducers/venues";
import Navbar from "../../../components/navbar";
import Loader from "../../../components/loader";
import "../home.scss";
// import { useTheme } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import AddFeatured from "./add-featured";
// import Remove from "./remove";
// import { fetchAllFeatured } from "../../../redux/reducers/dashboard";
// import { fetchAllFeatured } from "../../../redux/reducers/dashboard";

const Featured = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [featuredRestaurants, setfeaturedRestaurants] = useState([]);
  const [featuredEvents, setfeaturedEvents] = useState([]);
  const [featuredBrunches, setfeaturedBrunches] = useState([]);
  const [featuredExplore, setfeaturedExplore] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [featuredCount, setFeaturedCount] = useState({ featuredEventCount: 0, featuredRestaurantCount: 0, featuredBruncheCount: 0, featuredExploreCount: 0 });
  // const [showAdd, setShowAdd] = useState(false);
  // const [addType, setAddType] = useState(null);
  const [reRender, setreRender] = useState(false);
  const [addFeaturedEvent, setAddFeaturedEvent] = useState([]);
  const [addFeaturedListing, setAddFeaturedListing] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [panel, setPanel] = useState("");
  const [checkbox, setCheckbox] = useState({});
  const [activeDealTypes, setActiveDealTypes] = useState({ isFeatured: true, isRestaurant: true, isBrunch: true, isExplore: true });
  // const [dashboardFeatured, setdashboardFeatured] = useState([]);
  // const [featuredOutlets, setfeaturedOutlets] = useState([]);

  const { eventsList, outletsList, outlet } = useSelector(
    (store) => ({
      eventsList: store?.events?.eventsList,
      outletsList: store?.outlets?.outletsList ?? [],
      outlet: store.outlets.outlet
    }),
    shallowEqual
  );


  useEffect(() => {
    fetchAllCategory();
    dispatch(setOutletItem(null));
    dispatch(setEventItem(null));
    dispatch(setVenueItem(null));
    dispatch(fetchAllEvents("featured"));
    dispatch(fetchAllOutlets("all"));
    // dispatch(fetchAllOutlets("featured"));
  }, [reRender]);


  const fetchAllCategory = () => {
    setisLoading(true);
    let event = api.get(DASHBOARD_APIS.event);
    let restaurant = api.get(DASHBOARD_APIS.restaurant);
    let brunch = api.get(DASHBOARD_APIS.brunch);
    let explore = api.get(DASHBOARD_APIS.explore);
    // let bar = api.get(DASHBOARD_APIS.bar + "/" + offset?.bar);
    // let outlet = api.get(DASHBOARD_APIS.outlet);
    Promise.all([event, restaurant, brunch, explore])
      .then((allCategry) => {
        if (allCategry.length) {
          // setfeaturedOutlets(allCategry[0].data.data);
          // console.log('allCategry[0].data.data: ', allCategry[0].data.data);
          // setfeaturedBars(allCategry[3].data.data);
          setfeaturedEvents(allCategry[0].data.data);
          setfeaturedRestaurants(allCategry[1].data.data);
          setfeaturedBrunches(allCategry[2].data.data);
          setfeaturedExplore(allCategry[3].data.data);
          setFeaturedCount({
            featuredEventCount: allCategry[0].data.data.length,
            featuredRestaurantCount: allCategry[1].data.data.length,
            featuredBruncheCount: allCategry[2].data.data.length,
            featuredExploreCount: allCategry[3].data.data.length
          })
        }
        setisLoading(false);
        setreRender(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setisLoading(false);
        setreRender(false);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setPanel(panel);
    event.stopPropagation();
    setExpanded(isExpanded ? panel : false);
    // if (!isExpanded) {
    //   setShowAdd(false);
    //   setAddType(null);
    // }
  };

  // const handleAdd = (
  //   id,
  //   dealType,
  //   orderType
  // ) => {
  //   const eventData = eventsList?.find((evl) => evl?.id === id);
  //   if (eventData) {
  //     const payload = { ...eventData, isFeatured: true };
  //     delete payload._id;
  //     delete payload?.id;
  //     delete payload.events;
  //     delete payload.featuredAt;
  //     delete payload.featuredEndAt;
  //     delete payload.createdAt;
  //     delete payload.updatedAt;
  //     delete payload.outlet;
  //     delete payload.offer;
  //     delete payload.redemptions;
  //     delete payload.isdisable;
  //     delete payload.status;
  //     delete payload.redemption;
  //     delete payload.redemptionsCount;
  //     if (!payload.redeemCode) payload.redeemCode = "6262";
  //     payload.flag = "add";
  //     payload.dealType = dealType;
  //     payload.order = orderType;

  //     dispatch(updateEvent(id, payload))
  //       .then((response) => {
  //         if (!response?.response?.data?.data) {
  //           Swal.fire('Error', 'Unable to add Deal as featured because listing is unpublished.', 'error');
  //           setreRender(false);
  //           setisLoading(false);
  //         } else {
  //           setreRender(true);
  //         }
  //         setAddType(null);
  //         setShowAdd(false);
  //       })
  //       .catch((err) => {
  //         Swal.fire('Error', 'It seams data is incorrect either in listing or deal, please check add try again.', 'error');
  //         setisLoading(false);
  //       });
  //   }
  // };

  // const dragItem = useRef();
  // const dragOverItem = useRef();
  // const dragStart = (e, position) => {
  //   dragItem.current = position;
  // };
  // const dragEnter = (e, position) => {
  //   dragOverItem.current = position;
  // };
  // const drop = (type) => {
  //   // console.log("type: ", type);
  //   let copyListItems = [];
  //   if (type === "outlet") {
  //     copyListItems = featuredEvents;
  //   } else if (type === "isRestaurant") {
  //     copyListItems = featuredRestaurants;
  //   } else if (type === "isBar") {
  //     copyListItems = featuredBars;
  //   } else if (type === "isBrunch") {
  //     copyListItems = featuredBrunches;
  //   } else if (type === "isExplore") {
  //     copyListItems = featuredExplore;
  //   } else {
  //     copyListItems = featuredOutlets;
  //   }
  //   let dragItemContent = copyListItems[dragItem.current];
  //   copyListItems?.splice(dragItem.current, 1);
  //   copyListItems?.splice(dragOverItem.current, 0, dragItemContent);

  //   let cpy = copyListItems[dragOverItem.current];
  //   cpy.orderStatus = dragOverItem.current;
  //   copyListItems[dragOverItem.current] = cpy;
  //   cpy = copyListItems[dragItem.current];
  //   cpy.orderStatus = dragItem.current;
  //   copyListItems[dragItem.current] = cpy;
  //   if (type === "outlet") updateOrders(copyListItems, "orderEvent");
  //   else if (type === "isRestaurant") updateOrders(copyListItems, "orderEat");
  //   else if (type === "isBar") updateOrders(copyListItems, "orderBar");
  //   else if (type === "isBrunch") updateOrders(copyListItems, "orderBrunch");
  //   else if (type === "isExplore") updateOrders(copyListItems, "orderExplore");
  //   else updateOrders(copyListItems, "orderStatus");
  //   dragItem.current = null;
  //   dragOverItem.current = null;
  //   if (type === "outlet") {
  //     setfeaturedEvents([...copyListItems]);
  //   } else if (type === "isRestaurant") {
  //     setfeaturedRestaurants([...copyListItems]);
  //   } else if (type === "isBar") {
  //     setfeaturedBars([...copyListItems]);
  //   } else if (type === "isBrunch") {
  //     setfeaturedBrunches([...copyListItems]);
  //   } else if (type === "isExplore") {
  //     setfeaturedExplore([...copyListItems]);
  //   } else {
  //     setfeaturedOutlets([...copyListItems]);
  //   }
  // };
  const updateOrders = (data, order) => {
    data.map((data, index) => {
      let payload = { id: data.id, orderType: order, order: index, type: data.type };
      updateOrder(payload);
    });
  };
  const updateOrder = async (payload) => {
    try {
      setisLoading(true);
      await api.post(EVENT_APIS.updateOrder, payload);
      setisLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setisLoading(false);
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    if (panel === "panel1") {
      let items = Array.from(featuredRestaurants);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateOrders(items, "orderEat");
      setfeaturedRestaurants(items);
    } else if (panel === "panel2") {
      let items = Array.from(featuredEvents);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateOrders(items, "orderEvent");
      setfeaturedEvents(items);
    }
    // else if (panel === "panel3") {
    //   let items = Array.from(featuredBars);
    //   const [reorderedItem] = items.splice(result.source.index, 1);
    //   items.splice(result.destination.index, 0, reorderedItem);
    //   updateOrders(items, "orderBar");
    //   setfeaturedBars(items);
    // } 
    else if (panel === "panel3") {
      let items = Array.from(featuredBrunches);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateOrders(items, "orderBrunch");
      setfeaturedBrunches(items);
    } else if (panel === "panel4") {
      let items = Array.from(featuredExplore);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateOrders(items, "orderExplore");
      setfeaturedExplore(items);
    }
  }

  const handleDelete = (id, dealType, orderType, type) => {
    setisLoading(true);
    api.put(DASHBOARD_APIS.deleteFeaturedOutlet, { id, dealType, orderType, type }).then(() => {
      setreRender(true)
    })
    setisLoading(false);
  };

  const getOutletEvents = () => {
    dispatch(fetchOutletById(selectedOutlet.id));
  }

  const setFeaturedEvent = (id, dealType, orderType, checkboxValue, checkbox) => {
    if (checkboxValue && ((dealType === "isFeatured" && featuredCount.featuredEventCount >= 10) || (dealType === "isRestaurant" && featuredCount.featuredRestaurantCount >= 10) || (dealType === "isBrunch" && featuredCount.featuredBruncheCount >= 10) || (dealType === "isExplore" && featuredCount.featuredExploreCount >= 10))) { Swal.fire("Error", "You can add upto 10 elements. Please delete first then try to add again", "error"); return; }

    if (dealType === "isFeatured" && checkboxValue) {
      setAddFeaturedEvent((prevData) => [...prevData, { id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredEventCount: featuredCount.featuredEventCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } });
    } else if (dealType === "isFeatured") {
      setAddFeaturedEvent(addFeaturedEvent.filter(element => element.id != id));
      setFeaturedCount((prevData) => { return { ...prevData, featuredEventCount: featuredCount.featuredEventCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } });
    }

    if (dealType === "isRestaurant" && checkboxValue) {
      setAddFeaturedEvent((prevData) => [...prevData, { id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredRestaurantCount: featuredCount.featuredRestaurantCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } });
    } else if (dealType === "isRestaurant") {
      setAddFeaturedEvent(addFeaturedEvent.filter(element => element.id != id));
      setFeaturedCount((prevData) => { return { ...prevData, featuredRestaurantCount: featuredCount.featuredRestaurantCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } });
    }

    if (dealType === "isBrunch" && checkboxValue) {
      setAddFeaturedEvent((prevData) => [...prevData, { id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredBruncheCount: featuredCount.featuredBruncheCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } });
    } else if (dealType === "isBrunch") {
      setAddFeaturedEvent(addFeaturedEvent.filter(element => element.id != id));
      setFeaturedCount((prevData) => { return { ...prevData, featuredBruncheCount: featuredCount.featuredBruncheCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } });
    }

    if (dealType === "isExplore" && checkboxValue) {
      setAddFeaturedEvent((prevData) => [...prevData, { id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredExploreCount: featuredCount.featuredExploreCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } });
    } else if (dealType === "isExplore") {
      setAddFeaturedEvent(addFeaturedEvent.filter(element => element.id != id));
      setFeaturedCount((prevData) => { return { ...prevData, featuredExploreCount: featuredCount.featuredExploreCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } });
    }
  }

  const setFeaturedOutlet = (dealType, orderType, checkboxValue, checkbox, allFlag = true) => {
    if (checkboxValue && allFlag && ((dealType === "isFeatured" && featuredCount.featuredEventCount >= 10) || (dealType === "isRestaurant" && featuredCount.featuredRestaurantCount >= 10) || (dealType === "isBrunch" && featuredCount.featuredBruncheCount >= 10) || (dealType === "isExplore" && featuredCount.featuredExploreCount >= 10))) { Swal.fire("Error", "You can add upto 10 elements. Please delete first then try to add again", "error"); return; }

    if (dealType === "isFeatured" && checkboxValue) {
      setAddFeaturedListing((prevData) => [...prevData, { id: outlet.id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredEventCount: featuredCount.featuredEventCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } })
    } else if (dealType === "isFeatured") {
      setAddFeaturedListing(addFeaturedListing.filter(element => element.dealType != dealType));
      setFeaturedCount((prevData) => { return { ...prevData, featuredEventCount: featuredCount.featuredEventCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } })
    }

    if (dealType === "isRestaurant" && checkboxValue) {
      setAddFeaturedListing((prevData) => [...prevData, { id: outlet.id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredRestaurantCount: featuredCount.featuredRestaurantCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } })
    } else if (dealType === "isRestaurant") {
      setAddFeaturedListing(addFeaturedListing.filter(element => element.dealType != dealType));
      setFeaturedCount((prevData) => { return { ...prevData, featuredRestaurantCount: featuredCount.featuredRestaurantCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } })
    }

    if (dealType === "isBrunch" && checkboxValue) {
      setAddFeaturedListing((prevData) => [...prevData, { id: outlet.id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredBruncheCount: featuredCount.featuredBruncheCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } })
    } else if (dealType === "isBrunch") {
      setAddFeaturedListing(addFeaturedListing.filter(element => element.dealType != dealType));
      setFeaturedCount((prevData) => { return { ...prevData, featuredBruncheCount: featuredCount.featuredBruncheCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } })
    }

    if (dealType === "isExplore" && checkboxValue) {
      setAddFeaturedListing((prevData) => [...prevData, { id: outlet.id, dealType, orderType }]);
      setFeaturedCount((prevData) => { return { ...prevData, featuredExploreCount: featuredCount.featuredExploreCount + 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: true } })
    } else if (dealType === "isExplore") {
      setAddFeaturedListing(addFeaturedListing.filter(element => element.dealType != dealType));
      setFeaturedCount((prevData) => { return { ...prevData, featuredExploreCount: featuredCount.featuredExploreCount - 1 } });
      setCheckbox((prevData) => { return { ...prevData, [checkbox]: false } })
    }
  }

  const submitFeatured = async () => {
    setisLoading(true);
    setCheckbox({});
    setAddFeaturedEvent([]);
    setAddFeaturedListing([]);
    setActiveDealTypes({ isFeatured: true, isRestaurant: true, isBrunch: true, isExplore: true });
    if (addFeaturedListing.length) {
      for (let element of addFeaturedListing) {
        await api.post(DASHBOARD_APIS.addFeaturedOutlet, { id: element.id, dealType: element.dealType, orderType: element.orderType, type: "OUTLET" });
      }
    } else if (addFeaturedEvent.length) {
      for (let element of addFeaturedEvent) {
        await api.post(DASHBOARD_APIS.addFeaturedOutlet, { id: element.id, dealType: element.dealType, orderType: element.orderType, type: "EVENT" });
      }
    } else {
      Swal.fire("Error", "Please select listing or deal.", "error");
    }
    setreRender(!reRender);
    setisLoading(false);
  }

  const setAllFeaturedOutlet = (dealType, orderType, checkboxValue, alphabet, countName) => {
    if (checkboxValue) {
      let activeCheckboxCount = 0;
      for (let i = 0; i < outlet.events.length; i++) {
        if (checkbox[`${i}${alphabet}`]) activeCheckboxCount++;
      }
      if (checkboxValue && ((featuredCount[countName] + 1 - activeCheckboxCount) > 10)) { Swal.fire("Error", "You can add upto 10 elements. Please delete first then try to add again", "error"); return; }

      for (let i = 0; i < outlet.events.length; i++) {
        setCheckbox((prevData) => { return { ...prevData, [`${i}${alphabet}`]: false } });
        setActiveDealTypes((prevData) => { return { ...prevData, [dealType]: false } });
      }
      setAddFeaturedEvent(addFeaturedEvent.filter(element => element.dealType != dealType));
      setFeaturedOutlet(dealType, orderType, checkboxValue, alphabet, false);
      setCheckbox((prevData) => { return { ...prevData, [alphabet]: true } });
      setFeaturedCount((prevData) => { return { ...prevData, [countName]: featuredCount[countName] - activeCheckboxCount + 1 } });
    }
    else {
      setFeaturedOutlet(dealType, orderType, checkboxValue, alphabet);
      setCheckbox((prevData) => { return { ...prevData, [alphabet]: false } });
      setActiveDealTypes((prevData) => { return { ...prevData, [dealType]: true } });
    }
  }

  return (
    <div>
      <Navbar />
      {isLoading && <Loader />}
      <div className="container-main">
        <h1 className="header mb3">Featured Listings & Deals</h1>
        <div className="accordian">
          <Grid className="mb4">
            <Grid item container xs={12} sm={12} lg={12} spacing={2} className="m-auto mb3 w-100">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={outletsList.filter((option) => option.status)}
                  renderOption={(props, option) => (
                    <li key={option.id} {...props}>{option?.outletName}</li>
                  )}
                  getOptionLabel={(option) => option.outletName}
                  onChange={(event, outlet) => {
                    setAddFeaturedEvent([]);
                    setAddFeaturedListing([]);
                    setCheckbox({});
                    setSelectedOutlet(outlet);
                    setActiveDealTypes({ isFeatured: true, isRestaurant: true, isBrunch: true, isExplore: true });
                  }}
                  renderInput={(params) => <TextField {...params} label="Select Listing" />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <button className="btn-primary-square" onClick={getOutletEvents} disabled={!selectedOutlet}>
                  SELECT
                </button>
              </Grid>
              <Grid style={{ margin: "15px 0 0 5px" }} spacing={2} item container xs={8} sm={8} lg={8}>
                {outlet &&
                  <TableContainer>
                    <Table sx={{ minWidth: 600, maxWidth: 1000 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className="feature-add-head f-medium">{outlet?.outletName}</TableCell>
                          <TableCell className="feature-add-head purple feature-add-head-width">Offers</TableCell>
                          <TableCell align="left" className="feature-add-head skyblue feature-add-head-width">Eat & Drink</TableCell>
                          <TableCell className="feature-add-head dark-yellow feature-add-head-width">Brunch</TableCell>
                          <TableCell className="feature-add-head green feature-add-head-width">Explore</TableCell>
                        </TableRow>
                      </TableHead>
                      {outlet?.events?.length ?
                        <TableBody>
                          {outlet?.events.map((event, index) => (
                            <TableRow key={event.id}>
                              <TableCell component="th" scope="row" className="feature-add-body">{event?.offer?.offerName}</TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox[`${index}a`] || false} disabled={!activeDealTypes.isFeatured} className={activeDealTypes.isFeatured ? "checkbox" : "checkbox disabled-checkbox"} onChange={(e) => setFeaturedEvent(event.id, "isFeatured", "orderEvent", e.target.checked, `${index}a`)} /></TableCell>
                              <TableCell className="feature-add-body">&nbsp;&nbsp;<Checkbox checked={checkbox[`${index}b`] || false} className={event.isRestaurant && activeDealTypes.isRestaurant ? "checkbox" : "checkbox disabled-checkbox"} disabled={!event.isRestaurant || !activeDealTypes.isRestaurant} onChange={(e) => setFeaturedEvent(event.id, "isRestaurant", "orderEat", e.target.checked, `${index}b`)} /></TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox[`${index}c`] || false} className={event.isBrunch && activeDealTypes.isBrunch ? "checkbox" : "checkbox disabled-checkbox"} disabled={!event.isBrunch || !activeDealTypes.isBrunch} onChange={(e) => setFeaturedEvent(event.id, "isBrunch", "orderBrunch", e.target.checked, `${index}c`)} /></TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox[`${index}d`] || false} className={event.isExplore && activeDealTypes.isExplore ? "checkbox" : "checkbox disabled-checkbox"} disabled={!event.isExplore || !activeDealTypes.isExplore} onChange={(e) => setFeaturedEvent(event.id, "isExplore", "orderExplore", e.target.checked, `${index}d`)} /></TableCell>
                            </TableRow>
                          ))}
                          {outlet.events.length > 1 &&
                            <TableRow key="all">
                              <TableCell component="th" scope="row" className="feature-add-body">All Offers Available</TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox.a || false} className="checkbox" onChange={(e) => setAllFeaturedOutlet("isFeatured", "orderEvent", e.target.checked, "a", "featuredEventCount")} /></TableCell>
                              <TableCell className="feature-add-body">&nbsp;&nbsp;<Checkbox checked={checkbox.b || false} className={outlet.isRestaurant ? "checkbox" : "checkbox disabled-checkbox"} disabled={!outlet.isRestaurant} onChange={(e) => setAllFeaturedOutlet("isRestaurant", "orderEat", e.target.checked, "b", "featuredRestaurantCount")} /></TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox.c || false} className={outlet.isBrunch ? "checkbox" : "checkbox disabled-checkbox"} disabled={!outlet.isBrunch} onChange={(e) => setAllFeaturedOutlet("isBrunch", "orderBrunch", e.target.checked, "c", "featuredBruncheCount")} /></TableCell>
                              <TableCell className="feature-add-body"><Checkbox checked={checkbox.d || false} className={outlet.isExplore ? "checkbox" : "checkbox disabled-checkbox"} disabled={!outlet.isExplore} onChange={(e) => setAllFeaturedOutlet("isExplore", "orderExplore", e.target.checked, "d", "featuredExploreCount")} /></TableCell>
                            </TableRow>}
                        </TableBody> :
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" className="feature-add-body">No Deals - Show As Listing Only</TableCell>
                            <TableCell className="feature-add-body"><Checkbox checked={checkbox.a || false} className="checkbox" onChange={(e) => setFeaturedOutlet("isFeatured", "orderEvent", e.target.checked, "a")} /></TableCell>
                            <TableCell className="feature-add-body"><Checkbox checked={checkbox.b || false} disabled={!outlet.isRestaurant} className={outlet.isRestaurant ? "checkbox" : "checkbox disabled-checkbox"} onChange={(e) => setFeaturedOutlet("isRestaurant", "orderEat", e.target.checked, "b")} /></TableCell>
                            <TableCell className="feature-add-body"><Checkbox checked={checkbox.c || false} disabled={!outlet.isBrunch} className={outlet.isBrunch ? "checkbox" : "checkbox disabled-checkbox"} onChange={(e) => setFeaturedOutlet("isBrunch", "orderBrunch", e.target.checked, "c")} /></TableCell>
                            <TableCell className="feature-add-body"><Checkbox checked={checkbox.d || false} disabled={!outlet.isExplore} className={outlet.isExplore ? "checkbox" : "checkbox disabled-checkbox"} onChange={(e) => setFeaturedOutlet("isExplore", "orderExplore", e.target.checked, "d")} /></TableCell>
                          </TableRow>
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>}
              </Grid>
              {outlet && <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginTop: "15px" }}>
                <button className="btn-primary-square" disabled={(!addFeaturedEvent.length && !addFeaturedListing.length) ? true : false} onClick={submitFeatured}>ADD FEATURED</button>
              </Grid>}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 orange-dark-text font-24-semiBold">
                      Featured Listings{" "}
                    </h4>
                    <div className="total">
                      Total {featuredOutlets?.filter(x=>x.isFeatured )?.length ?? 0}
                    </div>
                  </Typography>
                  <Button
                    className="add-feature"
                    onClick={(e) => {
                      // e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "outlets" ? null : "outlets");
                    }}
                  >
                    <Add />
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  {showAdd && addType === "outlets" ? (
                    <AddFeatured
                      itemList={eventsList?.filter((item) => !item.isFeatured)}
                      handleAdd={handleAdd}
                    />
                  ) : (
                    <ul className="featured-items-list-ul">
                      {featuredOutlets?.filter(x=>x.isFeatured )?.map((listItem, index) => (
                        <Remove
                          item={listItem}
                          type={"outlet"}
                          index={index}
                          dragStart={dragStart}
                          dragEnter={dragEnter}
                          drop={drop}
                          setreRender={setreRender}
                          key={index}
                        />
                      ))}
                    </ul>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid> */}

            <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 purple-text font-24-semiBold">
                      Featured Offers
                    </h4>
                    <div className="total">
                      Total {featuredEvents?.length ?? 0}
                    </div>
                  </Typography>
                  {/* <Button
                    className="add-feature"
                    onClick={(e) => {
                      //e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "offers" ? null : "offers");
                    }}
                  >
                    <Add />
                  </Button> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* {showAdd && addType === "offers" ? ( */}
                  {/* <AddFeatured
                    itemList={eventsList}
                    handleAdd={handleAdd}
                    isFeatured={true}
                    type="event"
                    count={featuredEvents?.length ?? 0}
                    dealType="isFeatured"
                    orderType="orderEvent"
                  /> */}
                  {/* ) : ( */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="featured-items-list-ul" {...provided.droppableProps} ref={provided.innerRef}>
                          {featuredEvents.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {item.type === "EVENT" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/events/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          {item?.offer && <div className="">{item?.offer?.offerName} - </div>}
                                          <div className="">{item?.outletName ?? item?.eventName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isFeatured", "orderEvent", "EVENT")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                    {item.type === "OUTLET" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/outlets/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          <div className="">{item?.outletName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isFeatured", "orderEvent", "OUTLET")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <ul className="featured-items-list-ul">
                    {featuredEvents?.map((listItem, index) => (
                      <Remove
                        item={listItem}
                        index={index}
                        dragStart={dragStart}
                        dragEnter={dragEnter}
                        drop={drop}
                        type={"outlet"}
                        isEvent={true}
                        isFeatured={true}
                        setreRender={setreRender}
                        key={index}
                      />
                    ))} */}
                  {/* <button
                      className="button-sm normal-btn-space"
                      onClick={() => {
                        let update = offset.offer + 10
                        setoffset({ ...offset, offer: update });
                        api
                          .get(
                            DASHBOARD_APIS.event + "/" + update
                          )
                          .then((res) => {
                            if (res.data.data.length)
                              setfeaturedEvents([
                                ...featuredEvents,
                                ...res.data.data,
                              ]);
                          });
                      }}
                    >
                      See More
                    </button> */}
                  {/* </ul> */}
                  {/* )} */}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 light-green-text font-24-semiBold">
                      Featured Eat & Drink
                    </h4>
                    <div className="total">
                      Total {featuredRestaurants?.length ?? 0}
                    </div>
                  </Typography>
                  {/* <Button
                    className="add-feature"
                    onClick={(e) => {
                      //e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "eat" ? null : "eat");
                    }}
                  >
                    <Add />
                  </Button> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* {showAdd && addType === "eat" ? ( */}
                  {/* <AddFeatured
                    itemList={eventsList}
                    handleAdd={handleAdd}
                    isRestaurant={true}
                    count={featuredRestaurants?.length ?? 0}
                    dealType="isRestaurant"
                    orderType="orderEat"
                  /> */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="featured-items-list-ul" {...provided.droppableProps} ref={provided.innerRef}>
                          {featuredRestaurants.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {item.type === "EVENT" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/events/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          {item?.offer && <div className="">{item?.offer?.offerName} - </div>}
                                          <div className="">{item?.outletName ?? item?.eventName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isRestaurant", "orderEat", "EVENT")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                    {item.type === "OUTLET" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/outlets/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          <div className="">{item?.outletName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isRestaurant", "orderEat", "OUTLET")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* )} */}
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 secondry-yellow-text font-24-semiBold">
                      Featured Drink
                    </h4>
                    <div className="total">
                      Total {featuredBars?.length ?? 0}
                    </div>
                  </Typography> */}
            {/* <Button
                    className="add-feature"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "drink" ? null : "drink");
                    }}
                  >
                    <Add />
                  </Button> */}
            {/* </AccordionSummary>
                <AccordionDetails> */}
            {/* {showAdd && addType === "drink" ? ( */}
            {/* <AddFeatured
                    itemList={eventsList}
                    handleAdd={handleAdd}
                    isBar={true}
                    count={featuredBars?.length ?? 0}
                    dealType="isBar"
                    orderType="orderBar"
                  /> */}
            {/* ) : ( */}
            {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="featured-items-list-ul" {...provided.droppableProps} ref={provided.innerRef}>
                          {featuredBars.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div
                                      className="feat-user flex items-center justify-between mb3"
                                    >
                                      <div
                                        className="flex items-center"
                                        onClick={() =>
                                          navigate(`/events/edit/${item?.id}`)
                                        }
                                      >
                                        <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                        {item?.offer && <div className="">{item?.offer?.offerName} - </div>}
                                        <div className="">{item?.outletName ?? item?.eventName}</div>
                                      </div>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        className="feat-btn"
                                        onClick={() => handleDelete(item?.id, item, "isBar", "orderBar")}
                                      >
                                        REMOVE
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext> */}
            {/* <ul className="featured-items-list-ul">
                    {featuredBars?.map((listItem, index) => (
                      <Remove
                        item={listItem}
                        isEvent={true}
                        type={"isBar"}
                        index={index}
                        dragStart={dragStart}
                        dragEnter={dragEnter}
                        drop={drop}
                        setreRender={setreRender}
                        key={index}
                      />
                    ))} */}
            {/* <button
                      className="button-sm normal-btn-space"
                      onClick={() => {
                        let update = offset.bar + 10
                        setoffset({ ...offset, bar: update });
                        api
                          .get(DASHBOARD_APIS.bar + "/" + update)
                          .then((res) => {
                            if (res.data.data.length)
                              setfeaturedBars([
                                ...featuredBars,
                                ...res.data.data,
                              ]);
                          });
                      }}
                    >
                      See More
                    </button> */}
            {/* </ul> */}
            {/* )} */}
            {/* </AccordionDetails>
              </Accordion>
            </Grid> */}

            <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 primary-yellow-text font-24-semiBold">
                      Featured Brunch
                    </h4>
                    <div className="total">
                      Total {featuredBrunches?.length ?? 0}
                    </div>
                  </Typography>
                  {/* <Button
                    className="add-feature"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "brunch" ? null : "brunch");
                    }}
                  >
                    <Add />
                  </Button> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* {showAdd && addType === "brunch" ? ( */}
                  {/* <AddFeatured
                    itemList={eventsList}
                    handleAdd={handleAdd}
                    isBrunch={true}
                    count={featuredBrunches?.length ?? 0}
                    dealType="isBrunch"
                    orderType="orderBrunch"
                  /> */}
                  {/* ) : ( */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="featured-items-list-ul" {...provided.droppableProps} ref={provided.innerRef}>
                          {featuredBrunches.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {item.type === "EVENT" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/events/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          {item?.offer && <div className="">{item?.offer?.offerName} - </div>}
                                          <div className="">{item?.outletName ?? item?.eventName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isBrunch", "orderBrunch", "EVENT")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                    {item.type === "OUTLET" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/outlets/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          <div className="">{item?.outletName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isBrunch", "orderBrunch", "OUTLET")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <ul className="featured-items-list-ul">
                    {featuredBrunches?.map((listItem, index) => (
                      <Remove
                        item={listItem}
                        isEvent={true}
                        type={"isBrunch"}
                        index={index}
                        dragStart={dragStart}
                        dragEnter={dragEnter}
                        drop={drop}
                        setreRender={setreRender}
                        key={index}
                      />
                    ))} */}
                  {/* <button
                      className="button-sm normal-btn-space"
                      onClick={() => {
                        let update = offset.brunch + 10
                        setoffset({ ...offset, brunch: update });
                        api
                          .get(
                            DASHBOARD_APIS.brunch + "/" + update
                          )
                          .then((res) => {
                            if (res.data.data.length)
                              setfeaturedBrunches([
                                ...featuredBrunches,
                                ...res.data.data,
                              ]);
                          });
                      }}
                    >
                      See More
                    </button> */}
                  {/* </ul> */}
                  {/* )} */}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item lg={6} md={12} xs={12}>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <h4 className="ma0 green-text font-24-semiBold">
                      Featured Explore
                    </h4>
                    <div className="total">
                      Total {featuredExplore?.length ?? 0}
                    </div>
                  </Typography>
                  {/* <Button
                    className="add-feature"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAdd(!showAdd);
                      setAddType(addType === "explore" ? null : "explore");
                    }}
                  >
                    <Add />
                  </Button> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* {showAdd && addType === "explore" ? ( */}
                  {/* <AddFeatured
                    itemList={eventsList}
                    handleAdd={handleAdd}
                    isExplore={true}
                    count={featuredExplore?.length ?? 0}
                    dealType="isExplore"
                    orderType="orderExplore"
                  /> */}
                  {/* ) : ( */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="featured-items-list-ul" {...provided.droppableProps} ref={provided.innerRef}>
                          {featuredExplore.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {item.type === "EVENT" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/events/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          {item?.offer && <div className="">{item?.offer?.offerName} - </div>}
                                          <div className="">{item?.outletName ?? item?.eventName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isExplore", "orderExplore", "EVENT")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                    {item.type === "OUTLET" &&
                                      <div className="feat-user flex items-center justify-between mb3">
                                        <div
                                          className="flex items-center left"
                                          onClick={() =>
                                            navigate(`/outlets/edit/${item?.id}`)
                                          }
                                        >
                                          <Avatar src={item?.bannerImage ?? "/broken-image.jpg"} />
                                          <div className="">{item?.outletName}</div>
                                        </div>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className="feat-btn"
                                          onClick={() => handleDelete(item?.id, "isExplore", "orderExplore", "OUTLET")}
                                        >
                                          REMOVE
                                        </Button>
                                      </div>}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <ul className="featured-items-list-ul">
                    {featuredExplore?.map((listItem, index) => (
                      <Remove
                        item={listItem}
                        isEvent={true}
                        type={"isExplore"}
                        index={index}
                        dragStart={dragStart}
                        dragEnter={dragEnter}
                        drop={drop}
                        setreRender={setreRender}
                        key={index}
                      />
                    ))} */}
                  {/* <button
                      className="button-sm normal-btn-space"
                      onClick={() => {
                        let update = offset?.explore + 10
                        setoffset({
                          ...offset,
                          explore: update,
                        });
                        api
                          .get(
                            DASHBOARD_APIS.explore +
                            "/" + update
                          )
                          .then((res) => {
                            if (res.data.data.length)
                              setfeaturedExplore([
                                ...featuredExplore,
                                ...res.data.data,
                              ]);
                          });
                      }}
                    >
                      See More
                    </button> */}
                  {/* </ul> */}
                  {/* )} */}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Featured;