// AUTH
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';

// USERS
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_LIST = 'USERS_LIST';
export const USERS_DATA = 'USERS_DATA';
export const EXPORT_USERS_LIST = 'EXPORT_USERS_LIST';

// CATEGORY
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const CATEGORY_ITEM = 'CATEGORY_ITEM';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_LOADING = 'CATEGORY_LOADING';

// VENUES
export const VENUES_ERROR = 'VENUES_ERROR';
export const VENUES_ITEM = 'VENUES_ITEM';
export const VENUES_LIST = 'VENUES_LIST';
export const VENUES_LOADING = 'VENUES_LOADING';

// OUTLETS
export const OUTLETS_ERROR = 'OUTLETS_ERROR';
export const OUTLETS_ITEM = 'OUTLETS_ITEM';
export const OUTLETS_LIST = 'OUTLETS_LIST';
export const OUTLETS_LOADING = 'OUTLETS_LOADING';

// EVENTS
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const EVENTS_ITEM = 'EVENTS_ITEM';
export const EVENTS_LIST = 'EVENTS_LIST';
export const EVENTS_LOADING = 'EVENTS_LOADING';

// OFFERS
export const OFFERS_ERROR = 'OFFERS_ERROR';
export const OFFERS_ITEM = 'OFFERS_ITEM';
export const OFFERS_LIST = 'OFFERS_LIST';
export const OFFERS_LOADING = 'OFFERS_LOADING';

// REDEMPTIONS
export const REDEMPTIONS_ERROR = 'REDEMPTIONS_ERROR';
export const REDEMPTIONS_ITEM = 'REDEMPTIONS_ITEM';
export const REDEMPTIONS_LIST = 'REDEMPTIONS_LIST';
export const REDEMPTIONS_LOADING = 'REDEMPTIONS_LOADING';
export const EXPORT_REDEMPTIONS_DATA = 'EXPORT_REDEMPTIONS_DATA';

// DASHBOARD
export const DASHBOARD_ERROR = 'OFFERS_ERROR';
export const DASHBOARD_FEATURED = 'DASHBOARD_FEATURED';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const FETCH_APP_UPDATE_SETTING = 'FETCH_APP_UPDATE_SETTING';
export const UPDATE_APP_UPDATE_SETTING = 'UPDATE_APP_UPDATE_SETTING';

export const NATIFICATION_ERROR = 'NATIFICATION_ERROR';
export const NATIFICATION_SUCCESS = 'NATIFICATION_SUCCESS';
export const NATIFICATION_LOADING = 'NATIFICATION_LOADING';
