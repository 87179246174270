import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  FormControl,
  Autocomplete,
  Box,
  InputLabel,
  Select,
} from "@mui/material";
import {
  Call,
  CameraAlt,
  Person,
  Email,
  CheckBoxOutlineBlank,
  Password,
} from "@mui/icons-material";
import Navbar from "../../components/navbar";
import TableEdit from "./edit-table";
import { NATIONALITIES_LIST, PACKAGES } from "../../config/constants";
import "./users.scss";
import { useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateSingleUser } from "../../redux/reducers/users";
import { fetchRedemptionByUserId } from "../../redux/reducers/redemptions";
import { updateVendorVenuesListing } from "../../redux/reducers/venues";
import { fetchAllVenues } from "../../redux/reducers/venues";
import Swal from "sweetalert2";
import moment from "moment";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { ArrowBackIosNew, MoreHoriz } from "@mui/icons-material";
import Loader from "../../components/loader";
import api, {
  OUTLET_APIS,
  CATEGORY,
  PLANS_APIS,
  VENUE_APIS,
} from "../../config/api-config";
import { element } from "prop-types";

const currencies = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "others",
    label: "Others",
  },
];

const residency = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const userType = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "vendor",
    label: "Vendor",
  },
  {
    value: "admin",
    label: "Administrator",
  },
];

const EditUser = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search");

  const [redemption, setredemption] = useState([]);
  const [formData, setFormData] = useState({
    gender: "",
    nationality: "",
    uaeResidency: "",
    role: "",
    isActive: true,
  });
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [venues, setVenues] = useState([]);
  let [outletsList, setoutletList] = useState([]);
  const [selectedListing, setSelectedListing] = useState([]);
  const [originalListing, setOriginalListing] = useState([]);
  const [listingItems, setListingItems] = useState([]);
  const [listingLoader, setListingLoader] = useState(true);
  const [categories, setCategories] = useState([]);
  const [packages, setPackages] = useState([]);

  const { venuesList, isLoading } = useSelector(
    (store) => ({
      venuesList: store?.venues?.venuesList,
      isLoading: store.venues.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    // if (selectedVenues && selectedVenues.length > 0) {
    api
      .get(OUTLET_APIS.outlets)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.data && response?.data?.data?.length > 0) {
            let data = [];
            let selectedListing = [];
            for (let listing of response.data.data) {
              data.push({
                label: listing.outletName,
                id: listing.id,
                associatedVenueId: listing.associatedVenue,
              });
              if (listing?.associatedUsers?.includes(state?.userData?.id)) {
                selectedListing.push({
                  label: listing.outletName,
                  id: listing.id,
                  associatedVenueId: listing.associatedVenue,
                });
              }
            }

            // dispatch(fetchAllVenues("all"));
            api
              .get(VENUE_APIS.venues + `?offset=all`)
              .then((res) => {
                if (res.data?.data) {
                  let selectedVenuesTemp = [];
                  if (res.data?.data && res.data?.data.length > 0) {
                    let data = [];
                    let existingVenueName = [];
                    for (let venue of res.data?.data) {
                      if (!existingVenueName.includes(venue.venueName)) {
                        // Check if venueName already exists
                        data.push({ label: venue?.venueName, id: venue.id });
                        existingVenueName.push(venue.venueName); // Add venueName to existing venueName
                      }
                      if (
                        venue?.associatedUsers?.includes(state?.userData?.id)
                      ) {
                        selectedVenuesTemp.push({
                          label: venue?.venueName,
                          id: venue.id,
                        });
                      }
                    }
                    setSelectedVenues(selectedVenuesTemp);
                    setVenues(data);
                  }

                  let tempFilteredData = [];
                  // To fetch the listing according to the venue
                  for (let venue of selectedVenuesTemp) {
                    if (venue) {
                      tempFilteredData.push(
                        ...data.filter(
                          (element) => element.associatedVenueId === venue.id
                        )
                      );
                    }
                  }
                  setOriginalListing(data);
                  setSelectedListing(selectedListing);
                  setoutletList(tempFilteredData);
                  setListingItems(selectedListing);
                }
              })
              .catch((err) => {
                let selectedVenuesTemp = [];
                if (venuesList && venuesList.length > 0) {
                  let data = [];
                  let existingVenueName = [];
                  for (let venue of venuesList) {
                    if (!existingVenueName.includes(venue.venueName)) {
                      // Check if venueName already exists
                      data.push({ label: venue?.venueName, id: venue.id });
                      existingVenueName.push(venue.venueName); // Add venueName to existing venueName
                    }
                    if (venue?.associatedUsers?.includes(state?.userData?.id)) {
                      selectedVenuesTemp.push({
                        label: venue?.venueName,
                        id: venue.id,
                      });
                    }
                  }
                  setSelectedVenues(selectedVenuesTemp);
                  setVenues(data);
                }

                let tempFilteredData = [];
                // To fetch the listing according to the venue
                for (let venue of selectedVenuesTemp) {
                  if (venue) {
                    tempFilteredData.push(
                      ...data.filter(
                        (element) => element.associatedVenueId === venue.id
                      )
                    );
                  }
                }
                setOriginalListing(data);
                setSelectedListing(selectedListing);
                setoutletList(tempFilteredData);
                setListingItems(selectedListing);
              });
          }
          setListingLoader(false);
        }
      })
      .catch((err) => {
        setListingLoader(false);
        console.log("err: ", err);
      });

    api.get(CATEGORY.all).then((response) => {
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    });
    api.get(PLANS_APIS.all).then((response) => {
      if (response.status === 200) {
        setPackages(response.data.data);
      }
    });
    // }
  }, []);

  useEffect(() => {
    let obj = state.userData;
    obj.password = "";
    setFormData(obj);
    dispatch(fetchRedemptionByUserId(state.userData.id))
      .then((res) => {
        setredemption(res);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    // eslint-disable-next-line
  }, [state.userData.id]);
  const addUpdate = () => {
    const {
      password,
      confirmPassword,
      email,
      phone,
      profilePic,
      dob,
      gender,
      nationality,
      uaeResidency,
      role,
      isActive,
      firstName,
      lastName,
    } = formData;
    if (!firstName) {
      Swal.fire("Required", "First Name is a required field", "error");
      return;
    }
    if (!lastName) {
      Swal.fire("Required", "Last Name is a required field", "error");
      return;
    }
    if (!email) {
      Swal.fire("Required", "Email is a required field", "error");
      return;
    }
    if (!dob) {
      Swal.fire("Required", "Date of birth is a required field", "error");
      return;
    }
    if (!gender) {
      Swal.fire("Required", "Please select Gender", "error");
      return;
    }
    if (!role) {
      Swal.fire("Required", "Please select Role", "error");
      return;
    }
    if (!nationality) {
      Swal.fire("Required", "Plase select Nationality", "error");
      return;
    }

    if (formData?.package && (!formData.startDate || !formData.endDate)) {
      Swal.fire("", "Package start and end date are required", "error");
      return;
    }

    dispatch(updateSingleUser(formData?.id, formData))
      .then(() => {
        if (formData?.role === "vendor")
          dispatch(
            updateVendorVenuesListing(
              formData?.id,
              selectedVenues,
              listingItems
            )
          );
        let venueWithoutListing = [];

        for (let venue of selectedVenues) {
          const listingData = originalListing.some(
            (item) => item.associatedVenueId === venue.id
          );

          if (listingData) {
            const hasMatchingAssociatedVenueId = listingItems.some(
              (item) => item.associatedVenueId === venue.id
            );
            if (!hasMatchingAssociatedVenueId) {
              venueWithoutListing.push(venue.label);
            }
          } else {
            Swal.fire(
              "Required",
              `Please select another venue. No listing is available for :- ${venue.label}`,
              "error"
            );
            return;
          }
        }

        if (venueWithoutListing.length > 0) {
          Swal.fire(
            "Required",
            `Please select a listing regarding the selected venue(s):- ${venueWithoutListing.join(
              ", "
            )}`,
            "error"
          );
          return;
        }
        Swal.fire("Success", "User updated successfully.", "success").then(
          (res) => {
            window.location.href = searchQuery
              ? `/users?search=${searchQuery}`
              : "/users";
          }
        );
      })
      .catch((err) => {
        console.log("ERROR UPDATING USER: ", err);
        Swal.fire(
          "Error",
          "Error adding a new user. Please try again.",
          "error"
        );
      });
  };
  return (
    <div>
      <Navbar />
      {/* <Breadcrumb
        breadFirst="Home"
        breadSec="Users"
        breadLast="Edit Users"
        linkFirst="/"
        linkSec="/users"
      /> */}
      {isLoading && <Loader />}
      <div className="bradcrumbs" role="presentation">
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} className="flex items-center">
            <div className="mr4 c-pointer left-arrow ">
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/users?search=${searchQuery}` : "/users"}
              >
                <ArrowBackIosNew />
              </Link>{" "}
            </div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link underline="hover" href="/">
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/users?search=${searchQuery}` : "/users"}
              >
                Users
              </Link>
              <Typography>Edit Users</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
            <MoreHoriz />
          </Grid>
        </Grid>
      </div>
      <div className="container-main">
        <div className=" w-100 tc">
          <div className="user-edit">
            <div className="relative user-edit" style={{ overflow: "hidden" }}>
              <img
                src={formData?.profilePic ?? "/images/logo.svg"}
                alt="user"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="change-img absolute" style={{ zIndex: "999" }}>
              <CameraAlt />
            </div>
          </div>
          <div className="tc w-100">
            <div className="flex justify-center items-center mb4 mt2">
              <div className="user-name h1 ma0">
                {`${formData?.firstName ?? ""} ${formData?.lastName ?? ""}`}
                <div className="font-18-regular">
                  User since {formData?.dob}
                </div>
              </div>
              {/* <div className="icon-yellow ml4">
                <DriveFileRenameOutline />
              </div> */}
            </div>
          </div>
        </div>
        <Grid
          item
          container
          sm={12}
          md={12}
          lg={9}
          spacing={2}
          className="m-auto mt6 user-edit-wrapper"
        >
          <form>
            <Grid
              item
              container
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              className="m-auto mt5 user-edit-wrapper"
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    className="w-100 input-square"
                    placeholder="First Name"
                    value={formData?.firstName}
                    onChange={(evt) =>
                      setFormData({ ...formData, firstName: evt.target.value })
                    }
                  />
                  <span className="cl-icon absolute">
                    <Person />{" "}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="w-100 input-square"
                    value={formData?.lastName}
                    onChange={(evt) =>
                      setFormData({ ...formData, lastName: evt.target.value })
                    }
                  />
                  <span className="cl-icon absolute">
                    <Person />{" "}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="email"
                    className="w-100 input-square"
                    placeholder="Email"
                    value={formData?.email}
                    onChange={(evt) =>
                      setFormData({ ...formData, email: evt.target.value })
                    }
                  />
                  <span className="cl-icon absolute">
                    <Email />{" "}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="password"
                    placeholder="Password *"
                    className="w-100 input-square"
                    value={formData?.password}
                    onChange={(evt) =>
                      setFormData({ ...formData, password: evt.target.value })
                    }
                  />
                  <span className="cl-icon absolute">
                    <Password />{" "}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    className="w-100 input-square"
                    placeholder="Phone"
                    value={formData?.phone}
                    onChange={(evt) => {
                      const inputValue = evt.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      const limitedValue = inputValue.slice(0, 10); // Limit the input to 10 characters
                      setFormData({ ...formData, phone: limitedValue });
                    }}
                  />
                  <span className="cl-icon absolute">
                    <Call />{" "}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="date"
                    placeholder="Date of Birth *"
                    className="w-100 input-square date-inp"
                    value={moment(formData?.dob, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(evt) => {
                      var years = moment(evt.target.value)
                        .fromNow()
                        .split(" ")[0];
                      if (
                        years >= 21 &&
                        "years" ===
                          moment(evt.target.value).fromNow().split(" ")[1]
                      ) {
                        setFormData({
                          ...formData,
                          dob: moment(evt.target.value, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          ),
                        });
                      } else {
                        alert("Sorry but you need to be over 21 years of age");
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Gender *"
                  select
                  value={formData?.gender}
                  onChange={(evt) =>
                    setFormData({ ...formData, gender: evt.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    id="select"
                    label="Nationality *  (eg: Arabic)"
                    select
                    value={formData?.nationality}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        nationality: evt.target.value,
                      })
                    }
                  >
                    {NATIONALITIES_LIST.map((option, index) => (
                      <MenuItem value={option.value} key={index}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="UAE Residency *"
                  select
                  value={formData?.uaeResidency}
                  onChange={(evt) =>
                    setFormData({ ...formData, uaeResidency: evt.target.value })
                  }
                >
                  {residency.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="User Type *"
                  select
                  value={formData?.role}
                  onChange={(evt) =>
                    setFormData({ ...formData, role: evt.target.value })
                  }
                >
                  {userType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {formData?.role === "vendor" && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="flex relative">
                    <input
                      type="text"
                      placeholder="Business Name"
                      className="w-100 input-square"
                      value={formData?.businessName}
                      onChange={(evt) =>
                        setFormData({
                          ...formData,
                          businessName: evt.target.value,
                        })
                      }
                    />
                  </div>
                </Grid>
              )}
              {formData?.role === "vendor" && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="category">Venue Category</InputLabel>
                      <Select
                        name="category"
                        labelId="category"
                        value={formData.category}
                        label="Venue Category"
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            category: event?.target?.value,
                          });
                        }}
                      >
                        {categories.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
              {formData?.role === "vendor" && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="package">Package</InputLabel>
                      <Select
                        name="package"
                        labelId="package"
                        value={formData.package}
                        label="Package"
                        onChange={(event) => {
                          if (event?.target?.value === "none") {
                            setFormData({
                              ...formData,
                              startDate: "",
                              endDate: "",
                              package: "",
                            });
                          } else {
                            setFormData({
                              ...formData,
                              package: event?.target?.value,
                            });
                          }
                        }}
                      >
                        <MenuItem key="none" value="none">
                          None
                        </MenuItem>
                        {packages.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {formData?.role === "vendor" &&
                !["none", ""].includes(formData?.package) && (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="startDate"
                      label="Package Start Date Time *"
                      type="datetime-local"
                      value={formData?.startDate}
                      onChange={(evt) => {
                        setFormData({
                          ...formData,
                          startDate: evt.target.value,
                        });
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ max: formData?.endDate }}
                      fullWidth
                    />
                  </Grid>
                )}
              {formData?.role === "vendor" &&
                !["none", ""].includes(formData?.package) && (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="endDate"
                      label="Package End Date Time *"
                      type="datetime-local"
                      value={formData?.endDate}
                      onChange={(evt) => {
                        setFormData({ ...formData, endDate: evt.target.value });
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ min: formData?.startDate }}
                      fullWidth
                    />
                  </Grid>
                )}
              {formData?.role === "vendor" &&
                formData.package === PACKAGES.MULTI_LISTING && (
                  <>
                    <Grid item xs={6}>
                      <Autocomplete
                        disablePortal
                        options={Array.from(
                          { length: 9 },
                          (_, index) => index + 2
                        )}
                        getOptionLabel={(option) => option.toString()}
                        value={formData.listingNumber}
                        onChange={(evt, value) => {
                          if (value >= 2 && value <= 10) {
                            formData.listingNumber = value;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select number of listing"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="flex relative">
                        <input
                          type="text"
                          placeholder="Address"
                          className="w-100 input-square"
                          value={formData?.address}
                          onChange={(evt) =>
                            setFormData({
                              ...formData,
                              address: evt.target.value,
                            })
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="flex relative">
                        <input
                          type="text"
                          placeholder="City"
                          className="w-100 input-square"
                          value={formData?.city}
                          onChange={(evt) =>
                            setFormData({ ...formData, city: evt.target.value })
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="flex relative">
                        <input
                          type="text"
                          placeholder="Country"
                          className="w-100 input-square"
                          value={formData?.country}
                          onChange={(evt) =>
                            setFormData({
                              ...formData,
                              country: evt.target.value,
                            })
                          }
                        />
                      </div>
                    </Grid>
                  </>
                )}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={formData?.isActive}
                      defaultChecked={formData?.isActive}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        formData?.isActive ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() => {
                        setFormData({
                          ...formData,
                          isActive: !formData?.isActive,
                        });
                      }}
                    />
                  }
                  label="Make User Active"
                />
              </Grid>

              {formData?.role === "vendor" && (
                <Grid item lg={6}>
                  <Autocomplete
                    multiple
                    disablePortal
                    value={selectedVenues}
                    options={venues}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, venue, reason) => {
                      setSelectedVenues(venue);
                      if (reason !== "removeOption") {
                        // existing select list -> if any listing is there which is not linked with above venue , need to remove from select list
                        // filter temp list which assied to listing
                        if (venue) {
                          let existingListingId = [];
                          let tempFilteredData = [];
                          for (let venues of venue) {
                            tempFilteredData = originalListing.filter(
                              (outlet) => outlet.associatedVenueId === venues.id
                            );
                          }
                          for (const outlet of tempFilteredData) {
                            if (!existingListingId.includes(outlet.id)) {
                              // Check if listing id already exists
                              outletsList.push(outlet);
                              existingListingId.push(outlet.id); // Add  listing id to existing  listing
                            }
                          }
                        } else {
                          setSelectedListing(originalListing); // If no venue is selected, show all listings
                        }
                      } else {
                        const removedItem = selectedVenues.find(
                          (item) => !venue.includes(item)
                        );
                        outletsList = outletsList.filter(
                          (outlet) =>
                            outlet.associatedVenueId !== removedItem.id
                        );
                        setoutletList(outletsList);
                        let tempListingData = listingItems.filter(
                          (outlet) =>
                            outlet.associatedVenueId !== removedItem.id
                        );

                        setListingItems(tempListingData);
                      }
                    }}
                    // }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select venues" />
                    )}
                  />
                </Grid>
              )}
              {formData?.role === "vendor" && (
                <Grid
                  item={!listingLoader}
                  container={listingLoader}
                  justifyContent="center"
                  alignItems="center"
                  lg={6}
                >
                  {listingLoader ? (
                    <CircularProgress />
                  ) : (
                    <Autocomplete
                      multiple
                      disablePortal
                      value={listingItems}
                      options={outletsList}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, outlet, reason) => {
                        setListingItems(outlet);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select listing" />
                      )}
                      disabled={selectedVenues.length === 0} // Disable if parent Autocomplete is empty
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </form>

          <div className="w-100">
            <div className="w-100 mt5 mb5 tc btn-space">
              <Button
                type="button"
                className="btn-primary"
                onClick={() => addUpdate()}
              >
                SAVE &amp; UPDATE
              </Button>
            </div>
            <h1 className="header mb3">User Deal Redemptions</h1>
            <div className="mt4 table-main">
              <TableContainer>
                <Table className="w-100">
                  <TableHead>
                    <TableRow>
                      <TableCell>Deal Name</TableCell>
                      <TableCell>Venue (Hotel/Area)</TableCell>
                      <TableCell>Redemption Date</TableCell>
                      <TableCell>Redemption Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {redemption?.map((item, index) => (
                      <TableEdit item={item} key={index} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid>
        <div className="tc">
          {" "}
          <Button variant="text" className="delete font-16-semiBold underline">
            DELETE USER
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};
export default EditUser;
