import React, { useEffect, useState } from "react";
import Report from "./component/Report";
import api, { REPORT_API } from "../../config/api-config";
import Loader from "../../components/loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllOutlets } from "../../redux/reducers/outlets";
import { fetchUserDetails } from "../../redux/reducers/users";
import { storageToken } from "../../config/constants";
import moment from "moment";
import jwt_decode from 'jwt-decode';
export default function ReportScreen() {
  const [count, setcount] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [filter, setfilter] = useState({
    name: "",
    id: "",
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  // const [selectedVenue, setselectedVenue] = useState(null);
  // const [outlets, setoutlets] = useState([]);
  const [offers, setoffers] = useState([]);
  const dispatch = useDispatch();
  const [remdData, setremdData] = useState(null);
  const [totalUsrData, settotalUsrData] = useState(null);
  const [selected, setselected] = useState({
    total_user: true,
    active_user: true,
    app_visits: true,
    redemption: true
  });
  const [total_user_perDay, settotal_user_perDay] = useState(null);
  const [redem_perDay, setredem_perDay] = useState(null);
  const [active_user, setactive_user] = useState(null);
  const [active_user_perDay, setactive_user_perDay] = useState(null);
  const [app_visit, setapp_visit] = useState(null);
  const [app_visit_perDay, setapp_visit_perDay] = useState(null);
  const [redemptionFilter, setRedemptionFilter] = useState({
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  })

  useEffect(() => {
    _fecthStatic();
    dispatch(fetchAllOutlets("all"));
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
  }, []);

  const { outletsList, user } = useSelector(
    (store) => ({
      outletsList: store?.outlets?.outletsList ?? [],
      user: store.auth.user
    }),
    shallowEqual
  );

  const createStat = (key, backgroundColor, setModal, type) => {
    let labels = [];
    let obj = {};
    let Data = [];
    let Key = key
    Key?.map((item) => {
      Data.push(item.count);
      if (type == "per-day") {
        labels.push(moment(item.date, "YYYY-MM-DD").format("DD"));
      } else {
        labels.push(moment(item.month, "M").format("MMMM"));
      }
    });
    obj.data = Data;
    obj.backgroundColor = backgroundColor;
    obj.barThickness = 10;
    setModal({
      labels,
      datasets: [obj],
    });
  };
  const _fecthStatic = async () => {
    try {
      setisLoading(true);
      const { data } = await api.post(REPORT_API.report, redemptionFilter);
      if (data.status) {
        setcount(data.data);
        createStat(data.data.redemptionCountByMonth, "rgb(64, 198, 191)", setremdData)
        createStat(data.data.usersActiveCount, "#5b8ef9", setactive_user)
        createStat(data.data.perDayUserActive, "#5b8ef9", setactive_user_perDay, "per-day")
        createStat(data.data.usersCountByMonth, "#efb252", settotalUsrData)
        createStat(data.data.perDay, "#efb252", settotal_user_perDay, "per-day")
        createStat(data.data.appVisitCountByMonth, "#ff6a53", setapp_visit)
        createStat(data.data.perDayAppVisits, "#ff6a53", setapp_visit_perDay, "per-day")
        createStat(data.data.perRedemDay, "rgb(64, 198, 191)", setredem_perDay, "per-day")
      }
      setisLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setisLoading(false);
    }
  };

  // const fetchOutlet = async (id) => {
  //   try {
  //     setisLoading(true);
  //     const { data } = await api.get(REPORT_API.venue + `/${id}`);
  //     // console.log("data: ", data);
  //     if (data.status) {
  //       setoutlets(data.data.outlets);
  //     }
  //     setisLoading(false);
  //   } catch (error) {
  //     console.log("error: ", error);
  //     setisLoading(false);
  //   }
  // };
  const _showEvent = async () => {
    try {
      setisLoading(true);
      _fecthStatic();
      // console.log("filter: ", filter);
      const { data } = await api.post(REPORT_API.getOfferByDate, filter);
      // console.log("data: ", data);
      if (data.status) {
        setoffers(data.data);
      }
      setisLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setisLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Report
        count={count}
        outlets={outletsList}
        filter={filter}
        setfilter={setfilter}
        _showEvent={_showEvent}
        offers={offers}
        remdData={remdData}
        totalUsrData={totalUsrData}
        selected={selected}
        setselected={setselected}
        total_user_perDay={total_user_perDay}
        redem_perDay={redem_perDay}
        active_user={active_user}
        active_user_perDay={active_user_perDay}
        app_visit={app_visit}
        app_visit_perDay={app_visit_perDay}
        setRedemptionFilter={setRedemptionFilter}
        redemptionFilter={redemptionFilter}
        isAdmin={user && user.role === "admin"}
      />
    </>
  );
}
