import React, { useEffect, useState } from "react";
import {
    Grid,
    Button
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import Breadcrumb from "../../components/breadcrumb";
import { getAppUpdateSetting } from "../../redux/reducers/dashboard";
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import { updateAppUpdateSetting } from "../../redux/reducers/dashboard";
import {
    Select,
    FormControl,
} from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import './setting.scss'

const initialData = {
    platform: "IOS",
    title: "",
    subTitle: "",
    buildVersion: "",
    buildCode: "",
    forceUpdate: "NO"
}

const EditAppUpdateSetting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [settingData, setSettingData] = useState(initialData);

    const { appUpdateSetting } = useSelector(
        (store) => ({
            appUpdateSetting: store.dashboard.appUpdateSetting
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(getAppUpdateSetting())
    }, [])

    useEffect(() => {
        if (appUpdateSetting) {
            setSettingData(...(appUpdateSetting.filter((item) => item.id === params?.id)))
        }
    }, [appUpdateSetting])

    const handleChange = (e) => {
        setSettingData({ ...settingData, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        if (!settingData.title.trim() || !settingData.subTitle.trim() || !settingData.buildCode.trim() || !settingData.buildVersion.trim()) {
            Swal.fire(
                'Error',
                'All fields are required',
                'error'
            );
            return;
        }

        if (appUpdateSetting) {
            dispatch(updateAppUpdateSetting(settingData));
        }
        navigate('/appUpdateSetting')
    }

    return (
        <div className="">
            <Navbar />
            <Breadcrumb
                breadFirst="Home"
                breadSec="App Setting"
                breadLast="Edit App Setting"
                linkFirst="/"
                linkSec="/appUpdateSetting"
            />
            <Grid className="m-5" style={{ display: "flex", justifyContent: "center" }} >
                <h2 className="h1 weight-semi-bold">Edit App Update Setting</h2>
            </Grid>
            <Grid item container sm={12} md={12} lg={9} className="m-auto" style={{ display: "flex", justifyContent: "center" }}>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="title"
                            value={settingData.platform === 0 ? "IOS" : "ANDROID"}
                            onChange={handleChange}
                            className="w-100 input-square"
                            disabled
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="title"
                            value={settingData.title}
                            onChange={handleChange}
                            placeholder="Title"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="subTitle"
                            value={settingData.subTitle}
                            onChange={handleChange}
                            placeholder="Sub-Title"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="buildVersion"
                            value={settingData.buildVersion}
                            onChange={handleChange}
                            placeholder="Build Version"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="buildCode"
                            value={settingData.buildCode}
                            onChange={handleChange}
                            placeholder="Build Code"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className="m-3">
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                        <InputLabel id="forceUpdate">Force Update</InputLabel>
                            <Select
                                labelId="forceUpdate"
                                value={settingData.forceUpdate}
                                label="Force Update"
                                onChange={handleChange}
                                name="forceUpdate"
                            >
                                <MenuItem value="YES">YES</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={6} className="flex relative">
                <TextField id="outlined-basic" label="Outlined" variant="outlined" fullWidth className="w-100 input-square" />
                </Grid> */}
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                className="flex items-center justify-center"
                style={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
            >
                <div className="mr3 mt-4 m-4">
                    <Button type="button" className="button-sm normal-btn-space box-shadow flex items-center pa3" onClick={handleSubmit}>
                        SAVE
                    </Button>
                </div>
            </Grid>
        </div>
    );
};

export default EditAppUpdateSetting;
