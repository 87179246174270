import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import EditVenue from './pages/venues/edit';
import EditOutlet from './pages/venues/outlets/edit';
import EditEvent from './pages/events/edit';
import Events from './pages/events';
import EditUser from './pages/users/edit';
import Featured from './pages/home/featured';
import HomePage from './pages/home';
import Login from './pages/authentication/login';
import Outlets from './pages/venues/outlets/index';
import Users from './pages/users';
import Venues from './pages/venues';
import Redemption from './pages/redemptions';
import Notifications from './pages/notifications';
import ForgetPasswordaPage from './pages/forgetPassword';
import ReportScreen from './pages/report';
import ResetPasswordScreen from './pages/resetPassword';
import NotFound from './pages/notFound';
import AppUpdateSetting from './pages/appUpdateSetting/AppUpdateSetting';
import EditAppUpdateSetting from './pages/appUpdateSetting/EditAppUpdateSetting';
import RegisterVendor from './pages/register';
import UpdateVendor from './pages/updatePlan';
import moment from "moment";

const Main = (props) => {
  const { user } = useSelector(store => ({
    user: store.auth.user
  }), shallowEqual);

  function RequireAuth({ children, redirectTo, endpoint }) {
    if (user) {
      if (user.role === "admin") {
        return children;
      }

      if ((user.role === "vendor" && endpoint)) {
        const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
        if (user?.package && moment(currentDate).isBefore(user?.endDate)) {
          return children;
        }
        return <Navigate to="/user-plan/1" />
      } else {
        return <Navigate to="/" />
      }
    }
    else return <Navigate to={redirectTo} />
  }

  return (
    <div>
      <Router history={props.history}>
        <div>
          <Routes>
            {/* unprotected routes  */}
            <Route path="/" element={<Login />} />
            <Route path="/register-vendor" element={<RegisterVendor />} />
            <Route path="/reset-password/:id/:unixTime" element={<ResetPasswordScreen />} />
            <Route path="/forget-password" element={<ForgetPasswordaPage />} />
            <Route path="/user-plan/:type" element={<UpdateVendor user={user} />} />

            {/* protected routes  */}
            <Route path="/home" element={<RequireAuth redirectTo="/"><HomePage /></RequireAuth>} />
            <Route path="/venues" element={<RequireAuth endpoint="/venues" redirectTo="/"><Venues /></RequireAuth>} />
            <Route path="/events" element={<RequireAuth endpoint="/events" redirectTo="/"><Events /></RequireAuth>} />
            <Route path="/venues/add" element={<RequireAuth endpoint="//venues/add" redirectTo="/"><EditVenue /></RequireAuth>} />
            <Route path="/venues/edit/:id" element={<RequireAuth endpoint="//venues/edit/:id" redirectTo="/"><EditVenue /></RequireAuth>} />
            <Route path="/outlets" element={<RequireAuth endpoint="/outlets" redirectTo="/"><Outlets /></RequireAuth>} />
            <Route path="/outlets/add" element={<RequireAuth endpoint="/outlets/add" redirectTo="/"><EditOutlet /></RequireAuth>} />
            <Route path="/outlets/edit/:id" element={<RequireAuth endpoint="/outlets/edit/:id" redirectTo="/"><EditOutlet /></RequireAuth>} />
            <Route path="/events/add" element={<RequireAuth endpoint="/events/add" redirectTo="/"><EditEvent /></RequireAuth>} />
            <Route path="/events/edit/:id" element={<RequireAuth endpoint="/events/edit/:id" redirectTo="/"><EditEvent /></RequireAuth>} />
            <Route path="/users" element={<RequireAuth redirectTo="/"><Users /></RequireAuth>} />
            <Route path="/users/edit" element={<RequireAuth redirectTo="/"><EditUser /></RequireAuth>} />
            <Route path="/redemptions" element={<RequireAuth endpoint="/redemptions" redirectTo="/"><Redemption /></RequireAuth>} />
            <Route path="/notifications" element={<RequireAuth redirectTo="/"><Notifications /></RequireAuth>} />
            <Route path="/featured" element={<RequireAuth redirectTo="/"><Featured /></RequireAuth>} />
            <Route path="/report" element={<RequireAuth endpoint="/report" redirectTo="/"><ReportScreen /></RequireAuth>} />
            <Route path="/appUpdateSetting" element={<RequireAuth redirectTo="/"><AppUpdateSetting /></RequireAuth>} />
            <Route path="/appUpdateSetting/edit/:id" element={<RequireAuth redirectTo="/"><EditAppUpdateSetting /></RequireAuth>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default Main;
