import api, { DASHBOARD_APIS } from "../config/api-config";

export const fetchAllCategory = (setisLoading,setmodal) => {
    setisLoading(true);
    let event = api.get(DASHBOARD_APIS.event);
    let restaurant = api.get(DASHBOARD_APIS.restaurant);
    // let bar = api.get(DASHBOARD_APIS.bar+"/0");
    let brunch = api.get(DASHBOARD_APIS.brunch);
    let explore = api.get(DASHBOARD_APIS.explore);
    let outlet = api.get(DASHBOARD_APIS.outlet);
    Promise.all([outlet,event, restaurant, brunch, explore])
      .then(allCategry => {
        if (allCategry.length) {
          let finalFeatured = {
            featuredOutlets:allCategry[0].data.data,
            featuredEvents: allCategry[1].data.data,
            featuredRestaurants: allCategry[2].data.data,
            // featuredBars: allCategry[3].data.data,
            featuredBrunches: allCategry[3].data.data,
            featuredExplore: allCategry[4].data.data,
          };
          setmodal(finalFeatured);
        }
        setisLoading(false);
      })
      .catch(err => {
        console.log('err: ', err);
        setisLoading(false);
      });
  };