import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, Button, TableCell, TableRow, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import { Add } from "@mui/icons-material";
import Navbar from "../../components/navbar";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import TableList from "./table-list";
import { fetchAllVenues, searchVenues, setVenueItem } from "../../redux/reducers/venues";
import { fetchUserDetails } from "../../redux/reducers/users";
import { storageToken } from "../../config/constants";
import "./venues.scss";
// import { useSortableData } from "../../components/SortTable";
import { useLocation } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Loader from "../../components/loader";

const Venues = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');

  const [cnt, setcnt] = useState(0)
  const [search, setSearch] = useState(searchQuery ?? "");
  const [offset, setoffset] = useState(0);
  const [sortConfig, setSortConfig] = useState(null);
  const [key, setKey] = useState(null);
  const { venuesList, isLoading, user } = useSelector(
    (store) => ({
      venuesList: store?.venues?.venuesList ?? [],
      isLoading: store.venues.isLoading,
      user: store.users.user
    }),
    shallowEqual
  );

  useEffect(() => {
    const userToken = window.localStorage.getItem(storageToken);
    const userData = jwt_decode(userToken);
    dispatch(fetchUserDetails(userData.id));

    if (search?.length) {
      dispatch(searchVenues(search, key, sortConfig)).then(res => {
        setcnt(res.length)
      })
    } else {
      dispatch(fetchAllVenues(offset, key, sortConfig)).then(res => {
        setcnt(res.count)
      })
    }
    dispatch(setVenueItem(null));
  }, [offset, sortConfig, key]);

  const handleSearch = () => {
    if (search?.length) {
      dispatch(searchVenues(search, key, sortConfig)).then(res => {
        setcnt(res.length)
      })
    } else {
      dispatch(fetchAllVenues(offset, key, sortConfig)).then(res => {
        setcnt(res.count)
      })
    }
  };

  useEffect(() => {
    if (search.length === 0)
      handleSearch(search);
  }, [search]);

  return (
    <div>
      <Navbar />
      {user && user.role === "admin" ? <BreadcrumbDouble breadFirst="Home" breadLast="Venue (Hotel/Area)" linkFirst="/" /> : ""}
      {isLoading && <Loader />}
      <div className="container-main">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className="flex items-center">
              <input
                type="text"
                className="inpt-width"
                placeholder="Search Venues (Hotel/Area) by name..."
                onChange={(evt) => setSearch(evt?.target?.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearch(search);
                  }
                }}
                value={search}
              />
              <Button className="search flex justify-center items-center" onClick={() => { handleSearch() }}>
                <img src="/images/search.png" alt="search" />
              </Button>
            </div>
              <div className="text-grey font-16-semiBold ml3">
                <span>{cnt} </span>Results
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <div className="tr-ns tl-m ma1">
              <div className="flex flex-row fr-ns fl-m">
                <div className="mr3">
                  <Button
                    type="button"
                    href="/venues/add"
                    className="button-sm"
                  >
                    <div className="plus-ico">
                      <Add />
                    </div>
                    NEW VENUE (HOTEL/AREA)
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="mt4 table-main">
          <TableContainer>
            <Table className="w-100" sx={{ minWidth: 991 }}>
              <TableHead>
                <TableRow>
                  {/*<TableCell>ID <span className="filter-icon"></span></TableCell>*/}
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("venueName");
                    }}
                  >
                    Venue (Hotel/Area) Name
                    {sortConfig && key === "venueName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  {/* <TableCell
                    onClick={() => requestSort("outlets[0].outletName")}
                    className={getClassNamesFor("outlets[0].outletName")} 
                  >
                    Listing
                    {sortConfig ? (
                      sortConfig.key === "outlets[0].outletName" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                          <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>  
                    )}
                  </TableCell> */}
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("updatedAt");
                    }}
                  >
                    Last Updated
                    {sortConfig && key === "updatedAt" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  {/*<TableCell>Days Featured <span className="filter-icon"></span></TableCell>
                  <TableCell>Views <span className="filter-icon"></span></TableCell>
                  <TableCell>Redemptions <span className="filter-icon"></span></TableCell>*/}
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("isActive");
                    }}
                  >
                    Status
                    {sortConfig && key === "isActive" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {venuesList?.map((item, index) => (
                  <TableList key={"Outlet_" + index} item={item} index={index + 1} isVenue search={search} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="button"
            className="btn"
            onClick={() => {
              if (offset < 0) {
                setoffset(0);
              } else {
                setoffset(offset - 10);
              }
            }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            type="button"
            className="btn"
            onClick={() => {
              setoffset(offset + 10);
            }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>

    </div>
  );
};
export default Venues;
