import {
  EVENTS_LIST, EVENTS_ERROR, EVENTS_LOADING, EVENTS_ITEM
} from '../state-constants';
import api, { EVENT_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';
import { setUsersLoading } from './users';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  event: null,
  eventsList: []
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case EVENTS_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case EVENTS_ITEM:
      return {
        ...state,
        event: action.event
      };

    case EVENTS_LIST:
      return {
        ...state,
        eventsList: action.eventsList
      };

    case EVENTS_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null,
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: EVENTS_ERROR, error: error?.response?.data?.message ?? error });
};

export const setEventLoading = loading => {
  return dispatch => dispatch({ type: EVENTS_LOADING, loading });
};

export const setEventItem = event => {
  return dispatch => dispatch({ type: EVENTS_ITEM, event });
};

export const fetchAllEvents = (type, offset, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setEventLoading(true));
      api.get(type ? EVENT_APIS.events : `${EVENT_APIS.admin}?offset=${offset ? offset : 0}&key=${key ? key : null}&sortConfig=${sortConfig ? sortConfig : null}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: EVENTS_LIST,
            eventsList: data?.data
          });

          dispatch(setEventLoading(false));
          resolve(data);
        })
        .catch(err => {
          dispatch(setEventLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setEventLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const addEvent = (payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setEventLoading(true));
      api.post(EVENT_APIS.events, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(setEventLoading(false));
          if (!searchQuery) dispatch(fetchAllEvents());
          resolve(response?.data?.data);
        })
        .catch(err => {
          dispatch(setEventLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setEventLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updateEvent = (id, payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setEventLoading(true));
      api.put(`${EVENT_APIS.events}/${id}`, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          } else if (response?.data?.status && response?.data?.data && !searchQuery) {
            dispatch(fetchAllEvents());
          }
          dispatch(setEventLoading(false));
          resolve({ response });
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const deleteEvent = (id, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setEventLoading(true));
      api.delete(`${EVENT_APIS.events}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          if (!searchQuery) dispatch(fetchAllEvents());
          dispatch(setEventLoading(false));
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const fetchEventById = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setEventLoading(true));
      api.get(`${EVENT_APIS.events}/${id}`)
        .then(response => {
          console.log(JSON.stringify(response),'deal cancelled');
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch(setEventLoading(false));
          dispatch(setEventItem(data?.data));

          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setEventLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setEventLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const searchEvents = (keyword, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      api
        .post(EVENT_APIS?.search, { keyword, key, sortConfig })
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (data) {
            dispatch({ type: EVENTS_LIST, eventsList: data?.data });
            resolve(data?.data);
            return;
          }

          if (!data?.data) {
            resolve([]);
            return;
          }
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    }
    catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  });
};
