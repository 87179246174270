import React, { useEffect, useState } from "react";
import { Grid, TextField, MenuItem, Button, TableCell, TableRow, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import TableList from "./table-list";
import { fetchAllRedemptions, searchRedemptions } from "../../redux/reducers/redemptions";
import { fetchAllEvents } from "../../redux/reducers/events";
import "./redemtions.scss";
import moment from "moment";
// import { useSortableData } from "../../components/SortTable";
import exportFromJSON from "export-from-json";
import Loader from "../../components/loader";
import jwt_decode from 'jwt-decode';
import { storageToken } from "../../config/constants";
import { fetchUserDetails } from "../../redux/reducers/users";

const Redemption = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [text, setText] = useState("");
  const [offset, setoffset] = useState(0);
  const [isExportLoading, setIsExportLoading] = useState(false);
  // const [exportFlag, setExportFlag] = useState(false);
  const [filterData, setFilterData] = useState({ startDate: '', endDate: '', id: '' });
  const [sortConfig, setSortConfig] = useState(null);
  const [key, setKey] = useState(null);

  const { events, isLoading, redemptions, exportRedemptionsData, count, user } = useSelector(
    (store) => ({
      events: store?.events?.eventsList,
      isLoading: store?.redemptions?.isLoading,
      redemptions: store?.redemptions?.redemptionsList,
      exportRedemptionsData: store?.redemptions?.exportRedemptionsData,
      count: store?.redemptions?.count,
      user: store.users.user
    }),
    shallowEqual
  );

  useEffect(() => {
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
  }, [])

  useEffect(() => {
    if ((search && search?.length) || (filterData.startDate || filterData.endDate || filterData.id)) {
      dispatch(searchRedemptions(search, filterData, offset, key, sortConfig));
    } else {
      dispatch(fetchAllRedemptions(offset, key, sortConfig));
    }
    dispatch(fetchAllEvents(1, offset));
  }, [offset, filterData, search, key, sortConfig]);

  // const { items, requestSort, sortConfig } = useSortableData(redemptions);

  // useEffect(() => {
  //   // console.log('exportRedemptionsData', exportRedemptionsData)
  //   if (exportFlag) {
  //     // console.log('exportFlag: ', exportFlag);
  //     downloadExportDataFn(exportRedemptionsData)
  //     setExportFlag(false)
  //   }
  // }, [exportRedemptionsData, exportFlag]);

  // const downloadExportDataFn = (items) => {
  //   // const exportData = []
  //   // items.map((option, index) => {
  //   //   console.log('option: ', option?.user?.email);
  //   //   const time = moment(option?.createdAt).format("HH:mm");
  //   //   const date = moment(option?.createdAt).format("DD/MM/YYYY");
  //   //   exportData.push({ offerName: option?.offer?.offerName, outlet: option?.itemDetails?.eventName,  email: option?.user?.email, date: date, time: time, code: option?.code})
  //   // })
  //   console.log('items: ', items.length);
  //   console.log('exportRedemptionsData: ', exportRedemptionsData.length);
  //   if (items.length > 0) {
  //     const finalData = exportRedemptionsData;
  //     exportFromJSON({
  //       data: finalData,
  //       fileName: "redemptions",
  //       exportType: exportFromJSON.types.csv,
  //     });
  //     // exportData.splice(0,exportData.length)
  //     setIsExportLoading(false)
  //   }
  // }

  const exportSelectionDataFn = () => {
    if (redemptions.length > 0) {
      setIsExportLoading(true);
      const exportData = [], data = JSON.parse(JSON.stringify(redemptions));

      data.map((element) => {
        // delete element._id;
        // delete element.itemDetails._id
        // delete element.itemDetails.id
        // delete element.itemDetails.createdAt;
        // delete element.itemDetails.logoImage;
        // delete element.itemDetails.bannerImage;
        // delete element.itemDetails.timings;
        // delete element.itemDetails.otherImages;

        // delete element.itemDetails.offer._id
        // delete element.itemDetails.offer.id
        // delete element.itemDetails.offer.createdAt

        // let offerDetail = element.itemDetails.offer;
        // delete element.itemDetails.offer;
        // let eventDetail = element.itemDetails;
        // delete element.itemDetails;
        exportData.push({
          id: element.id,
          code: element?.code,
          outletName: element?.itemDetails?.eventName || element?.itemDetails?.outletName,
          offerName: element?.itemDetails?.offer?.offerName,
          userId: element?.user?.id,
          email: element?.user?.email,
          date: moment(element.createdAt).format("DD/MM/YYYY"),
          time: moment(element.createdAt).utcOffset(4).format("HH:mm")
        })
      })

      exportFromJSON({
        data: exportData,
        fileName: "redemptions",
        exportType: exportFromJSON.types.csv,
      });
      setIsExportLoading(false)
    }
  }
  const exportDataFn = () => {
    setIsExportLoading(true)
    dispatch(fetchAllRedemptions('export'));
    exportFromJSON({
      data: exportRedemptionsData,
      fileName: "redemptions",
      exportType: exportFromJSON.types.csv,
    });
    setIsExportLoading(false)
  }

  // const handleSearch = (keyword) => {
  // if (keyword && keyword?.length || filterData.startDate || filterData.endDate || filterData.id) {
  // dispatch(searchRedemptions(keyword, filterData, offset));
  // } else {
  //   dispatch(fetchAllRedemptions(offset));
  // }
  // };

  // useEffect(() => {
  //   dispatch(searchRedemptions(search, filterData, offset));
  // }, [filterData]);

  // const getClassNamesFor = (name) => {
  //   if (!sortConfig) {
  //     return;
  //   }
  //   return sortConfig.key === name ? sortConfig.direction : undefined;
  // };
  return (
    <div>
      <Navbar />
      {user?.role === "admin" && <BreadcrumbDouble
        breadFirst="Home"
        breadLast="Deal Redemptions"
        linkFirst="/"
      />}
      {isLoading && <Loader />}
      {isExportLoading && <Loader />}
      <div className="container-main ">
        <h1 className="header mb3">Deal Redemptions Data</h1>
        <div className="mb4">
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <div className="flex items-center">
                <input
                  type="text"
                  className="inpt-width"
                  placeholder="Search by Deal / Listing Name..."
                  onChange={(evt) => { setText(evt?.target?.value); if (!evt?.target?.value) { setSearch(text) } }}
                  value={text}
                // onKeyPress={event => {
                //   if (event.key === 'Enter') {
                //     setSearch(search);
                //     // handleSearch(search);
                //   }
                // }}
                />
                <Button className="search flex justify-center items-center" onClick={() => setSearch(text)}>
                  <img src="/images/search.png" alt="search" />
                </Button>
              </div>
                <div className="text-grey font-16-semiBold ml3">
                  <span>{count} </span>Results
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <div className="flex flex-row fr-ns fl-m">
                {(search && search?.length > 0 || filterData.startDate || filterData.endDate || filterData.id) && <div className="mr3">
                  <Button
                    type="button"
                    className="button-sm normal-btn-space button-normal box-shadow"
                    onClick={exportSelectionDataFn}
                  >
                    EXPORT SELECTION
                  </Button>
                </div>}
                <div className="mr3">
                  <Button
                    type="button"
                    className="button-sm normal-btn-space bg-secondry-yellow box-shadow"
                    onClick={() => {
                      setSearch("");
                      setText("");
                      setFilterData({
                        startDate: '',
                        endDate: '',
                        id: ''
                      })
                    }}
                  >
                    {" "}
                    CLEAR
                  </Button>
                </div>
                <Button
                  type="button"
                  className="button-sm normal-btn-space button-normal box-shadow"
                  onClick={exportDataFn}
                >
                  EXPORT
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container lg={12} spacing={2} className="flex items-center">
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className="flex w-100 relative">
              <input
                type="date"
                placeholder="From Date"
                className="w-100 input-square"
                onChange={(evt) => {
                  setFilterData({ ...filterData, startDate: evt?.target.value })
                }
                }
                value={filterData.startDate}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} className="flex items-center">
            <div className="flex w-100 relative">
              <input
                type="date"
                placeholder="To Date"
                className="w-100 input-square"
                onChange={(evt) => {
                  setFilterData({ ...filterData, endDate: evt?.target.value })
                }
                }
                value={filterData.endDate}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} className="flex items-center justify-center" >
            <TextField fullWidth label="Deal Type"
              onChange={(evt) => {
                setFilterData({ ...filterData, id: evt?.target.value })
                // setformData({ ...formData, offerLink: evt.target.value });
              }}
              select>
              {events.map((option) => (
                <MenuItem key={option.id} value={option?.offer?.id}>
                  {`${option?.eventName} - ${option?.offer?.offerName}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div className="mt4 table-main">
          <TableContainer>
            <Table className="w-100" sx={{ minWidth: 991 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("itemDetails.offer.offerName");
                    }}
                  >
                    Deal
                    {sortConfig && key === "itemDetails.offer.offerName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("itemDetails.eventName");
                    }}
                  >
                    Listing
                    {sortConfig && key === "itemDetails.eventName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  {user?.role !== "vendor" && <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("user.email");
                  // }}
                  >
                    User Email
                    {/* {sortConfig && key === "user.email" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>}
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("createdAt");
                    }}
                  >
                    Date
                    {sortConfig && key === "createdAt" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("createdAt");
                  // }}
                  >
                    Time
                    {/* {sortConfig && key === "createdAt" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell>
                    Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redemptions?.map((item, index) => (
                  <TableList item={item} key={index} user={user}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="button"
            className="btn"
            onClick={() => {
              if (offset < 0) {
                setoffset(0);
              } else {
                setoffset(offset - 10);
              }
            }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            type="button"
            className="btn"
            onClick={() => {
              setoffset(offset + 10);
            }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Redemption;