import React, { useEffect, useReducer, useState } from "react";
import {
    Box,
    Grid,
    Select,
    AppBar,
    Toolbar,
    MenuItem,
    IconButton,
    Typography,
    InputLabel,
    FormControl,
    Autocomplete,
    TextField
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { baseURL } from "../../../config/constants";
import { CATEGORY, VENDOR_APIS } from "../../../config/api-config";
import { initReducer } from "../../../utils/common-methods";
import { NATIONALITIES_LIST } from "../../../config/constants";

const Vendor = ({ setStep, setisLoading, formData, setFormData }) => {

    const [categories, setCategories] = useState([]);
    const [state, setState] = useReducer(initReducer, {
        showPwd: false,
        showConfirmPwd: false,
    });
    const [confirmPassword, setConfirmPassword] = useState("");
    const [country, setCountry] = useState("AE");
    const [vendor, setVendor] = useState({
        businessName: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        category: "",
        password: "",
        phone: "",
        address: "",
        city: "",
        country: "AE",
        isActive: false
    })

    useEffect(() => {
        setisLoading(true);

        axios.get(baseURL + CATEGORY.all).then(response => {
            if (response.status === 200) {
                setCategories(response.data.data);
            }
            setisLoading(false)
        }).catch(err => {
            setisLoading(false)
        })
    }, []);

    const handleChange = (e) => {
        setVendor({ ...vendor, [e.target.name]: e.target.value });
    }

    const handleSubmit = () => {
        for (let prop in vendor) {
            if (vendor.hasOwnProperty(prop) && prop != "isActive") {
                vendor[prop] = vendor[prop].trim();
            }
        }

        if (!vendor.businessName) {
            Swal.fire('Required', 'Business name is required', 'error');
            return;
        }
        if (!vendor.password || !confirmPassword || !vendor.firstName || !vendor.lastName || !vendor.email || !vendor.category || !vendor.address || !vendor.city || !vendor.country) {
            Swal.fire("Required", "All fields are mandatory", "error");
            return;
        }
        if (vendor.password.length < 6 || confirmPassword.length < 6) {
            Swal.fire("Required", "Password must have at least 6 digits", "error");
            return;
        }
        if (vendor.password !== confirmPassword) {
            Swal.fire("Both Password Not Match", "Please try again", "error");
            return;
        }

        if (!vendor.phone) {
            Swal.fire("Invalid", "Invalid phone number", "error");
            return;
        }

        if (!(vendor.country.length >= 2 && vendor.country.length <= 3)) {
            Swal.fire("Invalid", "Invalid country code", "error");
            return;
        }

        axios.post(baseURL + VENDOR_APIS.register, vendor).then(response => {
            if (response?.data?.data?.emailExists) {
                Swal.fire("", "Email already exist", "error");
            } else if (!response?.data?.status) {
                Swal.fire("", response?.data?.message || "Something went wrong", "error");
            }
            else {
                setFormData({ ...formData, vendor: response?.data?.data })
                setStep(2);
            }
            setisLoading(false);

        }).catch(err => {
            console.log("error-->", err);
            setisLoading(false);
        })
    }

    return (
        <div>
            <AppBar position="static" className="appbar">
                <Toolbar>
                    <img src="images/lets.png" alt="Logo" />
                </Toolbar>
            </AppBar>
            <Grid container className="m-auto mb-3 flex items-center justify-center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="mt-3 register-vendor-header">
                    <h2 className="h1 weight-semi-bold">Join Us!</h2>
                    <Typography variant="body1" gutterBottom className="p-0 pt-3">
                        Simply complete the details below, select your preferred subscription option and we'll set
                        you up with an account
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className="m-auto register-vendor-form flex items-center justify-center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="businessName"
                            value={vendor.businessName}
                            onChange={handleChange}
                            placeholder="Business Name *"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid container className="m-3 firstLastNames">

                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                        paddingRight: 1
                    }}>
                        <div className="flex relative">
                            <input
                                type="text"
                                name="firstName"
                                value={vendor.firstName}
                                onChange={handleChange}
                                placeholder="First Name *"
                                className="w-100 input-square"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingLeft: 1 }}>
                        <div className="flex relative">
                            <input
                                type="text"
                                name="lastName"
                                value={vendor.lastName}
                                onChange={handleChange}
                                placeholder="Last Name *"
                                className="w-100 input-square"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="email"
                            name="email"
                            value={vendor.email}
                            onChange={handleChange}
                            placeholder="Email Address *"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="phone"
                            name="phone"
                            value={vendor.phone}
                            onChange={handleChange}
                            placeholder="Phone Number *"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="category">Venue Category *</InputLabel>
                            <Select
                                name="category"
                                labelId="category"
                                value={vendor.category}
                                label="Venue Category *"
                                onChange={handleChange}
                            >
                                {categories.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type={state.showPwd ? "text" : "password"}
                            name="password"
                            value={vendor.password}
                            onChange={handleChange}
                            placeholder="Password *"
                            className="w-100 input-square"
                        />

                        <IconButton className="eye-icon" onClick={() => setState({ showPwd: !state.showPwd })} >
                            {state.showPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type={state.showConfirmPwd ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(evt) => {
                                setConfirmPassword(evt.target.value);
                            }}
                            placeholder="Confirm Password *"
                            className="w-100 input-square"
                        />

                        <IconButton className="eye-icon" onClick={() => setState({ showConfirmPwd: !state.showConfirmPwd })} >
                            {state.showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="address"
                            value={vendor.address}
                            onChange={handleChange}
                            placeholder="Address  *"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <div className="flex relative">
                        <input
                            type="text"
                            name="city"
                            value={vendor.city}
                            onChange={handleChange}
                            placeholder="City *"
                            className="w-100 input-square"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="m-3">
                    <Autocomplete
                        disablePortal
                        options={NATIONALITIES_LIST}
                        value={NATIONALITIES_LIST.find(countryData => countryData.value === country)}
                        onChange={(event, value) => {
                            if (value) {
                                setVendor({ ...vendor, country: value.value });
                                setCountry(value.value);
                            }
                        }}
                        renderInput={params => <TextField {...params} label="Select country" />}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className="flex items-center justify-center" style={{ margin: "0 auto" }} >
                <div className="mr3 mt-4 m-4 select-btn">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="next-step-button normal-btn-space flex items-center pa3 btn"
                    >
                        NEXT STEP
                    </button>
                </div>
            </Grid>
            <footer className="footer">
                <div className="container">
                    <div className="footer-content">
                        <div className="footer-payment-info">
                            <p>Mandatory Payment Requirement</p>
                            <p>Account and Plan</p>
                        </div>
                        <div className="footer-payment-info">
                            <p>Contact</p>
                            <p>info@letsdxb.com</p>
                        </div>
                        <div className="footer-links">
                            <a href="https://letsdxb.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                            <Link to="/cancellation-policy">Cancellation Policy</Link>
                            <Link to="/refund-policy">Refund Policy</Link>
                        </div>
                        <div className="footer-logos">
                            <img src="/images/visa.png" alt="Visa Logo" />
                            <img src="/images/mastercard.png" alt="Mastercard Logo" />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Vendor;
