import React, { useState } from "react";
import Vendor from "./component/vendor";
import Package from "./component/package";
import Payment from "./component/payment";
import Loader from "../../components/loader";
import "./component/styles.scss";

const initialData = {
    vendor: null,
    selectedPlan: null,
    listingNumber: 2
}

const RegisterVendor = () => {

    const [isLoading, setisLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialData);
    const [done, setDone] = useState('NOT CALLED');

    return (
        <div>
            {isLoading && <Loader />}

            {step === 1 && <Vendor
                setStep={setStep}
                setisLoading={setisLoading}
                formData={formData}
                setFormData={setFormData}
            />}

            {step === 2 && <Package
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
                setisLoading={setisLoading}
                setDone={setDone}
            />}

            {step === 3 && <Payment
                formData={formData}
                setisLoading={setisLoading}
                called={done}
            />}
        </div>
    );
};

export default RegisterVendor;
