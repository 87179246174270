import {
  USERS_LIST,
  USERS_ERROR,
  USERS_LOADING,
  USERS_DATA,
  EXPORT_USERS_LIST,
} from "../state-constants";
import api, { USER_APIS, USER_APIS_V1 } from "../../config/api-config";
import {
  handle400Rejections,
  COMMON_API_ERR_MSG,
} from "../../config/constants";

const initialState = {
  error: null,
  isError: false,
  isLoading: false,
  user: null,
  usersList: [],
  exportUsersList: []

};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;

    case USERS_DATA:
      return {
        ...state,
        user: action.user,
        error: null,
        isError: false,
      };

    case USERS_ERROR:
      return {
        ...state,
        error: action.error,
        isError: true,
        isLoading: false,
      };

    case USERS_LIST:
      return {
        ...state,
        usersList: action.usersList,
        error: null,
        isError: false,
      };
    case EXPORT_USERS_LIST:
      return {
        ...state,
        exportUsersList: action.exportUsersList,
      };

    case USERS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        isError: false,
      };
  }
}

export default reducer;

const handleErr = (dispatch, error) => {
  dispatch({
    type: USERS_ERROR,
    error: error?.response?.data?.message ?? error,
  });
};

export const setUser = (user) => {
  return (dispatch) => dispatch({ type: USERS_DATA, user });
};

export const setUsersLoading = (loading) => {
  return (dispatch) => dispatch({ type: USERS_LOADING, isLoading: loading });
};

export const fetchUsersList = (offset='', key, value, type) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setUsersLoading(true));
      try {
        api
          .get(USER_APIS_V1.all + `?offset=${offset}&key=${key}&value=${value}&type=${type}`)
          .then((response) => {
            if (response.status > 399) {
              dispatch(setUsersLoading(false));
              handleErr(dispatch, COMMON_API_ERR_MSG);
              handle400Rejections(response.status, reject);
              return;
            }

            const { data } = response;

            if (offset === 'today' || offset === '7days' || offset === '30days') {
              dispatch({ type: EXPORT_USERS_LIST, exportUsersList: data.data });
              dispatch(setUsersLoading(false));
              resolve(data);
              return;
            } else {
              dispatch({ type: USERS_LIST, usersList: data.data });
              dispatch(setUsersLoading(false));
              resolve(data);
              return;
            }

            // dispatch(setUsersLoading(false));
            // resolve(data);
            // console.log('data: ', data);
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        dispatch(setUsersLoading(false));
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

export const fetchUserDetails = (userId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setUsersLoading(true));
      try {
        api
          .get(`${USER_APIS.userDetails}/${userId}`)
          .then((response) => {
            if (response.status > 399) {
              dispatch(setUsersLoading(false));
              handleErr(dispatch, COMMON_API_ERR_MSG);
              handle400Rejections(response.status, reject);
              return;
            }

            const userData = response?.data?.data;
            dispatch(setUser(userData));
            dispatch(setUsersLoading(false));
            resolve(userData);
          })
          .catch((err) => {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        dispatch(setUsersLoading(false));
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

export const updateUser = (userId, payload) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        api
          .put(USER_APIS.adminUsers, { userId, userData: payload })
          .then((response) => {
            console.log('response.data.data: ', response.data.data);
            resolve(response);
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

export const updateSingleUser = (userId, payload) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        api
          .put(USER_APIS.updateSingleUsr + userId, payload)
          .then((response) => {
            resolve({});
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

export const addUser = (payload) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        api
          .post(USER_APIS.adminUsers, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

export const deleteUser = (userId, search) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        api
          .delete(USER_APIS.users, {
            data: { userId },
          })
          .then(() => {
            if(!search) dispatch(fetchUsersList());
            resolve({});
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        handleErr(dispatch, error);
        reject(error);
      }
    });
};

/*export const fetchUsersList = () => {
  return dispatch => new Promise((resolve, reject) => {
    try {} catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};*/

export const searchUsers = (keyword) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        api
          .post(USER_APIS?.search, { keyword })
          .then((response) => {
            if (response.status > 399) {
              dispatch(setUsersLoading(false));
              handleErr(dispatch, COMMON_API_ERR_MSG);
              handle400Rejections(response.status, reject);
              return;
            }

            const { data } = response;

            if (data) {
              dispatch({ type: USERS_LIST, usersList: data?.data });
              dispatch(setUsersLoading(false));
              resolve(data?.data);
              return;
            }

            if (!data?.data) {
              resolve({});
              return;
            }
          })
          .catch((err) => {
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (e) {
        handleErr(dispatch, e);
        reject(e);
      }
    });
};
