import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import OffersList from "./offers";
import UserBox from "./user-list";
import { fetchAllFeatured } from "../../redux/reducers/dashboard";
import Loader from "../../components/loader";
import { fetchAllCategory } from "../../utils/fetchAllCategory";

// const initialData = {
//   plateform: 0,
//   title: "",
//   subTitle: "",
//   buildVersion: "",
//   buildCode: "",
//   forceUpdate: 0
// }


// const users = [
//   { heading: "Active Outlets", number: "3" },
//   { heading: "Active Offers", number: "1" },
//   { heading: "Redemptions", number: "0", days: "(Last 30 Days)" },
//   { heading: "Total Users", number: "4" },
//   { heading: "Active Users", number: "3", days: "(Used in last 30 days)" },
//   { heading: "Notifications Sent", number: "0", days: "(Last 30 Days)" },
// ];

const HomePage = () => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [dashboardFeatured, setdashboardFeatured] = useState({})
  // const [open, setOpen] = useState(false);
  // const [settingData, setSettingData] = useState(initialData);

  const { _dashboardFeatured } = useSelector(
    (store) => ({
      _dashboardFeatured: store.dashboard.dashboardFeatured
    }),
    shallowEqual
  );

  const [countCards, setCountCards] = useState([
    {
      heading: "Active Listings",
      number: "0",
      image: "/images/admin/card1.png",
    },
    { heading: "Active Deals", number: "0", image: "/images/admin/card2.png" },
    {
      heading: "Redemptions",
      number: "0",
      days: "(Last 30 Days)",
      image: "/images/admin/card3.png",
    },
    { heading: "Total Users", number: "0", image: "/images/admin/card4.png" },
    {
      heading: "Active Users",
      number: "0",
      days: "(Used in last 30 days)",
      image: "/images/admin/card5.png",
    },
    {
      heading: "Notifications Sent",
      number: "0",
      days: "(Last 30 Days)",
      image: "/images/admin/card6.png",
    },
  ]);

  // const handleClickOpen = () => {
  //   if (appUpdateSetting) {
  //     setSettingData({
  //       plateform: appUpdateSetting.plateform,
  //       title: appUpdateSetting.title,
  //       subTitle: appUpdateSetting.subTitle,
  //       buildVersion: appUpdateSetting.buildVersion,
  //       buildCode: appUpdateSetting.buildCode,
  //       forceUpdate: appUpdateSetting.forceUpdate,
  //       id: appUpdateSetting.id
  //     });
  //   } else {
  //     setSettingData(initialData);
  //   }
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setSettingData(initialData);
  //   setOpen(false);
  // };

  // const handleChange = (e) => {
  //   setSettingData({ ...settingData, [e.target.name]: e.target.value })
  // }

  // const handleSubmit = () => {
  //   if (!settingData.title || !settingData.subTitle || !settingData.buildCode || !settingData.buildVersion) {
  //     alert("All fields are required");
  //     return;
  //   }
  //   console.log(settingData)

  //   if(appUpdateSetting){
  //     dispatch(updateAppUpdateSetting(settingData));
  //   }else{
  //     dispatch(createAppUpdateSetting(settingData));
  //   }

  //   handleClose();
  //   // Swal.fire(
  //   //   'Success',
  //   //   `Venue successfully ${venue ? 'updated' : 'added'}`,
  //   //   'success'
  //   // );
  //   Swal.fire(
  //     'Success',
  //     'App update setting saved successfully',
  //     'success'
  //   );
  // }

  useEffect(() => {
    fetchAllCategory(setisLoading, setdashboardFeatured)
    dispatch(fetchAllFeatured())
  }, []);

  useEffect(() => {
    if (!_dashboardFeatured) return;

    const cardsData = [
      {
        heading: "Active Listings",
        number: _dashboardFeatured?.outletsCount ?? 0,
        color: "#fdcc09",
        image: "/images/admin/card1.png",
        url: "/outlets",
      },
      {
        heading: "Active Deals",
        number: _dashboardFeatured?.offersCount ?? 0,
        color: "#efb252",
        image: "/images/admin/card2.png",
        url: "/events",
      },
      {
        heading: "Redemptions",
        number: _dashboardFeatured?.redemptionsCount ?? 0,
        days: "",
        color: "#40c6bf",
        image: "/images/admin/card3.png",
        url: "/redemptions",
      },
      {
        heading: "Total Users",
        number: _dashboardFeatured?.totalUsersCount ?? 0,
        color: "#6ea308",
        image: "/images/admin/card4.png",
        url: "/users",
      },
      {
        heading: "Total users signed up - (New App)",
        number: _dashboardFeatured?.newAppUsersCount ?? 0,
        color: "#6ea308",
        image: "/images/admin/add-user.png",
        url: "/users",
      },
      {
        heading: "Total active (Past 6 months)",
        number: _dashboardFeatured?.sixMonthsActiveUsersCount ?? 0,
        color: "#6ea308",
        image: "/images/admin/card4.png",
        url: "/users",
      },
      {
        heading: "Total active (Past 3 months)",
        number: _dashboardFeatured?.threeMonthsActiveUsersCount ?? 0,
        days: "",
        image: "/images/admin/card5.png",
        url: "/users",
      },
      {
        heading: "Notifications Sent",
        number: _dashboardFeatured?.notificationsCount ?? 0,
        days: "",
        image: "/images/admin/card6.png",
        url: "/notifications",
      },
    ];

    setCountCards(cardsData);
  }, [_dashboardFeatured]);

  return (
    <div>
      <Navbar />
      {isLoading && <Loader />}
      <div className="container-main">
        <Grid
          container
          spacing={1}
          className="home-menu"
        >
          {countCards?.map((item, index) => (
            <UserBox item={item} key={index} />
          ))}
        </Grid>
        <div className="hr"></div>
        <div className="offer-title mt4 mb4">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <h1 className="ma0"> Featured Listings & Deals</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className="tr-ns tl-m ma1">
              <Button
                type="button"
                href="/featured"
                className="button-sm normal-btn-space flex items-center"
              >
                <DriveFileRenameOutlineRounded className="mr2" /> Add & Edit
              </Button>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          spacing={2}
          className="flex mt4 mb4"
        >
          {/* <OffersList
            title="Featured Listings"
            list={
              dashboardFeatured?.featuredOutlets?.map((item) => ({
                id: item?.id,
                name: item?.outletName ?? item?.eventName,
                type: item?.outletName ? "outlet" : "event"
              })) ?? []
            }
            color={"#db8251"}
          /> */}
          <OffersList
            title="Featured Offers"
            isEvents={true}
            list={
              dashboardFeatured?.featuredEvents?.map((item) => ({
                id: item?.id,
                name: item.type === "EVENT" ? `${item?.offer?.offerName} - ${item?.eventName}` : item?.outletName,
                type: item.type === "EVENT" ? "event" : "outlet",
                bannerImage: item?.bannerImage
              })) ?? []
            }
            color={"#7979cf"}
          />
          <OffersList
            title="Featured Eat & Drink"
            list={
              dashboardFeatured?.featuredRestaurants?.map((item) => ({
                id: item?.id,
                name: item.type === "EVENT" ? `${item?.offer?.offerName} - ${item?.eventName}` : item?.outletName,
                type: item.type === "EVENT" ? "event" : "outlet",
                bannerImage: item?.bannerImage
              })) ?? []
            }
            color={"#40c6bf"}
          />
          {/* <OffersList
            title="Featured Drink"
            list={
              dashboardFeatured?.featuredBars?.map((item) => ({
                id: item?.id,
                name: item?.outletName ?? item?.eventName,
                type: item?.outletName ? "outlet" : "event"
              })) ?? []
            }
            color={"#efb252"}
          /> */}
          <OffersList
            title="Featured Brunch"
            list={
              dashboardFeatured?.featuredBrunches?.map((item) => ({
                id: item?.id,
                name: item.type === "EVENT" ? `${item?.offer?.offerName} - ${item?.eventName}` : item?.outletName,
                type: item.type === "EVENT" ? "event" : "outlet",
                bannerImage: item?.bannerImage
              })) ?? []
            }
            color={"#fdcc09"}
          />
          <OffersList
            title="Featured Explore"
            list={
              dashboardFeatured?.featuredExplore?.map((item) => ({
                id: item?.id,
                name: item.type === "EVENT" ? `${item?.offer?.offerName} - ${item?.eventName}` : item?.outletName,
                type: item.type === "EVENT" ? "event" : "outlet",
                bannerImage: item?.bannerImage
              })) ?? []
            }
            color={"#6ea308"}
          />
        </Grid>
      </div>
      {/* <div className="setting" onClick={handleClickOpen}>
        <img src="/images/admin/talk.png" />
        <AppSettingsAltIcon fontSize="large" />
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>App Update Setting</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-select-currency"
            select
            label="Plateform"
            defaultValue={settingData.plateform}
            fullWidth
            style={{ margin: "10px 0" }}
            name="plateform"
            onChange={handleChange}
          >
            <MenuItem value={0}>IOS</MenuItem>
            <MenuItem value={1}>ANDROID</MenuItem>
          </TextField>

          <TextField required label="Title" margin="normal" variant="outlined" fullWidth name="title" value={settingData.title} onChange={handleChange} />
          <TextField label="Sub-Title" variant="outlined" fullWidth name="subTitle" value={settingData.subTitle} onChange={handleChange} />
          <TextField label="Build Version" variant="outlined" fullWidth name="buildVersion" value={settingData.buildVersion} onChange={handleChange} />
          <TextField label="Build Code" variant="outlined" fullWidth name="buildCode" value={settingData.buildCode} onChange={handleChange} />
          <TextField
            id="outlined-select-currency"
            select
            label="Force Update"
            defaultValue={settingData.forceUpdate}
            fullWidth
            style={{ margin: "10px 0" }}
            name="forceUpdate"
            onChange={handleChange}
          >
            <MenuItem value={1}>YES</MenuItem>
            <MenuItem value={0}>NO</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" className="button-danger normal-btn-space" >Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" className="button-sm normal-btn-space" >Save</Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default HomePage;