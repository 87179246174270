import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fetchOutletById, setOutletItem } from "../../redux/reducers/outlets";
import { fetchVenueById, setVenueItem } from "../../redux/reducers/venues";

const TableList = ({ item, isOutlet = false, isVenue = false, search }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let views = item.eventsWithOffers?.reduce(function (accumulator, curValue) {
    if (curValue?.offer?.views !== undefined) {
      return accumulator + curValue?.offer?.views;
    } else {
      return accumulator;
    }
  }, 0)

  let totalRedemption = item.eventsWithOffers?.reduce(function (accumulator, curValue) {
    if (curValue.redemptionCount !== undefined) {
      return accumulator + curValue?.redemptionCount;
    } else {
      return accumulator;
    }
  }, 0)

  return (
    <TableRow>
      {isVenue && (
        <>
          {/*<TableCell>{item?.id}</TableCell>*/}
          <TableCell>
            <div className="flex items-center">
              {" "}
              <img
                src={item?.logoImage ?? "/images/feat-img.png"}
                className="user-img"
                alt="logo"
              />
              {item?.venueName}{" "}
            </div>{" "}
          </TableCell>
          {/* <TableCell>
            {item?.outlets?.length > 0
              ? item?.outlets?.map((outlet) => outlet?.outletName)?.join(", ")
              : "No Listings attached"}
          </TableCell> */}
          <TableCell>
            {moment(item?.updatedAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          {/*<TableCell align="center">{item?.isFeatured ?
          moment().diff(moment(item?.featuredAt ?? item?.createdAt), 'days')
          : '-'
        }</TableCell>
        <TableCell align="center">0</TableCell>
        <TableCell align="center">0</TableCell>*/}
          <TableCell>{item?.isActive ? "Published" : "Draft"}</TableCell>
          <TableCell>
            <div
              onClick={() => {
                dispatch(setVenueItem(item));
                dispatch(fetchVenueById(item?.id));
                search ? navigate(`/venues/edit/${item?.id}?search=${search}`) : navigate(`/venues/edit/${item?.id}`);
              }}
            >
              <Edit />
            </div>
          </TableCell>
        </>
      )}

      {isOutlet && (
        <>
          {/*<TableCell>{item?.id}</TableCell>*/}
          <TableCell>
            <div className="flex items-center">
              {" "}
              <img src={item?.logoImage} className="user-img" alt="logo" />
              {item?.outletName}{" "}
            </div>{" "}
          </TableCell>
          <TableCell>
            {moment(item?.updatedAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell align="center">
            {item?.isFeatured && item?.featuredAt
              ? moment().diff(
                moment(item?.featuredAt),
                "days"
              )
              : "-"}
          </TableCell>
          <TableCell align="center">{views ?? 0}</TableCell>
          <TableCell align="center">{totalRedemption ?? 0}</TableCell>
          <TableCell>{item?.status ? "Published" : "Un-Published"}</TableCell>
          <TableCell>
            <div
              onClick={() => {
                dispatch(setOutletItem(item));
                dispatch(fetchOutletById(item?.id));
                navigate(`/outlets/edit/${item?.id}?search=${search}`);
                search ? navigate(`/outlets/edit/${item?.id}?search=${search}`) : navigate(`/outlets/edit/${item?.id}`);
              }}
            >
              <Edit />
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default TableList;
