import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './home.scss';

const UserBox = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="box-wrapper" onClick={() => navigate(item?.url)} >
      <div className="box h-100">
        <img src={item?.image} alt="admin" height="100" />
        <h4 className="ma0 pt3">{item?.heading}</h4>
        <div className='days pt2'>{item?.days}</div>
        <p className="ma0 pt2">{item?.number}</p>
      </div>
    </div>
  )
}

export default UserBox