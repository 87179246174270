import {
  REDEMPTIONS_LIST, REDEMPTIONS_ERROR, REDEMPTIONS_LOADING, REDEMPTIONS_ITEM, EXPORT_REDEMPTIONS_DATA
} from '../state-constants';
import api, { REDEMPTIONS_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';
import { setUsersLoading } from './users';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  redemption: null,
  redemptionsList: [],
  exportRedemptionsData: [],
  count: 0
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case REDEMPTIONS_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case REDEMPTIONS_ITEM:
      return {
        ...state,
        redemption: action.redemption
      };

    case REDEMPTIONS_LIST:
      return {
        ...state,
        redemptionsList: action.redemptionsList,
        count: action.count
      };

    case EXPORT_REDEMPTIONS_DATA:
      return {
        ...state,
        exportRedemptionsData: action.exportRedemptionsData
      };

    case REDEMPTIONS_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null,
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: REDEMPTIONS_ERROR, error: error?.response?.data?.message ?? error });
};

export const setRedemptionLoading = loading => {
  return dispatch => dispatch({ type: REDEMPTIONS_LOADING, loading });
};

export const setRedemptionItem = redemption => {
  return dispatch => dispatch({ type: REDEMPTIONS_ITEM, redemption });
};

export const fetchAllRedemptions = (offset, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setRedemptionLoading(true));
      api.get(`${REDEMPTIONS_APIS.getAll}?offset=${offset ? offset : 0}&key=${key ? key : null}&sortConfig=${sortConfig ? sortConfig : null}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          if (offset === 'export') {
            dispatch(setRedemptionLoading(true));
            dispatch({
              type: EXPORT_REDEMPTIONS_DATA,
              exportRedemptionsData: data?.data
            });
          } else {
            dispatch({
              type: REDEMPTIONS_LIST,
              redemptionsList: data?.data,
              count: data?.count
            });
          }

          dispatch(setRedemptionLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setRedemptionLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setRedemptionLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updateRedemption = (id, payload) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setRedemptionLoading(true));
      api.put(`${REDEMPTIONS_APIS.getAll}/${id}`, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(setRedemptionLoading(false));
          dispatch(fetchAllRedemptions());
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const deleteRedemption = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setRedemptionLoading(true));
      api.delete(`${REDEMPTIONS_APIS.getAll}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          dispatch(fetchAllRedemptions());
          dispatch(setRedemptionLoading(false));
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const fetchRedemptionByUserId = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setRedemptionLoading(true));
      api.get(`${REDEMPTIONS_APIS.getUser}${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch(setRedemptionLoading(false));
          dispatch(setRedemptionItem(data?.data));

          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setRedemptionLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setRedemptionLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const searchRedemptions = (keyword, filterData, offset, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(setRedemptionLoading(true));
    try {
      api
        .post(REDEMPTIONS_APIS.search, { keyword, filterData, offset, key, sortConfig })
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (data) {
            dispatch({
              type: REDEMPTIONS_LIST,
              redemptionsList: data?.data,
              count: data?.count
            });

            dispatch(setRedemptionLoading(false));
            resolve(data?.data);
            return;
          }

          resolve(data);
        })
        .catch(err => {
          dispatch(setRedemptionLoading(false));
          handleErr(dispatch, err);
          reject(err);
        })
    } catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  });
};
