import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Chip,
  Modal,
  Stack,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  DriveFileRenameOutline,
  CameraAlt,
  Add,
  Cancel,
  HorizontalRule,
  DeleteOutline,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { GoogleMap, Marker } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import Navbar from "../../../components/navbar";
import {
  addOutlet,
  deleteOutlet,
  fetchOutletById,
  setOutletItem,
  updateOutlet,
  setOutletLoading,
} from "../../../redux/reducers/outlets";
import { fetchAllVenues } from "../../../redux/reducers/venues";
import { initReducer, uploadFile } from "../../../utils/common-methods";
import {
  DRESS_CODES,
  PRICE,
  FACILITIES,
  CUISINES,
  WEEKDAYS,
} from "../../../config/constants";
import "../venues.scss";
import { handleCompressedUpload } from "../../../utils/ImageCompress";
import { Breadcrumbs, Link, Typography, Avatar } from "@mui/material";
import { ArrowBackIosNew, MoreHoriz } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import Loader from "../../../components/loader";
import { fetchUserDetails } from "../../../redux/reducers/users";
import { storageToken } from "../../../config/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditOutlet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search");

  const { outlet, venuesList, isLoading, user } = useSelector(
    (store) => ({
      outlet: store.outlets.outlet,
      events: store?.events?.eventsList,
      isLoading: store?.outlets?.isLoading,
      venuesList: store?.venues?.venuesList,
      user: store.users.user,
    }),
    shallowEqual
  );

  const initialState = {
    outletName: "",
    addressLine1: "",
    addressLine2: "",
    email: "",
    mobile: "",
    country: "Dubai",
    logoImage: null,
    bannerImage: null,
    otherImages: [],
    description: "",
    keywords: [],
    restaurantsOrBars: [],
    isRestaurant: false,
    // isBar: false,
    isBrunch: false,
    isActive: true,
    // isFeatured: true,
    is_desplay: false,
    terms: "",
    isExplore: false,
    dressCode: "",
    price: "100",
    facilities: [],
    cuisines: [],
    bookingLink: "",
    venueRating: 0,
    coords: [],
    associatedEvents: [],
    associatedBrunches: [],
    cuisineClass: "",
    timings: [
      { day: "Monday", startTime: "", endTime: "" },
      { day: "Tuesday", startTime: "", endTime: "" },
      { day: "Wednesday", startTime: "", endTime: "" },
      { day: "Thursday", startTime: "", endTime: "" },
      { day: "Friday", startTime: "", endTime: "" },
      { day: "Saturday", startTime: "", endTime: "" },
      { day: "Sunday", startTime: "", endTime: "" },
    ],
    redeemCode: "6262",
    associatedVenue: null,
    brunchPrice: "",
    status: false,
    isAdminApprove: false,
    whatsappBusinessLink: "",
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchOutletById(params?.id));
    }
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
    dispatch(fetchAllVenues("all"));
    // eslint-disable-next-line
  }, [params?.id]);

  const [state, setState] = useReducer(initReducer, initialState);
  const [events, setEvents] = useState(null);
  const [expiryType, setExpiryType] = useState(null);
  const [showAddTag, setShowTag] = useState(false);
  const [tag, setTag] = useState("");
  const [showEventModal, setShowEventModal] = useState(false);
  const [isEditBrunch, setEditBrunch] = useState(false);
  const [editBrunchIndex, setEditBrunchIndex] = useState(-1);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedRestaurantOrBar, setSelectedRestaurantOrBar] = useState(null);
  const [restaurantOrBarList, setRestaurantOrBarList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [center, setCenter] = useState({ lat: 25.2048493, lng: 55.2707828 });
  const [value, setValue] = useState("");
  const [eventState, setEventState] = useReducer(initReducer, {
    name: "",
    startDate: "",
    expiryDate: "",
    bannerImage: "",
    weekDays: [],
    eventDescription: "",
    untilTime: "",
    fromTime: "",
  });

  useEffect(() => {
    async function fetch() {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          address: value ? value.value.description : "",
        },
        function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            setState({
              coords: [
                results[0].geometry.location.lat(),
                results[0].geometry.location.lng(),
              ],
            });
            setCenter({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
            setOpenDialog(false);
          }
        }
      );
    }
    fetch();
  }, [value]);

  useEffect(() => {
    // if (!outlet) return;
    if (window.location.pathname === "/outlets/add") {
      if (!outlet) {
        dispatch(fetchOutletById(null));
        setState(initialState);
      }
    } else {
      setState({
        outletName: outlet?.outletName ?? "",
        addressLine1: outlet?.addressLine1 ?? "",
        addressLine2: outlet?.addressLine2 ?? "",
        email: outlet?.email ?? "",
        mobile: outlet?.mobile ?? "",
        country: outlet?.country ?? "Dubai",
        logoImage: outlet?.logoImage ?? null,
        bannerImage: outlet?.bannerImage ?? null,
        otherImages: outlet?.otherImages ?? [],
        description: outlet?.description ?? "",
        keywords: outlet?.keywords ?? [],
        isRestaurant: outlet?.isRestaurant ?? false,
        // isBar: outlet?.isBar ?? false,
        isBrunch: outlet?.isBrunch ?? false,
        isActive: true,
        // isFeatured: true,
        is_desplay: outlet?.is_desplay ?? false,
        terms: outlet?.terms ?? "",
        isExplore: outlet?.isExplore ?? false,
        dressCode: outlet?.dressCode ?? "",
        price: outlet?.price ?? "100",
        facilities: !Array.isArray(outlet?.facilities)
          ? outlet?.facilities?.split(",") ?? []
          : outlet?.facilities,
        cuisines: !Array.isArray(outlet?.cuisines)
          ? outlet?.cuisines?.split(",") ?? []
          : outlet?.cuisines,
        bookingLink: outlet?.bookingLink ?? "",
        venueRating: outlet?.venueRating ?? 0,
        coords: outlet?.coords ?? [],
        associatedEvents: outlet?.associatedEvents ?? [],
        associatedBrunches: outlet?.associatedBrunches ?? [],
        cuisineClass: outlet?.cuisineClass ?? "",
        timings: outlet?.timings ?? [
          { day: "Monday", startTime: "11:00", endTime: "23:30" },
          { day: "Tuesday", startTime: "11:00", endTime: "23:30" },
          { day: "Wednesday", startTime: "11:00", endTime: "23:30" },
          { day: "Thursday", startTime: "11:00", endTime: "23:30" },
          { day: "Friday", startTime: "11:00", endTime: "23:30" },
          { day: "Saturday", startTime: "11:00", endTime: "23:30" },
          { day: "Sunday", startTime: "11:00", endTime: "23:30" },
        ],
        redeemCode: outlet?.redeemCode,
        associatedVenue: outlet?.associatedVenue,
        brunchPrice: outlet?.brunchPrice ?? "",
        status: outlet?.status ?? true,
        isAdminApprove: outlet?.isAdminApprove ?? false,
        whatsappBusinessLink: outlet?.whatsappBusinessLink ?? "",
        restaurantsOrBars: outlet?.restaurantsOrBars ?? [],
      });
      if (outlet?.restaurantsOrBars && outlet?.restaurantsOrBars?.length > 0) {
        setSelectedRestaurantOrBar(outlet?.restaurantsOrBars?.[0]);
      }
      if (outlet?.associatedVenue) {
        let selectedAssociatedVenue = venuesList.find(
          (item) => item?.id === state?.associatedVenue
        );
        setSelectedVenue(selectedAssociatedVenue);
        setRestaurantOrBarList(
          selectedAssociatedVenue?.restaurantsOrBars ?? []
        );
      }
      if (outlet?.coords?.length)
        setCenter({ lat: +outlet?.coords[0], lng: +outlet?.coords[1] });
      setEvents(outlet?.events);
    }
    // eslint-disable-next-line
  }, [outlet, window.location.pathname]);

  useEffect(() => {
    setState({
      ...state,
      addressLine1: selectedVenue?.addressLine1,
      addressLine2: selectedVenue?.addressLine2,
      country: selectedVenue?.country ?? "Dubai",
      email: selectedVenue?.email,
      mobile: selectedVenue?.mobile,
      associatedVenue: selectedVenue?.id,
    });
    // eslint-disable-next-line
  }, [selectedVenue]);

  const getInitials = (name) => {
    let initials = "";
    const nameArr = name.split(" ");
    if (nameArr?.length > 0) {
      initials += nameArr[0]?.charAt(0);
    }
    if (nameArr?.length > 1) {
      initials += nameArr[1]?.charAt(0);
    }

    return initials;
  };

  const openLogoChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("logo-file-chooser").click();
    }
  };

  const openBannerChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("banner-file-chooser").click();
    }
  };

  const openEventBannerChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("event-banner-file-chooser").click();
    }
  };

  const openOtherImagesChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("other-images-file-chooser").click();
    }
  };

  const handleLogoUpload = async (evt) => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file);
      const resp = await uploadFile(compressImg, false);
      if (resp?.filePath) {
        setState({ logoImage: resp?.filePath });
        Swal.fire("Success", "Logo image uploaded", "success");
      }
    }
  };

  const handleBannerUpload = async (evt) => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file);
      const resp = await uploadFile(compressImg, false);
      if (resp?.filePath) {
        setState({ bannerImage: resp?.filePath });
        Swal.fire("Success", "Banner image uploaded", "success");
      }
    }
  };

  const handleOtherImagesUpload = async (evt) => {
    if (evt.target?.files?.length) {
      dispatch(setOutletLoading(true));
      const { otherImages } = state || [];
      for (let file of evt?.target?.files) {
        if (file) {
          const compressImg = await handleCompressedUpload(file);
          const resp = await uploadFile(compressImg, false);
          if (resp?.filePath) otherImages.push(resp?.filePath);
        }
      }
      setState({ otherImages });
      Swal.fire("Success", "Gallery image uploaded.", "success");
      dispatch(setOutletLoading(false));
    }
  };

  const handleEventBannerUpload = async (evt) => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file);
      const resp = await uploadFile(compressImg, false);
      if (resp?.filePath) {
        setEventState({ bannerImage: resp?.filePath });
        /*Swal.fire(
          'Success',
          'Banner image uploaded',
          'success'
        );*/
      }
    }
  };

  const removeOtherImage = (index) => {
    const { otherImages } = state;

    if (otherImages?.length && index < otherImages?.length) {
      otherImages.splice(index, 1);

      setState({ otherImages });
    }
  };

  const resetAddBrunch = () => {
    setEventState({
      name: "",
      startDate: "",
      expiryDate: "",
      bannerImage: "",
      weekDays: [],
      eventDescription: "",
      untilTime: "",
      fromTime: "",
    });
    setExpiryType(null);
  };

  const addBrunch = () => {
    const {
      name,
      expiryDate,
      startDate,
      bannerImage,
      weekDays,
      eventDescription,
      fromTime,
      untilTime,
    } = eventState;

    if (!name) {
      alert("Event name is required");
      return;
    } else if (expiryType === "selectByDate" && startDate === "") {
      alert("Please select start date");
      return;
    } else if (expiryType === "selectByDate" && expiryDate === "") {
      alert("Please select end date");
      return;
    } else if (expiryType === "selectByDate" && startDate > expiryDate) {
      alert("Start date must be less then expiry date");
      return;
    } else if (expiryType === "selectByWeek" && weekDays.length === 0) {
      alert("Please select days of week");
      return;
    } else if (
      (fromTime && untilTime === "") ||
      (fromTime === "" && untilTime)
    ) {
      alert("Please fill both from time and until time fields.");
      return;
    }
    //  else if ((fromTime && untilTime) && !(fromTime < untilTime)) {
    //   alert("From time must be less then until time.");
    //   return;
    // }

    const payload = {
      name,
      startDate,
      expiryDate,
      bannerImage,
      expiredAt: "",
      eventDescription: eventDescription ?? "",
      untilTime,
      fromTime,
    };

    if (expiryDate) {
      payload.expiryDate = expiryDate;
      payload.weekDays = [];
      payload.expiredAt = moment(expiryDate).unix();
    } else if (weekDays.length) {
      payload.expiryDate = "";
      payload.startDate = "";
      payload.weekDays = weekDays;
    }
    payload.weekDay = "";

    if (isEditBrunch && editBrunchIndex > -1) {
      const brunch = { ...payload };
      const { associatedBrunches } = state;
      associatedBrunches[editBrunchIndex] = brunch;
      setState({ associatedBrunches });
    } else {
      const associatedBrunches = state.associatedBrunches || [];
      associatedBrunches.push(payload);
      setState({ associatedBrunches });
    }
    setShowEventModal(false);
    setEditBrunch(false);
    resetAddBrunch();
  };

  const handleDelete = (index) => {
    const { keywords } = state;
    keywords.splice(index, 1);
    setState({ keywords });
  };

  const handleAddUpdate = () => {
    if (!state.outletName.trim()) {
      Swal.fire("Required", "Listing name field is required", "error");
      return;
    }
    if (state.venueRating) state.venueRating = Number(state.venueRating);

    if (outlet?.id) {
      delete state.featuredAt;
      delete state.featuredEndAt;
      delete state.redemptions;
      delete state.views;
      dispatch(updateOutlet(outlet?.id, state, searchQuery))
        .then(() => {
          Swal.fire(
            "Success",
            `Listing successfully ${outlet ? "updated" : "added"}`,
            "success"
          );
          searchQuery
            ? navigate(`/outlets?search=${searchQuery}`)
            : navigate("/outlets");
        })
        .catch((err) => {
          Swal.fire(
            "Error",
            err?.response?.data?.message ?? "Something went wrong",
            "error"
          );
        });
    } else {
      dispatch(addOutlet(state, searchQuery))
        .then(() => {
          Swal.fire(
            "Success",
            `Listing successfully ${outlet ? "updated" : "added"}`,
            "success"
          );
          searchQuery
            ? navigate(`/outlets?search=${searchQuery}`)
            : navigate("/outlets");
        })
        .catch((err) => {
          Swal.fire(
            "Error",
            err?.response?.data?.message ?? "Something went wrong",
            "error"
          );
        });
    }
  };

  const handleDeleteOutlet = () => {
    Swal.fire({
      title: "Are you sure you want to delete this Listing ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Keep",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOutlet(outlet?.id, searchQuery))
          .then(() => {
            Swal.fire({
              title: "Listing successfully deleted.",
              timer: 2500,
              icon: "success",
            });

            searchQuery
              ? navigate(`/outlets?search=${searchQuery}`)
              : navigate("/outlets");
          })
          .catch(() => {
            Swal.fire({
              title: "There was error deleting Listing. Try again.",
              timer: 3000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleDuplicate = () => {
    let obj = state;
    obj.associatedEvents = [];
    obj.associatedBrunches = [];
    dispatch(setOutletItem(obj));
    navigate("/outlets/add");
    Swal.fire(
      "Duplicated",
      "Data duplicated. Please make changes to save as a new item.",
      "success"
    );
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    let otherImages = state?.otherImages ?? [];

    const [reorderedItem] = otherImages.splice(result.source.index, 1);
    otherImages.splice(result.destination.index, 0, reorderedItem);

    setState((prevState) => {
      return {
        ...prevState,
        otherImages,
      };
    });
  }

  function handleOnDragEndDeals(result) {
    if (!result.destination) return;

    let associatedEvents = state?.associatedEvents ?? [];
    let [reorderedItem] = associatedEvents.splice(result.source.index, 1);
    associatedEvents.splice(result.destination.index, 0, reorderedItem);
    setState({ associatedEvents });

    associatedEvents = events ?? [];
    [reorderedItem] = associatedEvents.splice(result.source.index, 1);
    associatedEvents.splice(result.destination.index, 0, reorderedItem);
    setEvents(associatedEvents);
  }

  const handleMapClick = (event) => {
    setState({ coords: [event.latLng.lat(), event.latLng.lng()] });
    setCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    setOpenDialog(false);
    if (document.fullscreenElement) document.exitFullscreen();
  };

  return (
    <div>
      <Navbar />
      {/* <Breadcrumb
        breadFirst="Home"
        breadSec="Listings"
        breadLast="Edit Listings"
        linkFirst="/"
        linkSec="/outlets"
      /> */}
      {isLoading && <Loader />}
      <div className="bradcrumbs" role="presentation">
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} className="flex items-center">
            <div className="mr4 c-pointer left-arrow ">
              <Link
                underline="hover"
                color="inherit"
                href={
                  searchQuery ? `/outlets?search=${searchQuery}` : "/outlets"
                }
              >
                <ArrowBackIosNew />
              </Link>{" "}
            </div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {user && user.role === "admin" ? (
                <Link underline="hover" href="/">
                  Home
                </Link>
              ) : (
                ""
              )}
              <Link
                underline="hover"
                color="inherit"
                href={
                  searchQuery ? `/outlets?search=${searchQuery}` : "/outlets"
                }
              >
                Listings
              </Link>
              <Typography>Edit Listings</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
            <MoreHoriz />
          </Grid>
        </Grid>
      </div>
      <div className="container-main">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div className="venue-name flex items-center">
              <span className="cir-icon mr2">
                <span className="dot-text">
                  {getInitials(state.outletName)}
                </span>
              </span>
              <h1>{state.outletName}</h1>
              {/* {outlet && (
                <div className="icon-yellow ml4">
                  <DriveFileRenameOutline />
                </div>
              )} */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <div className="flex flex-wrap items-center mt2 fr-ns fl-m">
              {/* <div className="flex items-center flex-wrap fr-ns fl-m">
                <div className="mr4">
                  <FormControlLabel
                    className="checkbox"
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                        }
                        checkedIcon={
                          <img src="/images/chck.svg" alt="check" />
                        }
                        onChange={() => {
                          let logoImage = state.logoImage;
                          setState({ logoImage: state?.bannerImage, bannerImage: logoImage });
                        }
                        }
                      />
                    }
                    label="Switch Main Images"
                  />
                </div>
              </div> */}
              {/* <div className="mr4">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.is_desplay}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.is_desplay ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setState({ is_desplay: !state.is_desplay })
                      }
                    />
                  }
                  label="Show at Home"
                />
              </div> */}
              {user && user.role === "admin" && (
                <div className="mr4">
                  <FormControlLabel
                    className="checkbox"
                    control={
                      <Checkbox
                        checked={state?.isAdminApprove}
                        icon={
                          <CheckBoxOutlineBlank
                            style={{ fill: "transparent" }}
                          />
                        }
                        checkedIcon={
                          state?.isAdminApprove ? (
                            <img src="/images/chck.svg" alt="check" />
                          ) : (
                            <></>
                          )
                        }
                        onChange={() =>
                          setState({ isAdminApprove: !state?.isAdminApprove })
                        }
                      />
                    }
                    label="Approve Listing"
                  />
                </div>
              )}

              {outlet?.id && (
                <button
                  type="button"
                  className="button-sm box-shadow bg-secondry-yellow mr3 flex items-center"
                  onClick={handleDuplicate}
                >
                  <div className="plus-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <g
                        id="Vector_Smart_Object"
                        data-name="Vector Smart Object"
                        transform="translate(-1046 -239)"
                      >
                        <g
                          id="Vector_Smart_Object-2"
                          data-name="Vector Smart Object"
                          transform="translate(1042.667 235.667)"
                        >
                          <path
                            id="Path_22"
                            data-name="Path 22"
                            d="M8.889,17.778H6.667a2.222,2.222,0,0,1-2.222-2.222V6.667A2.222,2.222,0,0,1,6.667,4.444h8.889a2.222,2.222,0,0,1,2.222,2.222V8.889M11.111,22.222H20A2.222,2.222,0,0,0,22.222,20V11.111A2.222,2.222,0,0,0,20,8.889H11.111a2.222,2.222,0,0,0-2.222,2.222V20A2.222,2.222,0,0,0,11.111,22.222Z"
                            fill="none"
                            stroke="#4a5568"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.222"
                          />
                        </g>
                        <path
                          id="Color_Overlay"
                          data-name="Color Overlay"
                          d="M1053.778,257.889a2.222,2.222,0,0,1-2.222-2.222v-2.222l8.889-8.889h2.222a2.222,2.222,0,0,1,2.222,2.223v8.888a2.222,2.222,0,0,1-2.222,2.222Zm-4.444-4.444a2.222,2.222,0,0,1-2.222-2.222v-8.889a2.222,2.222,0,0,1,2.222-2.222h8.888a2.222,2.222,0,0,1,2.223,2.222v2.222h-6.667a2.222,2.222,0,0,0-2.222,2.223v6.666Z"
                        />
                      </g>
                    </svg>
                  </div>
                  DUPLICATE
                </button>
              )}
              {/* {outlet && (
                <button
                  type="button"
                  className="button-sm box-shadow flex items-center"
                  onClick={() => navigate("/outlets/add")}
                >
                  <div
                    className="plus-ico"
                    onClick={() => {
                      navigate("/outlets/add");
                    }}
                  >
                    <Add />
                  </div>
                  CREATE LISTING
                </button>
              )} */}
              {((user && user.role === "vendor" && state?.isAdminApprove) ||
                (user && user.role === "admin")) && (
                <div className="ma2 btn-dropdown">
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <Select
                      label="Status"
                      value={state.status}
                      onChange={(evt) =>
                        setState({ status: evt?.target?.value })
                      }
                    >
                      <MenuItem value={true}>Published</MenuItem>
                      <MenuItem value={false}>Un-Published</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container sm={12} md={12} lg={9} className="m-auto">
          <div className="mb-2 mt3">
            <Grid container lg={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <Autocomplete
                    id={"listing-venues-list"}
                    disabled={
                      outlet ? (outlet?.isVenueAccess ? false : true) : false
                    }
                    value={
                      selectedVenue
                        ? venuesList.find(
                            (item) => item?.id === state?.associatedVenue
                          )
                        : null
                    }
                    options={venuesList}
                    getOptionLabel={(option) => option.venueName}
                    filterSelectedOptions={true}
                    renderOption={(props, option, { selected }) => (
                      <li key={option.id} {...props}>
                        {option?.venueName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Choose venue by name"
                      />
                    )}
                    onChange={(evt, venue) => {
                      setSelectedVenue(venue);
                      setSelectedRestaurantOrBar(null);
                      setState({
                        restaurantsOrBars: [],
                      });
                      setRestaurantOrBarList(venue?.restaurantsOrBars ?? []);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <Autocomplete
                    id={"listing-restaurant-list"}
                    disabled={
                      outlet ? (outlet?.isVenueAccess ? false : true) : false
                    }
                    value={
                      selectedRestaurantOrBar ? selectedRestaurantOrBar : null
                    }
                    options={restaurantOrBarList}
                    getOptionLabel={(name) => name}
                    filterSelectedOptions={true}
                    renderOption={(props, option, { selected }) => (
                      <li key={Math.random()} {...props}>
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Choose outlet by name"
                      />
                    )}
                    onChange={(evt, restaurantOrBar) => {
                      setSelectedRestaurantOrBar(restaurantOrBar);
                      setState({
                        restaurantsOrBars: [restaurantOrBar],
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.outletName}
                    onChange={(evt) =>
                      setState({ outletName: evt?.target.value })
                    }
                    placeholder="Listing Name"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.addressLine1}
                    onChange={(evt) =>
                      setState({ addressLine1: evt?.target.value })
                    }
                    placeholder="Address Line 1"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="email"
                    value={state.email}
                    onChange={(evt) => setState({ email: evt?.target.value })}
                    placeholder="john.doe@example.com"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.addressLine2}
                    onChange={(evt) =>
                      setState({ addressLine2: evt?.target.value })
                    }
                    placeholder="Address Line 2"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.mobile}
                    onChange={(evt) => setState({ mobile: evt?.target.value })}
                    placeholder="Phone Number"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.country}
                    onChange={(evt) => setState({ country: evt?.target.value })}
                    placeholder="Country"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.redeemCode}
                    onChange={(evt) => {
                      if (evt?.target.value.length <= 4)
                        setState({ redeemCode: evt?.target.value });
                    }}
                    placeholder="Pin Number"
                    className="w-100 input-square"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative"></div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input
                    type="file"
                    name="logoimg"
                    id="logo-file-chooser"
                    style={{ display: "none" }}
                    onChange={handleLogoUpload}
                  />
                  <div
                    className="camera-ico bg-white-circle box-shadow"
                    onClick={openLogoChooser}
                  >
                    <CameraAlt />
                  </div>
                  {state?.logoImage ? (
                    <img
                      src={state.logoImage}
                      alt="Add Logo"
                      className="logo-banner-image"
                    />
                  ) : (
                    <div
                      className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openLogoChooser}
                    >
                      Click to add Logo
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input
                    type="file"
                    name="bannerimg"
                    id="banner-file-chooser"
                    style={{ display: "none" }}
                    onChange={handleBannerUpload}
                  />
                  <div
                    className="camera-ico bg-white-circle box-shadow"
                    onClick={openBannerChooser}
                  >
                    <CameraAlt />
                  </div>
                  {state?.bannerImage ? (
                    <img
                      src={state.bannerImage}
                      alt="Add Banner"
                      className="logo-banner-image"
                    />
                  ) : (
                    <div
                      className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openBannerChooser}
                    >
                      Click to add banner image
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="slider w-100 mt4 mb4 flex flex-wrap justify-between items-center edit-image">
            {/* {state?.otherImages?.map((otherImg, index) => (
              <div className="image-container relative" key={index} >
                <div
                  className="clear-ico"
                  onClick={() => removeOtherImage(index)}
                >
                  <Cancel />
                </div>
                <img src={otherImg} alt="Outlet Images" />
              </div>
            ))} */}

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div
                    className="slider w-100 mt4 mb4 flex flex-wrap items-center edit-image"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state?.otherImages.map((item, index) => {
                      return (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided) => (
                            <div
                              className="image-container relative"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className="clear-ico"
                                onClick={() => removeOtherImage(index)}
                              >
                                <Cancel />
                              </div>
                              <img src={item} alt="Outlet Images" />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    <div
                      className="image-container white-txt flex justify-center items-center"
                      onClick={openOtherImagesChooser}
                    >
                      <input
                        type="file"
                        name="otherimg"
                        id="other-images-file-chooser"
                        style={{ display: "none" }}
                        multiple={true}
                        onChange={handleOtherImagesUpload}
                      />
                      <Add />
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="description pa2 tc border w-100">
            <h3 className="font-18-regular pb2">Description of the venue.</h3>
            <textarea
              rows="5"
              value={state.description}
              onChange={(evt) => setState({ description: evt?.target.value })}
              placeholder="Description"
            ></textarea>
          </div>
          {/* Terms */}
          {/* <div className="description pa2 mt4 mb4 tc border w-100">
            <h3 className="font-18-regular pb2">
              Terms &amp; conditions here.
            </h3>
            <textarea
              rows="5"
              value={state.terms}
              onChange={(evt) =>
                setState({ terms: evt?.target.value })
              }
              placeholder="Description"
            ></textarea>
          </div> */}

          <div className="checkboxes mt4 w-100 flex flex-wrap justify-around">
            <div className="items-center pa2">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    checked={state.isRestaurant}
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={
                      state.isRestaurant ? (
                        <img src="/images/chck.svg" alt="check" />
                      ) : (
                        <></>
                      )
                    }
                    onChange={() =>
                      setState({ isRestaurant: !state.isRestaurant })
                    }
                  />
                }
                label="List as Restaurant (Let's Eat & Drink)"
              />
            </div>

            {/* <div className="items-center pa2">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    checked={state.isBar}
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={
                      state.isBar ? <img src="/images/chck.svg" alt="check" /> : <></>
                    }
                    onChange={() => setState({ isBar: !state.isBar })}
                  />
                }
                label="List as Bar (Let's Drink)"
              />
            </div> */}

            <div className="items-center pa2">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    checked={state.isBrunch}
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={
                      state.isBrunch ? (
                        <img src="/images/chck.svg" alt="check" />
                      ) : (
                        <></>
                      )
                    }
                    onChange={() => setState({ isBrunch: !state.isBrunch })}
                  />
                }
                label="List as Brunch (Let's Brunch)"
              />
            </div>

            <div className="items-center pa2">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    checked={state.isExplore}
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={
                      state.isExplore ? (
                        <img src="/images/chck.svg" alt="check" />
                      ) : (
                        <></>
                      )
                    }
                    onChange={() => setState({ isExplore: !state.isExplore })}
                  />
                }
                label="List as Explore (Let's Explore)"
              />
            </div>
          </div>
          <div className="page-bottom w-100">
            <div className="flex w-100 mb3 justify-between items-center">
              <h2 className="h1 weight-semi-bold edit-heading">
                General Listing Opening Times
              </h2>
            </div>
            <Grid
              item
              container
              sm={12}
              md={12}
              lg={12}
              className="w-100 table-ltx"
            >
              <Grid item sm={12} md={12} lg={6}>
                <table className="day-time-edit-wrapper tabel-container-list">
                  {state.timings?.map((dayTime, index) => (
                    <tr key={index}>
                      <td className="w-20">{dayTime?.day}</td>
                      <td className="w-80">
                        <div className="flex items-center">
                          <div className="grey-box">
                            <input
                              type="text"
                              value={dayTime.startTime}
                              onChange={(evt) => {
                                const updatedTimings = [...state.timings];
                                updatedTimings[index].startTime =
                                  evt.target.value;
                                setState({ timings: updatedTimings });
                              }}
                            />
                          </div>
                          <div className="mr2 ml2 text-grey">
                            <HorizontalRule />
                          </div>
                          <div className="grey-box">
                            <input
                              type="text"
                              value={dayTime.endTime}
                              onChange={(evt) => {
                                const updatedTimings = [...state.timings];
                                updatedTimings[index].endTime =
                                  evt.target.value;
                                setState({ timings: updatedTimings });
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </Grid>
              <Grid item sm={12} md={12} lg={6}>
                <table className="outlet-other-details tabel-container-list">
                  <tr>
                    <td>Booking Link</td>
                    <td>
                      <input
                        type="text"
                        value={state.bookingLink}
                        placeholder="https://letsdxb.com"
                        onChange={(evt) =>
                          setState({ bookingLink: evt.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Whatsapp Business Link</td>
                    <td>
                      <input
                        type="text"
                        value={state.whatsappBusinessLink}
                        placeholder="https://wa.me/1234567890"
                        onChange={(evt) =>
                          setState({ whatsappBusinessLink: evt.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>GPS Location</td>
                    <td className="flex">
                      <button
                        className="open-map-button"
                        onClick={() => {
                          setOpenDialog(true);
                          setValue("");
                        }}
                      >
                        Open Map
                      </button>
                      <input
                        type="text"
                        value={state.coords?.join(",")}
                        placeholder=""
                        onChange={(evt) =>
                          setState({ coords: evt.target.value?.split(",") })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Dresscode</td>
                    <td>
                      <select
                        value={state.dressCode}
                        placeholder="Dress Codes"
                        onChange={(evt) =>
                          setState({ dressCode: evt.target.value })
                        }
                      >
                        {DRESS_CODES?.map((item) => (
                          <option
                            value={item === "Glams" ? "Formals" : item}
                            key={item}
                          >
                            {item.slice(0, -1)}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Cuisine Class</td>
                    <td>
                      <select
                        value={state?.cuisineClass}
                        onChange={(evt) =>
                          setState({ cuisineClass: evt?.target?.value })
                        }
                      >
                        <option>Select Cuisine Class</option>
                        <option value={"Casual"}>Casual</option>
                        <option value={"Fine Dining"}>Fine Dining</option>
                        <option value={"All"}>All</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>
                      <select
                        value={state.price}
                        placeholder="Price"
                        onChange={(evt) =>
                          setState({ price: evt.target.value })
                        }
                      >
                        {PRICE?.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Facilities</td>
                    <td className="flex">
                      {FACILITIES?.map((item, index) => (
                        <div className="flex items-center justify-start mr3 pv3" key={index}>
                          <div>
                            <input
                              type="checkbox"
                              value={item}
                              key={item}
                              style={{ width: 30, height: 16 }}
                              checked={
                                state.facilities?.findIndex(
                                  (fac) => fac === item
                                ) > -1
                              }
                              onChange={() => {
                                const { facilities } = state;
                                const exIndex = state.facilities?.findIndex(
                                  (fac) => fac === item
                                );
                                if (exIndex > -1) facilities.splice(exIndex, 1);
                                else facilities.push(item);

                                setState({ facilities });
                              }}
                            />
                          </div>
                          <div>{item}</div>
                        </div>
                      ))}
                    </td>
                  </tr> */}
                  <tr>
                    <td>Brunch Price</td>
                    <td>
                      <input
                        type="text"
                        value={state.brunchPrice}
                        placeholder=""
                        onChange={(evt) =>
                          setState({ ...state, brunchPrice: evt.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Brunch Feature</td>
                    <td>
                      <select
                        value={state?.facilities}
                        placeholder="Brunch Feature"
                        onChange={(evt) => {
                          const { facilities } = state;
                          const val = evt?.target?.value;
                          const exIndex = state.facilities?.findIndex(
                            (facilities) => facilities === val
                          );
                          if (exIndex > -1) facilities.splice(exIndex, 1);
                          else facilities.push(val);

                          setState({ facilities });
                        }}
                        multiple
                      >
                        {FACILITIES?.map((item) => {
                          return (
                            <option
                              value={item}
                              key={item}
                              selected={
                                state.facilities?.findIndex(
                                  (facilities) => facilities === item
                                ) > -1
                              }
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Cuisine</td>
                    <td>
                      <select
                        value={state.cuisines}
                        placeholder="Cuisines"
                        onChange={(evt) => {
                          const { cuisines } = state;
                          const val = evt?.target?.value;
                          const exIndex = state.cuisines?.findIndex(
                            (cuisine) => cuisine === val
                          );
                          if (exIndex > -1) cuisines.splice(exIndex, 1);
                          else cuisines.push(val);

                          setState({ cuisines });
                        }}
                        // value={state.cuisines}
                        multiple
                      >
                        {CUISINES?.map((item) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              state.cuisines?.findIndex(
                                (cuisine) => cuisine === item
                              ) > -1
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  {/* <tr>
                    <td>Venue (Hotel/Area) Rating</td>
                    <td>
                      <select
                        value={state.venueRating}
                        onChange={(evt) =>
                          setState({ venueRating: evt?.target?.value })
                        }
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </td>
                  </tr> */}
                </table>
              </Grid>
            </Grid>

            {outlet && (
              <Grid container className="mt4">
                <h2 className="h1 weight-semi-bold heading">Deals</h2>
                <div className="ml4">
                  {events?.length ? (
                    <DragDropContext onDragEnd={handleOnDragEndDeals}>
                      <Droppable droppableId="characters">
                        {(provided) => (
                          <ul
                            className="featured-items-list-ul"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {events.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="feat-user flex items-center mb3">
                                        <Avatar
                                          src={
                                            item?.bannerImage ??
                                            "/broken-image.jpg"
                                          }
                                        />
                                        {item?.offer && (
                                          <div className="ml3">
                                            {item?.offer?.offerName}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <Typography variant="h6">
                      No associated deals found
                    </Typography>
                  )}
                </div>
              </Grid>
            )}

            <div className="page-bottom w-100">
              <div className="flex w-100 justify-between items-center">
                <h2 className="h1 weight-semi-bold heading edit-heading">
                  Keywords
                </h2>
                <button
                  type="button"
                  className="button-sm bg-purple flex items-center"
                  onClick={() => setShowTag(!showAddTag)}
                >
                  <div className="plus-ico">{showAddTag ? "x" : <Add />}</div>
                  {showAddTag ? "CLOSE" : "ADD NEW TAG"}
                </button>
              </div>
              {showAddTag && (
                <div className="add-stuff-wrapper flex items-center justify-center">
                  <input
                    type="text"
                    onChange={(evt) => setTag(evt.target.value)}
                    value={tag}
                  />
                  <button
                    onClick={() => {
                      if (tag) {
                        setState({ keywords: [...state.keywords, tag] });
                        setShowTag(false);
                        setTag("");
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              )}
              {state.keywords?.length > 0 && (
                <div className="description pa4 tc border mt4">
                  <Stack direction="row" className="flex flex-wrap" spacing={1}>
                    {state.keywords?.map((tag, index) => (
                      <Chip
                        label={tag}
                        variant="outlined"
                        onDelete={() => handleDelete(index)}
                        key={index}
                      />
                    ))}
                  </Stack>
                </div>
              )}
            </div>

            <Grid container className="mt4">
              <div className="flex w-100 mb3 justify-between items-center">
                <h2 className="h1 weight-semi-bold edit-heading">Events</h2>
                <button
                  type="button"
                  className="button-sm bg-secondry-yellow  flex items-center"
                  onClick={() => {
                    setShowEventModal(true);
                    setEditBrunchIndex(-1);
                    setEditBrunch(false);
                    resetAddBrunch();
                  }}
                >
                  <div className="plus-ico">
                    <Add />
                  </div>
                  ADD EVENT
                </button>
              </div>

              <Modal
                open={showEventModal}
                onClose={() => {
                  setShowEventModal(false);
                  setEditBrunch(false);
                }}
              >
                <div
                  className="add-event-modal-container flex flex-column"
                  style={{ height: "100vh", overflow: "scroll" }}
                >
                  <div className="modal-header">Event Details</div>
                  <Grid item container lg={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="flex relative">
                        <input
                          type="text"
                          value={eventState.name}
                          onChange={(evt) =>
                            setEventState({ name: evt?.target.value })
                          }
                          placeholder="Event Name"
                          className="w-100 input-square"
                        />
                      </div>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="flex relative">
                        <input
                          type="date"
                          value={eventState.expiryDate}
                          onChange={(evt) =>
                            setEventState({ expiryDate: evt?.target.value })
                          }
                          placeholder="Event Name"
                          className="w-100 input-square"
                        />
                      </div>
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                      <div className="venue-img">
                        <input
                          type="file"
                          name="eventbanner"
                          id="event-banner-file-chooser"
                          style={{ display: "none" }}
                          onChange={handleEventBannerUpload}
                        />
                        <div
                          className="camera-ico bg-white-circle box-shadow"
                          onClick={openEventBannerChooser}
                        >
                          <CameraAlt />
                        </div>
                        {eventState?.bannerImage ? (
                          <img
                            src={eventState.bannerImage}
                            alt="Add Banner for Event"
                            className="logo-banner-image"
                          />
                        ) : (
                          <div
                            className="empty-logo-box w-100 flex items-center justify-center"
                            onClick={openEventBannerChooser}
                          >
                            Click to add Banner
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="description pa2 tc border w-100">
                        <h3 className="font-18-regular pb2">
                          Event description
                        </h3>
                        <textarea
                          rows="8"
                          value={eventState.eventDescription}
                          onChange={(evt) =>
                            setEventState({
                              eventDescription: evt?.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={
                        expiryType ? "flex mt-4 justify-between" : "flex mt-4"
                      }
                    >
                      <FormControl sx={{ m: 1, width: 400, marginTop: "20px" }}>
                        <InputLabel>Date type</InputLabel>
                        <Select
                          value={expiryType}
                          onChange={(evt) => {
                            setExpiryType(evt?.target?.value);
                          }}
                          input={<OutlinedInput label="Date type" />}
                        >
                          <MenuItem disabled>Select option</MenuItem>
                          <MenuItem value="selectByDate">
                            Select a date
                          </MenuItem>
                          <MenuItem value="selectByWeek">
                            Select a day of week
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {expiryType === "selectByDate" && (
                        <>
                          <Grid item xs={12} sm={6} md={6} lg={8}>
                            <InputLabel>Start Date</InputLabel>
                            <div className="flex w-100 relative">
                              <input
                                type="date"
                                value={eventState.startDate}
                                onChange={(evt) => {
                                  setEventState({ weekDays: [] });
                                  setEventState({
                                    startDate: evt?.target.value,
                                  });
                                }}
                                className="w-100 input-square"
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={8}>
                            <InputLabel>End Date</InputLabel>
                            <div className="flex w-100 relative ml-2">
                              <input
                                type="date"
                                value={eventState.expiryDate}
                                placeholder="Expiry Date"
                                onChange={(evt) => {
                                  setEventState({ weekDays: [] });
                                  setEventState({
                                    expiryDate: evt?.target.value,
                                  });
                                }}
                                className="w-100 input-square"
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                          </Grid>
                        </>
                      )}
                      {expiryType === "selectByWeek" && (
                        <FormControl
                          sx={{ m: 1, width: 430, marginTop: "20px" }}
                        >
                          <InputLabel id="deal-date-label">
                            Select day
                          </InputLabel>
                          <Select
                            multiple={true}
                            value={eventState.weekDays}
                            onChange={(evt) => {
                              setEventState({ expiryDate: "", startDate: "" });
                              setEventState({ weekDays: evt?.target?.value });
                            }}
                            input={<OutlinedInput label="Select day" />}
                          >
                            {WEEKDAYS.map((day) => (
                              <MenuItem value={day}>{day}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="flex justify-between"
                    >
                      <Grid sx={{ m: 1, width: 400 }}>
                        <InputLabel shrink="true">From Time</InputLabel>
                        <input
                          type="time"
                          value={eventState.fromTime}
                          onChange={(evt) => {
                            setEventState({ fromTime: evt?.target.value });
                          }}
                          className="w-100 input-square"
                        />
                      </Grid>
                      <Grid sx={{ m: 1, width: 430 }}>
                        <InputLabel shrink="true">Until Time</InputLabel>
                        <input
                          type="time"
                          value={eventState.untilTime}
                          onChange={(evt) => {
                            setEventState({ untilTime: evt?.target.value });
                          }}
                          className="w-100 input-square"
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} lg={12} className="mt-4">
                      <div className="modal-actions-wrapper">
                        <button className="apply" onClick={addBrunch}>
                          Add Event
                        </button>
                        <button
                          className="cancel"
                          onClick={() => {
                            //resetAddBrunch();
                            setShowEventModal(false);
                            setEditBrunch(false);
                            setExpiryType("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Modal>
            </Grid>

            {state?.associatedBrunches?.map((item, index) => (
              <Grid
                item
                container
                sm={12}
                md={12}
                lg={12}
                className="flex pa3 box-shadow items-center mt4"
                key={index}
              >
                <Grid item sm={12} md={12} lg={4}>
                  <div className="event-name font-18-regular">{item?.name}</div>
                  <div
                    className="event-tag font-14-light mt2"
                    style={{ wordWrap: "break-word" }}
                  >
                    {/* Expiry date:{" "}
                    {item?.expiryDate
                      ? moment(item?.expiryDate)?.format("DD/MM/YYYY")
                      : ""} */}
                    {item?.expiryDate ? (
                      <>
                        <div>{`Start date: ${moment(item?.startDate)?.format(
                          "DD/MM/YYYY"
                        )}`}</div>
                        <div>{`Expiry date: ${moment(item?.expiryDate)?.format(
                          "DD/MM/YYYY"
                        )}`}</div>
                      </>
                    ) : item?.weekDays?.length ? (
                      `Week days: ${item?.weekDays?.join(", ")}`
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="event-tag font-14-light mt2">
                    {item?.fromTime ? `From Time: ${item?.fromTime}` : ""}
                  </div>
                  <div className="event-tag font-14-light mt2">
                    {item?.untilTime ? `Until Time: ${item?.untilTime}` : ""}
                  </div>
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <img
                    src={item?.bannerImage ?? "/images/event-img.png"}
                    alt="event"
                    className="logo-banner-image"
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <div className="flex justify-around items-center">
                    <button
                      type="button"
                      className="normal-btn-space button-sm pa3 bg-light-green"
                      onClick={() => {
                        if (item?.expiryDate) setExpiryType("selectByDate");
                        else if (item?.weekDays?.length)
                          setExpiryType("selectByWeek");

                        setEventState({ ...item });
                        setShowEventModal(true);
                        setEditBrunch(true);
                        setEditBrunchIndex(index);
                        openEventBannerChooser();
                      }}
                    >
                      UPDATE BANNER
                    </button>
                    <div
                      className="icon-yellow "
                      onClick={() => {
                        if (item?.expiryDate) setExpiryType("selectByDate");
                        else if (item?.weekDays?.length)
                          setExpiryType("selectByWeek");

                        setEventState({ ...item });
                        setShowEventModal(true);
                        setEditBrunch(true);
                        setEditBrunchIndex(index);
                      }}
                    >
                      <DriveFileRenameOutline />
                    </div>
                    <div
                      className="icon-yellow grey-3"
                      onClick={() => {
                        const { associatedBrunches } = state;
                        // console.log('associatedBrunches: ', associatedBrunches);
                        // const exIndex = associatedBrunches?.findIndex(
                        //   (ev) => {
                        //     return ev.id === item?.id
                        //   }
                        // );
                        // console.log('exIndex: ', exIndex);
                        // if (exIndex > -1) {
                        associatedBrunches?.splice(index, 1);
                        setState({ associatedBrunches });
                        handleAddUpdate();
                        // }
                      }}
                    >
                      <DeleteOutline />
                    </div>
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>

          <div className="w-100 mt5 tc btn-space">
            <button
              type="button"
              className="btn-primary edit-save-button"
              onClick={handleAddUpdate}
            >
              SAVE
            </button>
          </div>

          <div className="page-bottom w-100">
            {/*<div className="flex w-100 justify-between items-center mb3">
              <h2 className="h1 weight-semi-bold">Brunches</h2>
              <button type="button" className="button-sm">
                <div className="plus-ico"><Add /></div>
                ADD NEW BRUNCH
              </button>
            </div>*/}

            {/*<div className="border flex justify-between description items-center pa3 mb2">
              <div className="font-18-regular">Friday Brunch</div>
              <span className="dot-white box-shadow"> <div></div> </span>
            </div>
            <div className="border flex justify-between description items-center pa3 mb2">
              <div className="font-18-regular">Saturday Brunch</div>
              <span className="dot-white box-shadow"> <div></div> </span>
            </div>
            <div className="border flex justify-between description items-center pa3 mb2">
              <div className="font-18-regular">Evening Brunch</div>
              <span className="dot-white box-shadow"> <div></div> </span>
            </div>*/}

            {/*<div className="page-bottom w-100">*/}
            {/*  <div className="flex w-100 justify-between items-center">*/}
            {/*    <h2 className="h1 weight-semi-bold">Events</h2>*/}
            {/*    <button type="button" className="button-sm bg-purple">*/}
            {/*      <div className="plus-ico"><Add /></div>*/}
            {/*      ADD NEW EVENT*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </Grid>

        {outlet && (
          <div className="tc">
            <Button
              variant="text"
              className="delete font-16-semiBold underline"
              onClick={handleDeleteOutlet}
            >
              DELETE LISTING
            </Button>
          </div>
        )}
      </div>
      <div>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenDialog(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Click on map or search to get location</DialogTitle>
          <DialogContent>
            <GooglePlacesAutocomplete
              selectProps={{ value, onChange: setValue }}
            />
            <GoogleMap
              mapContainerStyle={{
                height: "60vh",
                width: "100%",
                marginTop: "15px",
              }}
              center={center}
              zoom={4}
              onClick={handleMapClick}
            >
              <Marker position={center} />
            </GoogleMap>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default EditOutlet;
