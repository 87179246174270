import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    IconButton
} from "@mui/material";
import { Visibility, VisibilityOff, CheckCircleOutline, CancelRounded } from "@mui/icons-material";
import "./styles.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../../config/constants";
import base64 from 'base-64';
import moment from "moment";


const ResetPassword = () => {
    const params = useParams();
    const [state, setState] = useState({ newPassword: "", confirmPassword: "" });
    const [passwordReset, setPasswordReset] = useState(false);
    const [isExpired, setExpired] = useState(false);

    useEffect(() => {
        if (moment().utcOffset(4).unix() > base64.decode(params.unixTime)) {
            setExpired(true);
        }
    }, [passwordReset])

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setState((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    const onSubmitHandler = () => {
        if (!state.newPassword || !state.confirmPassword) {
            Swal.fire("Required", "All fields are mandatory", "error");
            return;
        }
        if (state.newPassword.length < 6 || state.confirmPassword.length < 6) {
            Swal.fire("Required", "Password must have at least 6 digits", "error");
            return;
        }
        if (state.newPassword !== state.confirmPassword) {
            Swal.fire("Both Password Not Match", "Please try again", "error");
            return;
        }
        axios.post(baseURL + "/reset-password", { id: params.id, newPassword: state.newPassword, unixTime: params.unixTime }).then((response) => {
            if (response.data.status === 2) {
                setPasswordReset(true);
                // Swal.fire("Success", "Password updated successfully.", "success");
                setState({ newPassword: "", confirmPassword: "" });
            }
            else if (response.data.status === 3) {
                Swal.fire("Error", "Please request for forgot password first and then try to reset your password.", "error");
            }
            else if (response.data.status === 4) {
                Swal.fire("Error", "Your link has expired.", "error");
            }
            else {
                Swal.fire("Error", "Something went wrong.", "error");
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    if (passwordReset) {
        return (<div className="reset-password-wrapper flex flex-column items-center justify-center">
            <CheckCircleOutline className="check-circle" style={{ fontSize: "6rem" }} />
            <h3 className="mb4 success-heading">Password Updated Successfully</h3>
        </div>)
    }
    if(isExpired){
        return (<div className="reset-password-wrapper flex flex-column items-center justify-center">
            <CancelRounded className="cross-circle" style={{ fontSize: "6rem" }} />
            <h5 className="mb4 error-heading">Your link has expired. Please try to forgot password again.</h5>
        </div>)
    }
    return (
        <div className="reset-password-wrapper flex flex-column items-center justify-center">
            <h3 className="mb4 main-heading">Reset Password</h3>
            <div className="flex flex-column">
                <div className="flex items-center relative mb3">
                    <input
                        type={state.showNewPwd ? "text" : "password"}
                        className="flex items-center relative"
                        placeholder="New Password"
                        autoComplete="off"
                        name="newPassword"
                        value={state.newPassword}
                        onChange={onChangeHandler}
                    />
                    <IconButton
                        className="eye-icon"
                        onClick={() => setState((prevValue) => {
                            return {
                                ...prevValue,
                                showNewPwd: !state.showNewPwd
                            }
                        })}
                    >
                        {state.showNewPwd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </div>

                <div className="flex items-center relative mb3">
                    <input
                        type={state.showConfirmPwd ? "text" : "password"}
                        className="flex items-center relative"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={state.confirmPassword}
                        onChange={onChangeHandler}
                    />
                    <IconButton
                        className="eye-icon"
                        onClick={() =>
                            setState((prevValue) => {
                                return {
                                    ...prevValue,
                                    showConfirmPwd: !state.showConfirmPwd
                                }
                            })
                        }
                    >
                        {state.showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </div>

                <Button
                    className="btn-primary w-100"
                    onClick={onSubmitHandler}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default ResetPassword;
