import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, Button, TableCell, TableRow, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Swal from "sweetalert2";

import { fetchAllOutlets, searchOutlets, setOutletItem } from "../../../redux/reducers/outlets";
import BreadcrumbDouble from "../../../components/breadcrumb/double";
import { fetchUserDetails } from "../../../redux/reducers/users";
// import { useSortableData } from "../../../components/SortTable";
import api, { EXPORT_APIS } from '../../../config/api-config';
import Navbar from "../../../components/navbar";
import exportFromJSON from "export-from-json";
import TableList from "../table-list";
import Loader from "../../../components/loader";
import { storageToken } from "../../../config/constants";
import "../venues.scss";

const Venues = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [offset, setoffset] = useState(0);
  const [cnt, setcnt] = useState(0);
  // const [searchArry, setsearchArry] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');
  const [search, setSearch] = useState(searchQuery ?? "");
  const [sortConfig, setSortConfig] = useState(null);
  const [key, setKey] = useState(null);

  const { outletsList, isLoading, user } = useSelector(
    (store) => ({
      isLoading: store.outlets.isLoading,
      outletsList: store.outlets.outletsList,
      user: store.users.user
    }),
    shallowEqual
  );

  useEffect(() => {
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
  }, [])

  useEffect(() => {
    dispatch(setOutletItem(null));
    if (search?.length) {
      dispatch(searchOutlets(search, key, sortConfig))
        .then((res) => {
          setcnt(res.length)
        });
    } else {
      dispatch(fetchAllOutlets(null, offset, key, sortConfig))
        .then((res) => {
          setcnt(res.count)
        });
    }
    // eslint-disable-next-line
  }, [offset, key, sortConfig]);

  const handleSearch = () => {
    if (search?.length) {
      dispatch(searchOutlets(search, key, sortConfig));
    } else {
      dispatch(fetchAllOutlets(null, 0, key, sortConfig))
        .then((res) => {
          setcnt(res.count)
        });
    }
  };

  useEffect(() => {
    if (search.length === 0) handleSearch(search);
    // eslint-disable-next-line
  }, [search]);

  // const { items, requestSort, sortConfig } = useSortableData(outletsList);
  // const getClassNamesFor = (name) => {
  //   if (!sortConfig) {
  //     return;
  //   }
  //   return sortConfig.key === name ? sortConfig.direction : undefined;
  // };

  // const handleSearch = (e) => {
  //   let txt = e.target.value;
  //   setSearch(txt);
  //   if (txt === "") setsearchArry([...outletsList]);
  //   else {
  //     let filterData1 = searchArry.filter((item) => {
  //       return item?.outletName?.toLowerCase().includes(txt.toLowerCase());
  //     });
  //     setsearchArry(filterData1);
  //   }
  // };

  const exportData = () => {
    api.get(EXPORT_APIS.listing)
      .then(response => {
        if (response?.data?.data) {
          response?.data?.data.map((list) => {
            delete list?._id;
            delete list?.id;
            delete list?.associatedOffers;
            delete list?.bannerImage;
            delete list?.logoImage;
            delete list?.orderStatus;
            delete list?.otherImages;
            delete list?.createdAt;
            delete list.associatedBrunches;
            delete list.associatedEvents;
            delete list.coords;
          });
          exportFromJSON({
            data: response?.data?.data,
            fileName: "Listings",
            exportType: exportFromJSON.types.csv,
          });
        } else {
          Swal.fire("Error", "Something went wrong. Please try again", "error");
        }
      })
      .catch(err => {
        console.log(err)
        Swal.fire("Error", "Something went wrong. Please try again", "error");
      });
  }
  return (
    <div>
      <Navbar />
      {user && user.role !== "vendor" && <BreadcrumbDouble breadFirst="Home" breadLast="Listings" linkFirst="/" />}
      {isLoading && <Loader />}
      <div className="container-main">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className="flex items-center">
              <input
                type="text"
                className="inpt-width"
                placeholder="Search Listings by name..."
                onChange={(evt) => {
                  // handleSearch(evt);
                  setSearch(evt.target.value);
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
                value={search}
              />
              <Button
                className="search flex justify-center items-center"
                onClick={handleSearch}
              >
                <img src="/images/search.png" alt="search" />
              </Button>
            </div>
              <div className="text-grey font-16-semiBold ml3">
                <span>{cnt} </span>Results
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <div className="tr-ns tl-m ma1">
              <div className="flex flex-row fr-ns fl-m outlet-buttons">
                <Button
                  type="button"
                  style={{ marginRight: "10px" }}
                  className="button-sm normal-btn-space"
                  onClick={exportData}
                >
                  EXPORT
                </Button>
                <Button
                  type="button"
                  href="/outlets/add"
                  className="button-sm bg-green"
                >
                  <div className="plus-ico">
                    <Add />
                  </div>
                  NEW LISTING
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="mt4 table-main">
          <TableContainer>
            <Table className="w-100" sx={{ minWidth: 991 }}>
              <TableHead>
                <TableRow>
                  {/*<TableCell>ID <span className="filter-icon"></span></TableCell>*/}
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("outletName");
                    }}
                  >
                    Listing Name
                    {sortConfig && key === "outletName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("updatedAt");
                    }}
                  >
                    Last Updated
                    {sortConfig && key === "updatedAt" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("isFeatured");
                  // }}
                  >
                    Days Featured
                    {/* {sortConfig && key === "isFeatured" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("views");
                  // }}
                  >
                    Views
                    {/* {sortConfig && key === "views" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("redemptions");
                  // }}
                  >
                    Redemptions
                    {/* {sortConfig && key === "redemptions" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("status");
                    }}
                  >
                    Status
                    {sortConfig && key === "status" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outletsList?.map((item, index) => (
                  <TableList isOutlet item={item} index={index + 1} key={index} search={search} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="button"
            className="btn"
            onClick={() => {
              if (offset < 0) {
                setoffset(0);
              } else {
                setoffset(offset - 10);
              }
            }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            type="button"
            className="btn"
            onClick={() => {
              setoffset(offset + 10);
            }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Venues;
