import React, { useEffect, useState } from "react";
import { Grid, Button, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { CheckBoxOutlineBlank, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Swal from "sweetalert2";
import "./login.scss";
import { authenticateUser } from "../../redux/reducers/auth-user";
const remember_cred = JSON.parse(localStorage.getItem("remember"))
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Checked, setChecked] = useState(remember_cred?.checked ?? false)
  // const { user } = useSelector(
  //   (store) => ({
  //     user: store.auth.user,
  //   }),
  //   shallowEqual
  // );

  const [username, setUsername] = useState(remember_cred?.username ?? "");
  const [password, setPassword] = useState(remember_cred?.password ?? "");
  const [showPwd, setShowPwd] = useState(false);

  // useEffect(() => {
  //   if (user) navigate("/home");
    // eslint-disable-next-line
  // }, [user]);

  const handleLogin = () => {
    if (!username || !password) {
      Swal.fire("Required", "Username and Password are required", "error");
      return;
    }

    dispatch(authenticateUser(username, password))
      .then((data) => {
        let userRole = data?.user?.role;
        window.localStorage.setItem("Role", userRole);
        if (userRole === "admin") navigate("/home");
        else if (userRole === "vendor") navigate("/venues");
        else Swal.fire("Unauthorised", "You can not have permission for this action.", "error");
      })
      .catch((err) => {
        console.log("ERR: ", err);
        if (err.unauthorised) {
          Swal.fire("Unauthorised", "You can not have permission for this action.", "error");
        }
        else if (err === "Unauthorised") {
          Swal.fire("Unauthorised", "Invalid credentials", "error");
        }else {
          Swal.fire("Unauthorised", err, "error");
        }
      });
  };

  return (
    <Grid item lg={12} container className="login-main vh-100 flex items-center">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div className="login tc w-80 align">
          <a href="/home">
            {" "}
            <img src="./images/logo.svg" alt="logo" />{" "}
          </a>
          <h1> Welcome to the Let's DXB Admin Portal. </h1>
          <p>Please enter your username and password to get started.</p>
          <form className="form flex flex-column login-form">
            <input
              type="text"
              placeholder="Email Address"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
            <div className="flex items-center relative">
              <input
                type={showPwd ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
              <IconButton
                className="eye-icon"
                onClick={() => setShowPwd(!showPwd)}
              >
                {showPwd ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
            <Button className="btn-primary w-100" onClick={handleLogin}>
              SIGN IN
            </Button>
            <div className="remember-login">
              <FormControlLabel
                className="checkbox mb2 pl3"
                control={
                  <Checkbox
                    id="chk1"
                    checked={Checked}
                    onChange={event => {
                      setChecked(event.target.checked)
                      if (event.target.checked)
                        localStorage.setItem("remember", JSON.stringify({ checked: event.target.checked, username, password }))
                      else
                        localStorage.removeItem("remember")
                    }}
                    icon={
                      <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                    }
                    checkedIcon={<img src="/images/chck.svg" alt="check" />}
                  />
                }
                label=""
              />
              <label htmlFor="chk1">Remember me</label>
            </div>
            <div className="flex justify-between login-help mt4">
              <a href="/forget-password">Forgotten your password?</a>
              <a href="mailto:info@letsdxb.com">
                Need help? <span className="clr-primary">Contact us</span>
              </a>
            </div>
          </form>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div className="side-img">
          <img src="./images/login-page-img.png" className="vh-100 w-100" alt="login page" />
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
