import React, { useEffect, useReducer, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Button, Grid, IconButton, Menu, MenuItem, Modal } from "@mui/material";
import { Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import axios from "axios";
import { changePassword, logout } from "../../redux/reducers/auth-user";
import { fetchUserDetails } from "../../redux/reducers/users";
import { initReducer } from "../../utils/common-methods";
import { storageToken, PACKAGES } from "../../config/constants";
import { baseURL } from "../../config/constants";
import { PLANS_APIS } from "../../config/api-config";
import "./navbar.scss";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [plans, setPlans] = useState([]);
  const [state, setState] = useReducer(initReducer, {
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
    showOldPwd: false,
    showNewPwd: false,
    showConfirmPwd: false,
  });
  const [profileImg, setprofileImg] = useState(null);
  const { user } = useSelector(
    (store) => ({
      user: store.users.user,
    }),
    shallowEqual
  );
  useEffect(() => {
    const fetchPic = () => {
      const userToken = window.localStorage.getItem(storageToken);
      const userDetail = jwt_decode(userToken);
      if (userDetail) {
        dispatch(fetchUserDetails(userDetail.id))
          .then((res) => {
            setprofileImg(user?.profilePic);
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    };
    fetchPic();

    axios.get(baseURL + PLANS_APIS.all).then(response => {
      if (response.status === 200) {
        setPlans(response.data.data)
      }
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mobile-navbar">
        <div className="logo">
          <NavLink to="/home">
            <img src="/images/logo.svg" height={"100px"} width={"100px"} alt="logo" />
          </NavLink>
        </div>
        <div onClick={() => setShowNavbar(true)}>
          <MenuIcon />
        </div>
      </div>
      <div className={`${showNavbar ? 'navbar-main navbar-main-active' : 'navbar-main'}`}>
        <Grid container spacing={2} className="navbar-wrap">
          <Grid item xs={12} md={10} lg={10} className="left">
            <div className="flex items-center navbar-wrapper">
              <div className="logo">
                <NavLink to="/home">
                  <img src="/images/logo.svg" alt="logo" />
                </NavLink>
                <div className="navbar-close" onClick={() => setShowNavbar(false)}>
                  <CloseIcon />
                </div>
              </div>
              <ul className="navbar-menu flex">
                <li>
                  <NavLink to="/venues">Venue (Hotel/Area)</NavLink>
                </li>
                <li>
                  <NavLink to="/outlets">Listings</NavLink>
                </li>
                <li>
                  <NavLink to="/events">Deals</NavLink>
                </li>
                {user && user.role === "admin" && <li>
                  <NavLink to="/users">Users</NavLink>
                </li>}
                <li>
                  <NavLink to="/redemptions">Redemptions</NavLink>
                </li>
                <li>
                  <NavLink to="/report">Reports</NavLink>
                </li>
                {user && user.role === "admin" && <li>
                  <NavLink to="/notifications">Notification</NavLink>
                </li>}
                {user && user.role === "admin" && <li>
                  <NavLink to="/appUpdateSetting">Settings</NavLink>
                </li>}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={2} lg={2}  className="flex items-center avatar fr justify-end right" >
            <button
              style={{ background: "transparent", border: "none" }}
              id="user-menu-icon"
              aria-controls={showUserMenu ? "user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={showUserMenu ? "true" : undefined}
              onClick={(evt) => {
                setAnchorEl(evt?.currentTarget);
                setShowUserMenu(!showUserMenu);
              }}
            >
              <Avatar className="fr">
                {profileImg ? <img src={profileImg} alt="logo" /> : <Person />}
              </Avatar>
            </button>
            <Menu
              anchorEl={anchorEl}
              id="user-menu"
              aria-labelledby="user-menu-icon"
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={showUserMenu}
              onClose={() => {
                setAnchorEl(null);
                setShowUserMenu(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setShowUserMenu(false);
                  setShowChangePwd(true);
                }}
              >
                Change Password
              </MenuItem>
              {user && user?.package === PACKAGES.MULTI_LISTING && <MenuItem
                onClick={() => {
                  history("/user-plan/2");
                }}
              >
                Upgrade Listing Number
              </MenuItem>}
              {user && user?.role === "vendor" && user?.package !== "7cc2g350-9d9c-51ed-9484-5dfe7f6834df" && <MenuItem
                onClick={() => {
                  history("/user-plan/3");
                }}
              >
                Upgrade Package
              </MenuItem>}
              {user && user?.role === "vendor" && <MenuItem>
                Active Plan - {user.package ? plans.find((plan) => plan.id === user.package)?.title : "None"}
              </MenuItem>}
              <MenuItem
                onClick={() => {
                  dispatch(logout());
                  setAnchorEl(null);
                  setShowUserMenu(false);
                  history("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Grid>

          <Modal open={showChangePwd} onClose={() => setShowChangePwd(false)}>
            <div className="change-password-wrapper flex  flex-column items-center justify-center">
              <h3>Change Password</h3>
              <div className="flex flex-column">
                <div className="flex items-center relative mb3">
                  <input
                    type={state.showOldPwd ? "text" : "password"}
                    className="flex items-center relative"
                    placeholder="Old Password"
                    value={state.oldPassword}
                    onChange={(evt) =>
                      setState({ oldPassword: evt?.target?.value })
                    }
                  />
                  <IconButton
                    className="eye-icon"
                    onClick={() => setState({ showOldPwd: !state.showOldPwd })}
                  >
                    {state.showOldPwd ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>

                <div className="flex items-center relative mb3">
                  <input
                    type={state.showNewPwd ? "text" : "password"}
                    className="flex items-center relative"
                    placeholder="New Password"
                    value={state.newPassword}
                    onChange={(evt) =>
                      setState({ newPassword: evt?.target?.value })
                    }
                  />
                  <IconButton
                    className="eye-icon"
                    onClick={() => setState({ showNewPwd: !state.showNewPwd })}
                  >
                    {state.showNewPwd ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>

                <div className="flex items-center relative mb3">
                  <input
                    type={state.showConfirmPwd ? "text" : "password"}
                    className="flex items-center relative"
                    placeholder="New Password"
                    value={state.confirmPassword}
                    onChange={(evt) =>
                      setState({ confirmPassword: evt?.target?.value })
                    }
                  />
                  <IconButton
                    className="eye-icon"
                    onClick={() =>
                      setState({ showConfirmPwd: !state.showConfirmPwd })
                    }
                  >
                    {state.showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>

                <Button
                  className="btn-primary w-100"
                  onClick={() => {
                    if (
                      !state.oldPassword ||
                      !state.newPassword ||
                      !state.confirmPassword
                    ) {
                      alert("All fields are mandatory");
                      return;
                    }
                    if (state.newPassword !== state.confirmPassword) {
                      alert("New and Confirm password do not match");
                      return;
                    }
                    // let remember = JSON.parse(localStorage.getItem("remember"))
                    // remember.password = state.newPassword
                    // localStorage.setItem("remember", JSON.stringify(remember))
                    dispatch(changePassword(state.oldPassword, state.newPassword))
                      .then((response) => {
                        if (response?.data?.passwordUpdated) {
                          alert("Password changed successfully");
                          history("/");
                        } else {
                          alert(response?.message);
                        }
                      })
                      .catch((err) => console.error("ERR CHANGING PWD: ", err));
                  }}
                >
                  Set Password
                </Button>
              </div>
            </div>
          </Modal>
        </Grid>
      </div>
    </>
  );
};

export default Navbar;
