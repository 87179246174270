import React from "react";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";

const TableList = ({ item, user }) => {
  return (
    <TableRow>
      <TableCell>
        <div className="flex items-center">
          <div className="user-img">
            <img
              src={item?.itemDetails?.bannerImage ?? "/images/feat-img.png"}
              alt="item banner"
            />
          </div>
          {item?.itemDetails?.offer?.offerName??"-"}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          <div className="user-img">
            <img src={item?.itemDetails?.logoImage ?? "/images/feat-img.png"} alt="item logo" />
          </div>
          {item?.itemDetails?.eventName ?? item?.itemDetails?.outletName}
        </div>
      </TableCell>
      {user?.role !== "vendor" && <TableCell>{item?.user?.email}</TableCell>}
      <TableCell>{moment(item?.createdAt).format("DD/MM/YYYY")}</TableCell>
      <TableCell>{moment(item?.createdAt).utcOffset(4).format("HH:mm")}</TableCell>
      <TableCell>{item?.code ?? "-"}</TableCell>
    </TableRow>
  );
};

export default TableList;
