import React from 'react';
import { NavLink } from "react-router-dom";
import "./styles.scss";

const NotFound = () => {
    return (<div className="error-container flex flex-column items-center justify-center">
        <h1 className="flex justify-center align-items-center not-found-status mb3">404</h1>
        <h3 className="flex justify-center align-items-center mb3">Page Not Found</h3>
        <NavLink to="/home" className="flex justify-center align-items-center">Back to Home</NavLink>
    </div>)
}

export default NotFound;