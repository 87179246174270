import {
  VENUES_LIST, VENUES_ERROR, VENUES_LOADING, VENUES_ITEM
} from '../state-constants';
import api, { VENUE_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';
import { setUsersLoading } from './users';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  venue: null,
  venuesList: []
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case VENUES_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case VENUES_ITEM:
      return {
        ...state,
        venue: action.venue
      };

    case VENUES_LIST:
      return {
        ...state,
        venuesList: action.venuesList
      };

    case VENUES_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: VENUES_ERROR, error: error?.response?.data?.message ?? error });
};

export const setVenueLoading = loading => {
  return dispatch => dispatch({ type: VENUES_LOADING, loading });
};

export const setVenueItem = venue => {
  return dispatch => dispatch({ type: VENUES_ITEM, venue });
};

export const fetchAllVenues = (offset, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.get(VENUE_APIS.venues + `?offset=${offset ? offset : 0}&key=${key ? key : null}&sortConfig=${sortConfig ? sortConfig : null}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;
          if (!data?.data) {
            resolve({});
            return;
          }
console.log('data venue>>>',data)
          dispatch({
            type: VENUES_LIST,
            venuesList: data?.data
          });

          dispatch(setVenueLoading(false));
          resolve(data);
        })
        .catch(err => {
          dispatch(setVenueLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setVenueLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const addVenue = (payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.post(VENUE_APIS.venues, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG, 400);
            handle400Rejections(response.status, reject);
            return;
          }
          handleErr(dispatch, null, 200);
          dispatch(setVenueLoading(false));
          if (!searchQuery) dispatch(fetchAllVenues());
          resolve({});
        })
        .catch(err => {
          dispatch(setVenueLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setVenueLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const updateVenue = (id, payload, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.put(`${VENUE_APIS.venues}/${id}`, payload)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG, 400);
            handle400Rejections(response.status, reject);
            return;
          }
          handleErr(dispatch, null, 200);
          dispatch(setVenueLoading(false));
          if (!searchQuery) dispatch(fetchAllVenues());
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const deleteVenue = (id, searchQuery) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.delete(`${VENUE_APIS.venues}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          if (!searchQuery) dispatch(fetchAllVenues());
          dispatch(setVenueLoading(false));
          resolve({});
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const fetchVenueById = (id) => {
  return dispatch => new Promise((resolve, reject) => {
    if (id) {
      try {
        dispatch(setVenueLoading(true));
        api.get(`${VENUE_APIS.venues}/${id}`)
          .then(response => {
            if (response.status > 399) {
              dispatch(setUsersLoading(false));
              handleErr(dispatch, COMMON_API_ERR_MSG);
              handle400Rejections(response.status, reject);
              return;
            }

            const { data } = response;

            if (!data?.data) {
              resolve({});
              return;
            }

            dispatch(setVenueLoading(false));
            dispatch(setVenueItem(data?.data));

            resolve(data?.data);
          })
          .catch(err => {
            dispatch(setVenueLoading(false));
            handleErr(dispatch, err);
            reject(err);
          });
      } catch (error) {
        dispatch(setVenueLoading(false));
        handleErr(dispatch, error);
        reject(error);
      }
    } else {
      dispatch(setVenueItem(null));
    }
  });
};

export const addVendorToVenue = (id, vendorId) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.put(`${VENUE_APIS.vendor}/${id}`, { vendorId })
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }
          dispatch(setVenueLoading(false));
          dispatch(fetchAllVenues());
          resolve({});
        })
        .catch(err => {
          dispatch(setVenueLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setVenueLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const removeVenueVendor = id => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setVenueLoading(true));
      api.delete(`${VENUE_APIS.venues}/${id}`)
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }
          dispatch(setVenueLoading(false));
          dispatch(fetchAllVenues());
          resolve({});
        })
        .catch(err => {
          dispatch(setVenueLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setVenueLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};

export const searchVenues = (keyword, key, sortConfig) => {
  return dispatch => new Promise((resolve, reject) => {
    dispatch(setVenueLoading(true));
    try {
      api
        .post(VENUE_APIS?.search, { keyword, key, sortConfig })
        .then(response => {
          if (response.status > 399) {
            dispatch(setUsersLoading(false));
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (data) {
            dispatch({ type: VENUES_LIST, venuesList: data?.data });
            dispatch(setVenueLoading(false));
            resolve(data?.data);
            return;
          }

          if (!data?.data) {
            resolve({});
            return;
          }
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    }
    catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  });
};

export const updateVendorVenuesListing = (id, vendorVenues, vendorListing) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      api
        .post(VENUE_APIS?.vendorVenueListing, { id, venues: vendorVenues, listings: vendorListing })
        .then(response => {

          if (!response?.data) {
            resolve({});
            return;
          }
        })
        .catch(err => {
          handleErr(dispatch, err);
          reject(err);
        });
    }
    catch (e) {
      handleErr(dispatch, e);
      reject(e);
    }
  })
}
