import React from 'react'
import moment from 'moment';
import { DeleteForever, Edit } from '@mui/icons-material';
import { IconButton, TableRow, TableCell } from '@mui/material';
import { NATIONALITIES_LIST } from "../../config/constants";

const TableList = ({ item, handleEdit, handleDelete }) => {
  
  return (
    <TableRow>
      <TableCell>
        <img src={item?.profilePic ? item?.profilePic : '/images/logo.svg'} style={{ width: 50, height: 50, borderRadius: 30 }} alt="profile" />
      </TableCell>
      <TableCell>{item?.firstName}  </TableCell>
      <TableCell>{item?.lastName} </TableCell>
      <TableCell>{item?.email}</TableCell>
      <TableCell>{moment(item?.createdAt)?.format('DD MMM YYYY')}</TableCell>
      <TableCell>{NATIONALITIES_LIST.filter(country => country.value == item?.nationality)[0]?.label }</TableCell>
      <TableCell>{item?.gender}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleEdit(item)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => handleDelete(item)}>
          <DeleteForever />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default TableList
