import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { DeleteForever, Edit } from '@mui/icons-material';

const TableList = ({ item, handleEdit, handleDelete }) => {
  return (
    <TableRow>
      <TableCell>{item?.eventName}</TableCell>
      <TableCell>{item?.title && (item?.title?.length<40?item?.title:item?.title.substr(0,30)+"...")} </TableCell>
      <TableCell>{item?.message.length<40?item?.message:item?.message.substr(0,37)+"..."} </TableCell>
      <TableCell>{item?.offer?.offerName} </TableCell>
      <TableCell>{item?.notificationDate}</TableCell>
      <TableCell>{item?.notificationTime}</TableCell>
      <TableCell>
        {item?.isActive?(
          <label style={{color:"green"}}>Sent</label>
        ) : (
          <label style={{color:"red"}}>Pending</label>
        )}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleEdit(item)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => handleDelete(item)}>
          <DeleteForever />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableList;
