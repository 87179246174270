import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  Select,
  FormControl,
  TextField,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  CameraAlt,
  HorizontalRule,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import Navbar from "../../components/navbar";
// import Breadcrumb from "../../components/breadcrumb";
import { initReducer, uploadFile } from "../../utils/common-methods";
import {
  DRESS_CODES,
  PRICE,
  FACILITIES,
  CUISINES,
} from "../../config/constants";
import {
  addOffer,
  updateOffer,
  fetchOfferById,
} from "../../redux/reducers/offers";
import {
  addEvent,
  updateEvent,
  deleteEvent,
  fetchEventById,
  setEventItem,
} from "../../redux/reducers/events";
import "./events.scss";
import moment from "moment";
import { updateOutlet } from "../../redux/reducers/outlets";
import { fetchUserDetails } from "../../redux/reducers/users";
import { storageToken } from "../../config/constants";
import { handleCompressedUpload } from "../../utils/ImageCompress";
import api, { OUTLET_APIS } from "../../config/api-config";
import Loader from "../../components/loader";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { ArrowBackIosNew, MoreHoriz } from "@mui/icons-material";

const EditEvent = () => {
  let offerID = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search");

  const { event, offer, user } = useSelector(
    (store) => ({
      event: store?.events?.event,
      // eventLoading: store?.events?.isLoading,
      offer: store?.offers?.offer,
      user: store.auth.user,
      // offerLoading: store?.offers?.isLoading,
      // outletsListS: store?.outlets?.outletsList,
    }),
    shallowEqual
  );

  const initialState = {
    eventName: "",
    addressLine1: "",
    addressLine2: "",
    email: "",
    logoImage: null,
    bannerImage: null,
    otherImages: [],
    description: "",
    isLogoForOutlet: false,
    isBannerForOutlet: false,
    isRestaurant: false,
    // isBar: false,
    isBrunch: false,
    isActive: true,
    isFeatured: false,
    isExplore: false,
    dressCode: "",
    price: 100,
    facilities: [],
    cuisines: [],
    bookingLink: "",
    venueRating: 0,
    coords: [],
    associatedOffers: [],
    cuisineClass: "",
    timings: [
      { day: "Monday", startTime: "11:00", endTime: "23:30" },
      { day: "Tuesday", startTime: "11:00", endTime: "23:30" },
      { day: "Wednesday", startTime: "11:00", endTime: "23:30" },
      { day: "Thursday", startTime: "11:00", endTime: "23:30" },
      { day: "Friday", startTime: "11:00", endTime: "23:30" },
      { day: "Saturday", startTime: "11:00", endTime: "23:30" },
      { day: "Sunday", startTime: "11:00", endTime: "23:30" },
    ],
    brunchPrice: "",
    isDraft: false,
    status: false,
    isAdminApprove: false,
    whatsappBusinessLink: "",
    associatedVenueName: null,
    restaurantsOrBars: [],
  };
  const initialStateOffer = {
    offerName: "",
    discount: 10,
    description:
      "The venue reserves the right of admission at all times and all deals involving Alcoholic beverages are intended for use by people over the age of 21, and non-muslim. This offer(s) are redeemable by activated app members only. Each discount is applicable to ONE person only and cannot be shared. Offers cannot be used in conjunction with any other special promotions or discount schemes.",
    expiryDate: "",
    isPromotional: false,
  };
  const [outletsList, setoutletList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useReducer(initReducer, initialState);
  const [offerPrev, setOfferPrev] = useReducer(initReducer, initialStateOffer);
  const [stateOffer, setStateOffer] = useReducer(
    initReducer,
    initialStateOffer
  );
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  useEffect(() => {
    // dispatch(fetchAllOutlets(1));
    if (params?.id) {
      dispatch(fetchEventById(params?.id));
    } /*else {
      dispatch(setEventItem(null));
    }*/
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (event) {
      let final_time = [
        { day: "Monday", startTime: "", endTime: "" },
        { day: "Tuesday", startTime: "", endTime: "" },
        { day: "Wednesday", startTime: "", endTime: "" },
        { day: "Thursday", startTime: "", endTime: "" },
        { day: "Friday", startTime: "", endTime: "" },
        { day: "Saturday", startTime: "", endTime: "" },
        { day: "Sunday", startTime: "", endTime: "" },
      ];
      event?.timings?.map((time) => {
        final_time.map((ftime, index) => {
          if (ftime.day == time.day) {
            final_time[index] = time;
          }
        });
      });
      setState({
        eventName: event?.eventName ?? event?.outletName ?? "",
        addressLine1: event?.addressLine1 ?? "",
        addressLine2: event?.addressLine2 ?? "",
        email: event?.email ?? "",
        logoImage: event?.logoImage ?? null,
        bannerImage: event?.bannerImage ?? null,
        otherImages: event?.otherImages ?? [],
        description: event?.description ?? "",
        isLogoForOutlet: event?.isLogoForOutlet ?? false,
        isBannerForOutlet: event?.isBannerForOutlet ?? false,
        isActive: event?.isActive ?? true,
        isFeatured: event?.isFeatured ?? false,
        isRestaurant: event?.isRestaurant ?? false,
        // isBar: event?.isBar ?? false,
        isBrunch: event?.isBrunch ?? false,
        isExplore: event?.isExplore ?? false,
        dressCode: event?.dressCode ?? "",
        price: event?.price ?? 100,
        facilities: !Array.isArray(event?.facilities)
          ? event?.facilities?.split(",") ?? []
          : event?.facilities,
        cuisines: !Array.isArray(event?.cuisines)
          ? event?.cuisines?.split(",") ?? []
          : event?.cuisines,
        bookingLink: event?.bookingLink ?? "",
        venueRating: event?.venueRating ?? 0,
        coords: event?.coords ?? [],
        cuisineClass: event?.cuisineClass ?? "",
        timings: final_time,
        offerName: event?.offerName ?? "",
        brunchPrice: event.brunchPrice ?? "",
        isDraft: event.isDraft,
        status: event.status,
        isAdminApprove: event?.isAdminApprove ?? false,
        whatsappBusinessLink: event?.whatsappBusinessLink ?? "",
        associatedVenueName: event?.associatedVenueName ?? null,
        restaurantsOrBars: event?.restaurantsOrBars ?? [],
      });

      if (event?.associatedOffers?.length) {
        dispatch(fetchOfferById(event?.associatedOffers[0]));
      }
    }
    // eslint-disable-next-line
  }, [event]);
  useEffect(() => {
    _outletList();
  }, []);

  const _outletList = () => {
    setisLoading(true);

    api
      .get(OUTLET_APIS.outlets)
      .then((response) => {
        console.log("data>>", JSON.stringify(response));
        if (response.status === 200) {
          setoutletList(response.data.data);
        }
        setisLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setisLoading(false);
      });
  };
  useEffect(() => {
    if (offer) {
      setStateOffer({
        offerName: offer?.offerName ?? "",
        description:
          offer?.description ??
          "The venue reserves the right of admission at all times and all deals involving Alcoholic beverages are intended for use by people over the age of 21, and non-muslim. This offer(s) are redeemable by activated app members only. Each discount is applicable to ONE person only and cannot be shared. Offers cannot be used in conjunction with any other special promotions or discount schemes.",
        discount: offer?.discount ?? 10,
        isPromotional: offer?.isPromotional ?? false,
        expiryDate: offer?.expiryDate ?? "",
      });
      setOfferPrev(stateOffer);
    }
  }, [offer]);

  const getInitials = (name) => {
    let initials = "";
    const nameArr = name.split(" ");
    if (nameArr?.length > 0) {
      initials += nameArr[0]?.charAt(0);
    }
    if (nameArr?.length > 1) {
      initials += nameArr[1]?.charAt(0);
    }

    return initials;
  };

  const openLogoChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("logo-file-chooser").click();
    }
  };

  const openBannerChooser = () => {
    if (window !== undefined && document !== undefined) {
      document.getElementById("banner-file-chooser").click();
    }
  };

  const handleLogoUpload = async (evt) => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file);
      const resp = await uploadFile(compressImg, false);
      if (resp?.filePath) {
        setState({ logoImage: resp?.filePath });
        Swal.fire("Success", "Logo image uploaded", "success");
      }
    }
  };

  const handleBannerUpload = async (evt) => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file);
      const resp = await uploadFile(compressImg, false);
      if (resp?.filePath) {
        setState({ bannerImage: resp?.filePath });
        Swal.fire("Success", "Banner image uploaded", "success");
      }
    }
  };

  const handleDeleteEvent = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Deal ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Keep",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEvent(id, searchQuery))
          .then(() => {
            Swal.fire({
              title: "Deal successfully deleted.",
              timer: 2500,
              icon: "success",
            }).then(() => {
              console.log(JSON.stringify(result), "result >>>");
              searchQuery
                ? navigate(`/events?search=${searchQuery}`)
                : navigate("/events");
            });
          })
          .catch(() => {
            Swal.fire({
              title: "There was error deleting Deal. Try again.",
              timer: 3000,
              icon: "error",
            });
          });
      }
    });
  };

  const addUpdateOffer = () => {
    const {
      offerName,
      description,
      discount,
      expiryDate,
      isPromotional,
      associatedVenueName,
      restaurantsOrBars,
    } = stateOffer;

    if (!offerName.trim()) {
      Swal.fire("Required", "Deal Name field is required", "error");
      return;
    } else if (!description) {
      Swal.fire("Required", "Description field is required", "error");
      return;
    } else if (!discount || discount < 1) {
      Swal.fire("Required", "Discount field is required", "error");
      return;
    }

    if (moment(expiryDate).isSameOrBefore(moment())) {
      Swal.fire("Invalid", "Expiry date should be in future", "error");
      return;
    }

    const payload = {
      offerName,
      description,
      discount,
      expiryDate,
      isPromotional,
      associatedVenueName,
      restaurantsOrBars,
    };
    payload.eventId = params?.id;
    if (offer?.id) {
      offerID = offer?.id;
      dispatch(updateOffer(offer?.id, payload))
        .then(() => {
          addUpdateEvent();
        })
        .catch((err) => console.error("ERROR UPDATING OFFER: ", err));
    } else {
      dispatch(addOffer(payload))
        .then((resp) => {
          offerID = resp;
          addUpdateEvent();
        })
        .catch((err) => console.error("ERROR ADDING OFFER: ", err));
    }
  };

  const addUpdateEvent = () => {
    let {
      eventName,
      email,
      addressLine1,
      addressLine2,
      logoImage,
      bannerImage,
      otherImages,
      description,
      isLogoForOutlet,
      isBannerForOutlet,
      isFeatured,
      dressCode,
      price,
      facilities,
      coords,
      cuisines,
      venueRating,
      bookingLink,
      associatedOffers,
      timings,
      cuisineClass,
      redeemCode,
      brunchPrice,
      isAdminApprove,
      whatsappBusinessLink,
      restaurantsOrBars,
      associatedVenueName,
    } = state;

    if (!eventName.trim()) {
      Swal.fire("Required", "Please select listing", "error");
      return;
    }
    let outlet = outletsList?.find((item) => item?.id === selectedOutlet);

    const payload = {
      eventName,
      addressLine1,
      addressLine2,
      logoImage,
      bannerImage,
      description,
      dressCode,
      price,
      isFeatured,
      timings,
      isLogoForOutlet,
      isBannerForOutlet,
      venueRating: 0,
      isRestaurant: state?.isRestaurant ?? false,
      isBrunch: state?.isBrunch ?? false,
      isExplore: state?.isExplore ?? false,
      mobile: outlet?.mobile ?? null,
      redeemCode: redeemCode ?? outlet?.redeemCode ?? "6262",
      brunchPrice: brunchPrice ?? "",
      status: state.status,
      isDraft: state.isDraft,
      isAdminApprove,
      whatsappBusinessLink,
      restaurantsOrBars,
      associatedVenueName,
    };

    if (selectedOutlet) payload.outletId = selectedOutlet;

    if (offer && !associatedOffers?.length) associatedOffers.push(offer?.id);
    if (offerID) associatedOffers = [offerID];
    if (cuisineClass) payload.cuisineClass = cuisineClass;
    if (venueRating) payload.venueRating = Number(venueRating);
    if (email) payload.email = email;
    if (otherImages?.length) payload.otherImages = otherImages;
    if (facilities?.length) payload.facilities = facilities?.join(",");
    if (coords) payload.coords = coords;
    if (cuisines?.length) payload.cuisines = cuisines?.join(",");
    if (bookingLink) payload.bookingLink = bookingLink;
    if (associatedOffers?.length) payload.associatedOffers = associatedOffers;

    if (params?.id) {
      setisLoading(true);
      dispatch(updateEvent(params?.id, payload, searchQuery))
        .then(() => {
          // if(!outlet) outlet = outletsList?.find((item) => item?.id === event.outletId);
          if (outlet) {
            // if((!payload.status || payload.isDraft) && outlet?.associatedEvents?.includes(params?.id)){
            //   outlet?.associatedEvents?.splice(outlet?.associatedEvents?.indexOf(params.id),1);
            // }
            // else
            if (
              outlet?.associatedEvents?.length &&
              !outlet?.associatedEvents?.includes(params?.id)
            ) {
              outlet?.associatedEvents?.push(params.id);
            } else {
              outlet.associatedEvents = [params.id];
            }
            const outletPayload = { ...outlet };
            outletPayload.associatedEvents = [
              ...new Set(outletPayload.associatedEvents),
            ];
            // outletPayload.isFeatured = true;
            delete outletPayload.id;
            delete outletPayload._id;
            delete outletPayload.events;
            delete outletPayload.featuredAt;
            delete outletPayload.featuredEndAt;
            delete outletPayload.createdAt;
            delete outletPayload.updatedAt;
            delete outletPayload.__v;

            dispatch(updateOutlet(outlet?.id, outletPayload));
          }
          setisLoading(false);
          Swal.fire("Success", "Deal successfully updated", "success").then(
            (res) => {
              dispatch(setEventItem(null));
              searchQuery
                ? navigate(`/events?search=${searchQuery}`)
                : navigate("/events");
            }
          );
        })
        .catch((err) => {
          setisLoading(false);
          dispatch(updateOffer(offer?.id, offerPrev));
          Swal.fire(
            "Error",
            err?.response?.data?.message ?? "Something went wrong",
            "error"
          );
        });
    } else {
      setisLoading(true);
      if (outlet) payload.outletId = outlet.id;
      dispatch(addEvent(payload, searchQuery))
        .then((eventId) => {
          if (outlet) {
            if (outlet?.associatedEvents?.length) {
              outlet?.associatedEvents?.push(eventId);
            } else {
              outlet.associatedEvents = [eventId];
            }
            const outletPayload = { ...outlet };
            // outletPayload.isFeatured = true;
            outletPayload.associatedEvents = [
              ...new Set(outletPayload.associatedEvents),
            ];
            delete outletPayload.id;
            delete outletPayload._id;
            delete outletPayload.events;
            delete outletPayload.featuredAt;
            delete outletPayload.featuredEndAt;
            delete outletPayload.createdAt;
            delete outletPayload.updatedAt;
            delete outletPayload.__v;

            dispatch(updateOutlet(outlet?.id, outletPayload));
          }
          setisLoading(false);
          Swal.fire("Success", "Deal successfully created", "success").then(
            (res) => {
              dispatch(setEventItem(null));
              searchQuery
                ? navigate(`/events?search=${searchQuery}`)
                : navigate("/events");
            }
          );
        })
        .catch((err) => {
          setisLoading(false);
          Swal.fire(
            "Error",
            err?.response?.data?.message ?? "Something went wrong",
            "error"
          );
        });
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "draft") {
      setState({ ...state, isDraft: true, status: false });
    } else {
      setState({
        ...state,
        isDraft: false,
        status: JSON.parse(event.target.value),
      });
    }
  };
  return (
    <div>
      <Navbar />
      {isLoading && <Loader />}
      {/* <Breadcrumb
        breadFirst="Home"
        breadSec="Deals"
        breadLast="Edit Deal"
        linkFirst="/"
        linkSec="/events"
      /> */}
      <div className="bradcrumbs" role="presentation">
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} className="flex items-center">
            <div className="mr4 c-pointer left-arrow ">
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/events?search=${searchQuery}` : "/events"}
              >
                <ArrowBackIosNew />
              </Link>{" "}
            </div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {user && user.role === "admin" ? (
                <Link underline="hover" href="/">
                  Home
                </Link>
              ) : (
                ""
              )}
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/events?search=${searchQuery}` : "/events"}
              >
                Deals
              </Link>
              <Typography>Edit Deal</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
            <MoreHoriz />
          </Grid>
        </Grid>
      </div>
      <div className="container-main">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="venue-name flex items-center">
              <span className="cir-icon mr2">
                <span className="dot-text">{getInitials(state.eventName)}</span>
              </span>
              <div>
                <label style={{ fontSize: "20px" }}>{state.offerName}</label>
                <div
                  className="flex items-center"
                  style={{ marginTop: "10px" }}
                >
                  <label style={{ fontSize: "18px" }}>{state.eventName}</label>
                  {/* {params?.id && (
                    <div className="icon-yellow ml2">
                      <DriveFileRenameOutline />
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="flex items-center flex-wrap fr-ns fl-m">
              {/* <div className="ma2">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.isFeatured}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isFeatured ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setState({ isFeatured: !state.isFeatured })
                      }
                    />
                  }
                  label="Set as Featured"
                />
              </div> */}
              {/* <div className="">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={stateOffer.isPromotional}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        stateOffer.isPromotional ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setStateOffer({ isPromotional: !stateOffer.isPromotional })
                      }
                    />
                  }
                  label="Set as Promotional"
                />
              </div> */}
              {/* <div className="">
                {params?.id && (
                  <Button
                    type="button"
                    className="button-sm box-shadow bg-secondry-yellow flex items-center"
                    onClick={handleDuplicate}
                  >
                    <div className="plus-ico">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Vector_Smart_Object"
                          data-name="Vector Smart Object"
                          transform="translate(-1046 -239)"
                        >
                          <g
                            id="Vector_Smart_Object-2"
                            data-name="Vector Smart Object"
                            transform="translate(1042.667 235.667)"
                          >
                            <path
                              id="Path_22"
                              data-name="Path 22"
                              d="M8.889,17.778H6.667a2.222,2.222,0,0,1-2.222-2.222V6.667A2.222,2.222,0,0,1,6.667,4.444h8.889a2.222,2.222,0,0,1,2.222,2.222V8.889M11.111,22.222H20A2.222,2.222,0,0,0,22.222,20V11.111A2.222,2.222,0,0,0,20,8.889H11.111a2.222,2.222,0,0,0-2.222,2.222V20A2.222,2.222,0,0,0,11.111,22.222Z"
                              fill="none"
                              stroke="#4a5568"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.222"
                            />
                          </g>
                          <path
                            id="Color_Overlay"
                            data-name="Color Overlay"
                            d="M1053.778,257.889a2.222,2.222,0,0,1-2.222-2.222v-2.222l8.889-8.889h2.222a2.222,2.222,0,0,1,2.222,2.223v8.888a2.222,2.222,0,0,1-2.222,2.222Zm-4.444-4.444a2.222,2.222,0,0,1-2.222-2.222v-8.889a2.222,2.222,0,0,1,2.222-2.222h8.888a2.222,2.222,0,0,1,2.223,2.222v2.222h-6.667a2.222,2.222,0,0,0-2.222,2.223v6.666Z"
                          />
                        </g>
                      </svg>
                    </div>
                    DUPLICATE
                  </Button>
                )}
              </div> */}
              {user && user.role === "admin" && (
                <div className="mr4">
                  <FormControlLabel
                    className="checkbox"
                    control={
                      <Checkbox
                        checked={state?.isAdminApprove}
                        icon={
                          <CheckBoxOutlineBlank
                            style={{ fill: "transparent" }}
                          />
                        }
                        checkedIcon={
                          state?.isAdminApprove ? (
                            <img src="/images/chck.svg" alt="check" />
                          ) : (
                            <></>
                          )
                        }
                        onChange={() =>
                          setState({ isAdminApprove: !state?.isAdminApprove })
                        }
                      />
                    }
                    label="Approve Deal"
                  />
                </div>
              )}
              {((user && user.role === "vendor" && state?.isAdminApprove) ||
                (user && user.role === "admin")) && (
                <div className="ma2 btn-dropdown">
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <Select
                      // labelId="publish"
                      // id="publish"
                      value={
                        state.isDraft
                          ? "draft"
                          : state.status
                          ? "true"
                          : "false"
                      }
                      onChange={handleChange}
                    >
                      <MenuItem value="true">Published</MenuItem>
                      <MenuItem value="draft">Draft</MenuItem>
                      <MenuItem value="false">Un-Published</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={12} lg={9} className="m-auto">
          <div className="mb-2 mt4">
            <Grid item container lg={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative w-100">
                  <Autocomplete
                    // disableCloseOnSelect
                    id={"offer-outlets-list"}
                    options={outletsList}
                    value={
                      selectedOutlet
                        ? outletsList.find(
                            (item) => item?.id === selectedOutlet
                          )
                        : null
                    }
                    getOptionLabel={(option) => option.outletName}
                    filterSelectedOptions={true}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option?.outletName}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Choose listings by name"
                      />
                    )}
                    onChange={(evt, outlet) => {
                      if (outlet) {
                        setSelectedOutlet(outlet?.id);
                        setState({ eventName: outlet?.outletName });
                        let updatedEventItem = {};
                        if (event) {
                          updatedEventItem = { ...event };
                          updatedEventItem.eventName = outlet.outletName;
                          updatedEventItem.outletId = outlet.id;
                        }
                        updatedEventItem = { ...updatedEventItem, ...outlet };
                        dispatch(setEventItem(updatedEventItem));
                        setState({
                          eventName: outlet?.outletName,
                          associatedVenueName:
                            outlet?.associatedVenueName ?? null,
                          restaurantsOrBars: outlet?.restaurantsOrBars ?? [],
                        });
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.addressLine1}
                    onChange={(evt) =>
                      setState({ addressLine1: evt?.target.value })
                    }
                    placeholder="Address Line 1"
                    className="w-100 input-square"
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="email"
                    value={state.email}
                    onChange={(evt) => setState({ email: evt?.target.value })}
                    placeholder="john.doe@example.com"
                    className="w-100 input-square"
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input
                    type="text"
                    value={state.addressLine2}
                    onChange={(evt) =>
                      setState({ addressLine2: evt?.target.value })
                    }
                    placeholder="Address Line 2"
                    className="w-100 input-square"
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  className="checkbox pl3"
                  control={
                    <Checkbox
                      checked={state.isLogoForOutlet}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isLogoForOutlet ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setState({ isLogoForOutlet: !state.isLogoForOutlet })
                      }
                      disabled={true}
                    />
                  }
                  label="Use standard listing logo"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControlLabel
                  className="checkbox pl3"
                  control={
                    <Checkbox
                      checked={state.isBannerForOutlet}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isBannerForOutlet ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setState({
                          isBannerForOutlet: !state.isBannerForOutlet,
                        })
                      }
                      disabled={true}
                    />
                  }
                  label="Use standard listing main image"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input
                    type="file"
                    name="logoimg"
                    id="logo-file-chooser"
                    style={{ display: "none" }}
                    onChange={handleLogoUpload}
                    disabled={true}
                  />
                  <div
                    className="camera-ico bg-white-circle box-shadow"
                    onClick={openLogoChooser}
                  >
                    <CameraAlt />
                  </div>
                  {state?.logoImage ? (
                    <img
                      src={state.logoImage}
                      alt="Add Logo"
                      className="logo-banner-image"
                    />
                  ) : (
                    <div
                      className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openLogoChooser}
                    >
                      Click to add Logo
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input
                    type="file"
                    name="bannerimg"
                    id="banner-file-chooser"
                    style={{ display: "none" }}
                    onChange={handleBannerUpload}
                    disabled={true}
                  />
                  <div
                    className="camera-ico bg-white-circle box-shadow"
                    onClick={openBannerChooser}
                  >
                    <CameraAlt />
                  </div>
                  {state?.bannerImage ? (
                    <img
                      src={state.bannerImage}
                      alt="Add Banner"
                      className="logo-banner-image"
                    />
                  ) : (
                    <div
                      className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openBannerChooser}
                    >
                      Click to add banner image
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="slider w-100 mt4 mb4 flex flex-wrap">
            {state?.otherImages?.map((otherImg, index) => (
              <div
                className="image-container relative"
                key={`image-container${index}`}
              >
                {/* {!params?.id && <div
                  className="clear-ico"
                  onClick={() => removeOtherImage(index)}
                >
                  <Cancel />
                </div>} */}
                <img src={otherImg} alt="Outlet Images" className="relative" />
              </div>
            ))}
            {/* {!params?.id && <div
              className="image-container white-txt flex justify-center items-center"
              onClick={openOtherImagesChooser}
            >
              <input
                type="file"
                name="otherimg"
                id="other-images-file-chooser"
                style={{ display: "none" }}
                onChange={handleOtherImagesUpload}
              />
              <Add />
            </div>} */}
          </div>
          <div className="description pa2 tc border w-100">
            <h3 className="font-18-regular pb2">Description of the deal.</h3>
            <textarea
              rows="5"
              value={state.description}
              onChange={(evt) => setState({ description: evt?.target.value })}
              placeholder="Description"
              disabled={true}
            ></textarea>
          </div>

          <div className="page-bottom w-100">
            <div className="flex w-100 mb3 justify-between items-center">
              <h2 className="h1 weight-semi-bold">Deal Timing</h2>
            </div>
            <Grid
              item
              container
              sm={12}
              md={12}
              lg={12}
              className="w-100 table-ltx"
            >
              <Grid item sm={12} md={12} lg={6}>
                <table className="day-time-edit-wrapper tabel-container-list">
                  {state.timings?.map((dayTime, index) => (
                    <tr key={`event-timing${index}`}>
                      <td className="w-20">{dayTime?.day}</td>
                      <td className="w-80">
                        <div className="flex items-center">
                          <div className="grey-box">
                            <input
                              type="text"
                              value={dayTime.startTime}
                              onChange={(evt) => {
                                const updatedTimings = [...state.timings];
                                updatedTimings[index].startTime =
                                  evt.target.value;
                                setState({ timings: updatedTimings });
                              }}
                              disabled={true}
                            />
                          </div>
                          <div className="mr2 ml2 text-grey">
                            <HorizontalRule />
                          </div>
                          <div className="grey-box">
                            <input
                              type="text"
                              value={dayTime.endTime}
                              onChange={(evt) => {
                                const updatedTimings = [...state.timings];
                                updatedTimings[index].endTime =
                                  evt.target.value;
                                setState({ timings: updatedTimings });
                              }}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </Grid>
              <Grid item sm={12} md={12} lg={6}>
                <table className="outlet-other-details tabel-container-list">
                  <tr>
                    <td>Dresscode</td>
                    <td>
                      <select
                        value={state?.dressCode}
                        placeholder="Dress Codes"
                        defaultValue={state?.dressCode}
                        onChange={(evt) =>
                          setState({ dressCode: evt.target.value })
                        }
                        disabled={true}
                      >
                        {DRESS_CODES?.map((item) => (
                          <option value={item} key={item}>
                            {item.slice(0, -1)}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>
                      <select
                        value={state.price}
                        placeholder="Price"
                        onChange={(evt) =>
                          setState({ price: evt.target.value })
                        }
                        disabled={true}
                      >
                        {PRICE?.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Brunch Price</td>
                    <td>
                      <input
                        type="text"
                        value={state.brunchPrice}
                        placeholder=""
                        onChange={(evt) =>
                          setState({ ...state, brunchPrice: evt.target.value })
                        }
                        disabled={true}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Brunch Feature</td>
                    <td className="flex">
                      {FACILITIES?.map((item) => (
                        <div className="flex items-center justify-start mr3 pv3">
                          <div>
                            <input
                              type="checkbox"
                              value={item}
                              key={item}
                              style={{ width: 30, height: 16 }}
                              checked={
                                state.facilities?.findIndex(
                                  (fac) => fac === item
                                ) > -1
                              }
                              onChange={() => {
                                const { facilities } = state;
                                const exIndex = state.facilities?.findIndex(
                                  (fac) => fac === item
                                );
                                if (exIndex > -1) facilities.splice(exIndex, 1);
                                else facilities.push(item);

                                setState({ facilities });
                              }}
                              disabled={true}
                            />
                          </div>
                          <div>{item}</div>
                        </div>
                      ))}
                    </td>
                  </tr> */}
                  <tr>
                    <td>Brunch Feature</td>
                    <td>
                      <select
                        value={state?.facilities}
                        placeholder="Brunch Feature"
                        onChange={(evt) => {
                          const { facilities } = state;
                          const val = evt?.target?.value;
                          const exIndex = state.facilities?.findIndex(
                            (facilities) => facilities === val
                          );
                          if (exIndex > -1) facilities.splice(exIndex, 1);
                          else facilities.push(val);

                          setState({ facilities });
                        }}
                        disabled={true}
                        multiple
                      >
                        {FACILITIES?.map((item) => {
                          return (
                            <option
                              value={item}
                              key={item}
                              selected={
                                state.facilities?.findIndex(
                                  (facilities) => facilities === item
                                ) > -1
                              }
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Cuisine</td>
                    <td>
                      <select
                        value={state.cuisines}
                        placeholder="Cuisines"
                        onChange={(evt) => {
                          const { cuisines } = state;
                          const val = evt?.target?.value;
                          const exIndex = state.cuisines?.findIndex(
                            (cuisine) => cuisine === val
                          );
                          if (exIndex > -1) cuisines.splice(exIndex, 1);
                          else cuisines.push(val);

                          setState({ cuisines });
                        }}
                        // value={state.cuisines}
                        multiple
                        disabled={true}
                      >
                        {CUISINES?.map((item) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              state.cuisines?.findIndex(
                                (cuisine) => cuisine === item
                              ) > -1
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>GPS Location</td>
                    <td>
                      <input
                        type="text"
                        value={state.coords?.join(",")}
                        placeholder=""
                        onChange={(evt) =>
                          setState({ coords: evt.target.value?.split(",") })
                        }
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Booking Link</td>
                    <td>
                      <input
                        type="text"
                        value={state.bookingLink}
                        placeholder="https://letsdxb.com"
                        onChange={(evt) =>
                          setState({ bookingLink: evt.target.value })
                        }
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Whatsapp Business Link</td>
                    <td>
                      <input
                        type="text"
                        value={state.whatsappBusinessLink}
                        placeholder="https://wa.me/1234567890"
                        onChange={(evt) =>
                          setState({ whatsappBusinessLink: evt.target.value })
                        }
                        disabled={true}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Venue (Hotel/Area) Rating</td>
                    <td>
                      <select
                        value={state.venueRating}
                        onChange={(evt) =>
                          setState({ venueRating: evt?.target?.value })
                        }
                        disabled={true}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </td>
                  </tr> */}
                  <tr>
                    <td>Cuisine Class</td>
                    <td>
                      <select
                        value={state?.cuisineClass}
                        onChange={(evt) =>
                          setState({ cuisineClass: evt?.target?.value })
                        }
                        disabled={true}
                      >
                        <option>Select Cuisine Class</option>
                        <option value={"Casual"}>Casual</option>
                        <option value={"Fine Dining"}>Fine Dining</option>
                        <option value={"All"}>All</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </Grid>
            </Grid>
            {/*<div className="w-100 mt5 tc btn-space">
              <Button type="button" className="btn-primary" onClick={addUpdateEvent}>
                {event ? 'Update' : 'Save'}
              </Button>
            </div>*/}
            <div className="checkboxes mt4 w-100 flex flex-wrap justify-around">
              <div className="items-center pa2">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.isRestaurant}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isRestaurant ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() =>
                        setState({ isRestaurant: !state.isRestaurant })
                      }
                      disabled={true}
                    />
                  }
                  label="List as Restaurant (Let's Eat & Drink)"
                />
              </div>

              {/* <div className="items-center pa2">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.isBar}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isBar ? <img src="/images/chck.svg" alt="check" /> : <></>
                      }
                      onChange={() => setState({ isBar: !state.isBar })}
                      disabled={true}
                    />
                  }
                  label="List as Bar (Let's Drink)"
                />
              </div> */}

              <div className="items-center pa2">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.isBrunch}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isBrunch ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() => setState({ isBrunch: !state.isBrunch })}
                      disabled={true}
                    />
                  }
                  label="List as Brunch (Let's Brunch)"
                />
              </div>

              <div className="items-center pa2">
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={state.isExplore}
                      icon={
                        <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                      }
                      checkedIcon={
                        state.isExplore ? (
                          <img src="/images/chck.svg" alt="check" />
                        ) : (
                          <></>
                        )
                      }
                      onChange={() => setState({ isExplore: !state.isExplore })}
                      disabled={true}
                    />
                  }
                  label="List as Explore (Let's Explore)"
                />
              </div>
            </div>
            <div className="w-100">
              <div className="flex w-100 mb3 justify-between items-center">
                <h2 className="h1 weight-semi-bold">Deal</h2>
              </div>
              <Grid item container lg={12} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="flex relative">
                    <input
                      type="text"
                      value={stateOffer.offerName}
                      placeholder="Deal Title"
                      onChange={(evt) =>
                        setStateOffer({ offerName: evt.target.value })
                      }
                      className="w-100 input-square"
                    />
                  </div>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className="flex items-center"
                >
                  <span className="ml5 mr3">Discount</span>
                  <input
                    type="text"
                    value={stateOffer.discount}
                    placeholder="Discount Percentage"
                    onChange={(evt) =>
                      setStateOffer({ discount: evt.target.value })
                    }
                    className="w-100 input-square"
                  />
                </Grid> */}
              </Grid>
            </div>
          </div>
          <div className="description pa2 mt4 mb4 tc border w-100">
            <h3 className="font-18-regular pb2">
              Terms &amp; conditions here.
            </h3>
            <textarea
              rows="5"
              value={stateOffer.description}
              onChange={(evt) =>
                setStateOffer({ description: evt?.target.value })
              }
              placeholder="Description"
            ></textarea>
          </div>
          <Grid
            item
            container
            lg={12}
            spacing={2}
            className="flex items-center"
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <h2 className="h1 weight-semi-bold">Deal expiry date</h2>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5}
              className="flex items-center"
            >
              <div className="flex w-100 relative">
                <input
                  type="date"
                  value={stateOffer.expiryDate}
                  placeholder="Expiry Date"
                  onChange={(evt) =>
                    setStateOffer({ expiryDate: evt.target.value })
                  }
                  className="w-100 input-square"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className="flex items-center justify-center"
            >
              <div className="mr3">
                <Button
                  type="button"
                  className="button-sm normal-btn-space box-shadow  flex items-center pa3"
                  onClick={addUpdateOffer}
                >
                  SAVE &amp; UPDATE
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {event ? (
          <div className="tc">
            <Button
              variant="text"
              className="delete font-16-semiBold underline"
              onClick={() => handleDeleteEvent(event?.id)}
            >
              DELETE DEAL
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default EditEvent;
