import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography, Grid } from '@mui/material';
import { ArrowBackIosNew, MoreHoriz } from '@mui/icons-material';
import './breadcrumb.scss';

const BreadcrumbDouble = ({ breadFirst, breadLast, linkFirst }) => {

  const navigate  = useNavigate();

  return (

    <div className="bradcrumbs" role="presentation">
      <Grid container>
        <Grid item xs={11} sm={11} lg={11} className="flex items-center">
        <div className="mr4 c-pointer left-arrow" onClick={() => navigate(-1)}> <ArrowBackIosNew /> </div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link underline="hover" href={linkFirst}>
          {breadFirst}
        </Link>
        <Typography>{breadLast}</Typography>
        </Breadcrumbs>
        </Grid>
        <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
           <MoreHoriz />
        </Grid> 
      </Grid>
    </div>
  )
}

export default BreadcrumbDouble;