import React from 'react'
export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if(sortConfig.key.split("?").length>1)
          {
            let cpy=sortConfig.key.split("?")
            let obj=cpy[1]?.split(".")[1]
            if (a[cpy[0]]?.[obj] < b[cpy[0]]?.[obj]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[cpy[0]]?.[obj] > b[cpy[0]]?.[obj]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;  
          }
          else{
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          }
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };