import moment from "moment";
import React from "react";

const TableEdit = ({ id, item }) => {
  return (
    <>
      {/* // <tr>
        //     <td>{item?.dealName?.itemDetails}</td>
        //     <td>{item?.venue} </td>
        //     <td>{item?.redDate}  </td>
        // </tr> */}
      <tr>
        <td>
          {" "}
          <div className="flex items-center">
            <div className="user-img">
              <img
                src={item?.itemDetails?.bannerImage ?? "/images/feat-img.png"}
                alt="item banner"
              />
            </div>
            {item?.itemType === "event"
              ? item?.itemDetails?.eventName ??
                item?.itemDetails?.offer?.offerName
              : item?.itemDetails?.events?.length > 0
              ? item?.itemDetails?.events[0]?.offer?.offerName
              : item?.itemDetails?.outletName}
          </div>{" "}
        </td>
        <td>
          {" "}
          <div className="flex items-center">
            <div className="user-img">
              <img
                src={item?.itemDetails?.logoImage ?? "/images/feat-img.png"}
                alt="item logo"
              />
            </div>
            {item?.itemDetails?.eventName ?? item?.itemDetails?.outletName}
          </div>
        </td>
        {/* <td>{item?.user?.email}</td> */}
        <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
        <td>{item?.code ?? "-"}</td>
      </tr>
    </>
  );
};

export default TableEdit;
