import imageCompression from 'browser-image-compression';



export const handleCompressedUpload = async (imageFile) => {
          // const imageFile = event.target.files[0];
          // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
          // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
          // console.log(`originalFile size ${imageFile.size}`);
  
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          }
            const compressedFile = await imageCompression(imageFile, options);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            // console.log(`compressedFile size ${compressedFile.size}`); // smaller than maxSizeMB
            // await uploadToServer(compressedFile); // write your own logic

            return compressedFile
      
  
  };