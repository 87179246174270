import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <Backdrop
      sx={{
        color: '#512397',
        zIndex: 999999,
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
