import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Chip, Stack, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import {
  // CheckBox as CheckBoxIcon,
  CameraAlt,
  Add,
  Cancel
} from '@mui/icons-material';
import Swal from 'sweetalert2';

import Navbar from '../../components/navbar';
import { initReducer, uploadFile } from '../../utils/common-methods';
import './venues.scss';
import { addVenue, deleteVenue, setVenueItem, updateVenue } from '../../redux/reducers/venues';
import { handleCompressedUpload } from '../../utils/ImageCompress';
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ArrowBackIosNew, MoreHoriz } from '@mui/icons-material';
import Loader from "../../components/loader";

const EditVenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');

  const { venue, isLoading } = useSelector(store => ({
    venue: store.venues.venue,
    isLoading: store.venues.isLoading
  }), shallowEqual);

  const initialState = {
    venueName: '',
    addressLine1: '',
    addressLine2: '',
    email: '',
    mobile: '',
    country: 'Dubai',
    logoImage: null,
    bannerImage: null,
    otherImages: [],
    description: '',
    keywords: [],
    isActive: true,
    isFeatured: false,
    restaurantsOrBars: []
  };

  const [state, setState] = useReducer(initReducer, initialState);
  const [showAddTag, setShowTag] = useState(false);
  const [active, setActive] = React.useState('');
  const [tag, setTag] = useState('');
  const [restaurantTag, setRestaurantTag] = useState('');
  const [showRestaurantAddTag, setShowRestaurantTag] = useState(false);

  // const [outlets, setoutletList] = useState([]) 
  // const [isLoading, setisLoading] = useState(false);

  // useEffect(() => {
  // dispatch(fetchAllOutlets(1));
  // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (!venue) return;

    setState({
      venueName: venue?.venueName ?? '',
      addressLine1: venue?.addressLine1 ?? '',
      addressLine2: venue?.addressLine2 ?? '',
      email: venue?.email ?? '',
      mobile: venue?.mobile ?? '',
      country: venue?.country ?? 'Dubai',
      logoImage: venue?.logoImage ?? null,
      bannerImage: venue?.bannerImage ?? null,
      otherImages: venue?.otherImages ?? [],
      description: venue?.description ?? '',
      keywords: venue?.keywords ?? [],
      isActive: venue?.isActive ?? true,
      isFeatured: venue?.isFeatured ?? false,
      restaurantsOrBars: venue?.restaurantsOrBars ?? []
    });

  }, [venue]);

  const openLogoChooser = () => {
    if (window != undefined && document != undefined) {
      document.getElementById('logo-file-chooser').click();
    }
  };

  const openBannerChooser = () => {
    if (window != undefined && document != undefined) {
      document.getElementById('banner-file-chooser').click();
    }
  };

  const openOtherImagesChooser = () => {
    if (window != undefined && document != undefined) {
      document.getElementById('other-images-file-chooser').click();
    }
  };

  const handleLogoUpload = async evt => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file)
      const resp = await uploadFile(compressImg);
      if (resp?.filePath) {
        setState({ logoImage: resp?.filePath });
        Swal.fire(
          'Success',
          'Logo image uploaded',
          'success'
        );
      }
    }
  };

  const handleBannerUpload = async evt => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;

    if (file) {
      const compressImg = await handleCompressedUpload(file)
      const resp = await uploadFile(compressImg);
      if (resp?.filePath) {
        setState({ bannerImage: resp?.filePath });
        Swal.fire(
          'Success',
          'Banner image uploaded',
          'success'
        );
      }
    }
  };

  const handleOtherImagesUpload = async evt => {
    const file = evt.target?.files?.length ? evt?.target?.files[0] : null;
    const { otherImages } = state || [];

    if (file) {
      const compressImg = await handleCompressedUpload(file)
      const resp = await uploadFile(compressImg);
      if (resp?.filePath) {
        otherImages.push(resp?.filePath);
        setState({ otherImages });
        Swal.fire(
          'Success',
          'Gallery image uploaded.',
          'success'
        );
      }
    }
  };

  const removeOtherImage = index => {
    const { otherImages } = state;

    if (otherImages?.length && index < otherImages?.length) {
      otherImages.splice(index, 1);

      setState({ otherImages });
    }
  };

  const handleDeleteTag = (index) => {
    const { keywords } = state;
    keywords.splice(index, 1);
    setState({ keywords });
  };

   const handleDeleteRestaurantTag = (index) => {
    const { restaurantsOrBars } = state;
    restaurantsOrBars.splice(index, 1);
    setState({ restaurantsOrBars });
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure you want to delete this Venue (Hotel/Area) ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: 'Keep'
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteVenue(venue?.id, searchQuery))
          .then(() => {
            Swal.fire({
              title: 'Venue (Hotel/Area) successfully deleted.',
              timer: 2500,
              icon: 'success'
            });


            searchQuery ? navigate(`/venues?search=${searchQuery}`) : navigate('/venues');
          })
          .catch(() => {
            Swal.fire({
              title: 'There was error deleting Venue (Hotel/Area). Try again.',
              timer: 3000,
              icon: 'error'
            });
          });
      }
    });
  };

  const handleDuplicate = () => {
    dispatch(setVenueItem(state));
    navigate('/venues/add');
    Swal.fire(
      'Duplicated',
      'Data duplicated. Please make changes to save as a new item.',
      'success'
    );
  };

  const handleSubmit = () => {
    const {
      venueName, addressLine1, addressLine2,
      email, mobile, country, description,
      logoImage, bannerImage, otherImages,
      isActive, isFeatured, keywords, restaurantsOrBars
    } = state;

    if (!venueName.trim()){
      Swal.fire('Error', "Venue name is required", 'error');
      return;
    }

     if (!country.trim()){
      Swal.fire('Error', "Country is required", 'error');
      return;
    }

    const payload = {
      venueName, addressLine1, addressLine2,
      email, mobile, description, isActive,
      logoImage, bannerImage, isFeatured,
      country: country ?? 'Dubai',
      keywords, restaurantsOrBars,
    };

    if (otherImages?.length) payload.otherImages = otherImages;

    if (venue?.id) {
      dispatch(updateVenue(venue?.id, payload, searchQuery))
    } else {
      dispatch(addVenue(payload, searchQuery))
    }
    Swal.fire('Success', `Venue (Hotel/Area) successfully ${venue ? 'updated' : 'added'}`, 'success');
    searchQuery ? navigate(`/venues?search=${searchQuery}`) : navigate('/venues');

  };

  const handleChange = (event) => {
    setActive(event.target.value);
  };

  // useEffect(() => {
  //   _outletList()
  // }, [])

  // const _outletList=()=>{
  //   setisLoading(true)
  //   api.get(OUTLET_APIS.outlets).then(response => {
  //   // console.log('response: ', response);
  //     if(response.status==200){
  //       setoutletList(response.data.data)
  //     }
  //     setisLoading(false)
  //   }).catch(err=>{
  //   console.log('err: ', err);
  //   setisLoading(false)
  //   })
  // }

  return (
    <div>
      <Navbar />
      {isLoading && <Loader />}
      {/* <Breadcrumb 
      breadFirst="Home" 
      breadSec="Venue (Hotel/Area)" 
      breadLast="Edit Venue (Hotel/Area)" 
      linkFirst="/" 
      linkSec="/venues" /> */}

      <div className="bradcrumbs" role="presentation">
        <Grid item container>
          <Grid item xs={11} sm={11} lg={11} className="flex items-center">
            <div className="mr4 c-pointer left-arrow ">
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/venues?search=${searchQuery}` : "/venues"}
              >
                <ArrowBackIosNew />
              </Link> </div>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link underline="hover" href="/">
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={searchQuery ? `/venues?search=${searchQuery}` : "/venues"}
              >
                Venue (Hotel/Area)
              </Link>
              <Typography>Edit Venue (Hotel/Area)</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={1} sm={1} lg={1} className="tr text-grey">
            <MoreHoriz />
          </Grid>
        </Grid>
      </div>

      <div className="container-main">
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="venue-name flex items-center">
              <span className="cir-icon mr2">
                <img src="/images/logo-sm.png" alt="logo" />
              </span>
              <h1 className="w-50">{state.venueName}</h1>
              {/* {venue && <div className="icon-yellow ml4">
                <DriveFileRenameOutline/>
              </div>} */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="flex flex-wrap items-center mt2 fr-ns fl-m">
              {/*<div className="mr4">
                <FormControlLabel
                  className="checkbox"
                  control={<Checkbox
                    checked={state.isFeatured}
                    icon={<CheckBoxOutlineBlank style={{ fill: 'transparent' }}/>}
                    checkedIcon={state.isFeatured ? <img src="/images/chck.svg"/> : <></>}
                    onChange={() => setState({ isFeatured: !state.isFeatured })}
                  />}
                  label="Set as Featured"/>
              </div>*/}

              <div className="mr3 btn-dropdown">
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="active">ACTIVE</InputLabel>
                  <Select
                    labelId="active"
                    id="active"
                    value={active}
                    label="Search and Select by Name..."
                    onChange={handleChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {venue?.id && <button type="button" className="button-sm box-shadow bg-secondry-yellow mr3 flex items-center" onClick={handleDuplicate}>
                <div className="plus-ico">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="Vector_Smart_Object" data-name="Vector Smart Object" transform="translate(-1046 -239)">
                      <g id="Vector_Smart_Object-2" data-name="Vector Smart Object"
                        transform="translate(1042.667 235.667)">
                        <path id="Path_22" data-name="Path 22"
                          d="M8.889,17.778H6.667a2.222,2.222,0,0,1-2.222-2.222V6.667A2.222,2.222,0,0,1,6.667,4.444h8.889a2.222,2.222,0,0,1,2.222,2.222V8.889M11.111,22.222H20A2.222,2.222,0,0,0,22.222,20V11.111A2.222,2.222,0,0,0,20,8.889H11.111a2.222,2.222,0,0,0-2.222,2.222V20A2.222,2.222,0,0,0,11.111,22.222Z"
                          fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth="2.222" />
                      </g>
                      <path id="Color_Overlay" data-name="Color Overlay"
                        d="M1053.778,257.889a2.222,2.222,0,0,1-2.222-2.222v-2.222l8.889-8.889h2.222a2.222,2.222,0,0,1,2.222,2.223v8.888a2.222,2.222,0,0,1-2.222,2.222Zm-4.444-4.444a2.222,2.222,0,0,1-2.222-2.222v-8.889a2.222,2.222,0,0,1,2.222-2.222h8.888a2.222,2.222,0,0,1,2.223,2.222v2.222h-6.667a2.222,2.222,0,0,0-2.222,2.223v6.666Z" />
                    </g>
                  </svg>
                </div>
                DUPLICATE
              </button>}
              {/* <button type="button" className="button-sm box-shadow flex items-center" onClick={() => navigate('/venues/add')}>
                <div className="plus-ico" onClick={() => navigate('/outlets/add')}><Add /></div>
                CREATE LISTING
              </button> */}
            </div>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={12} lg={9} className="m-auto">
          <div className="mb-2 mt3">
            <Grid item container lg={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="text" value={state.venueName}
                    onChange={evt => setState({ venueName: evt?.target.value })} placeholder="Venue (Hotel/Area) Name"
                    className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="text" value={state.addressLine1}
                    onChange={evt => setState({ addressLine1: evt?.target.value })} placeholder="Address Line 1"
                    className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="email" value={state.email} onChange={evt => setState({ email: evt?.target.value })}
                    placeholder="john.doe@example.com" className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="text" value={state.addressLine2}
                    onChange={evt => setState({ addressLine2: evt?.target.value })} placeholder="Address Line 2"
                    className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="text" value={state.mobile} onChange={evt => setState({ mobile: evt?.target.value })}
                    placeholder="Phone Number" className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="flex relative">
                  <input type="text" value={state.country} onChange={evt => setState({ country: evt?.target.value })}
                    placeholder="Country" className="w-100 input-square" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input type="file" name="logoimg" id="logo-file-chooser" style={{ display: 'none' }}
                    onChange={handleLogoUpload} />
                  <div className="camera-ico bg-white-circle box-shadow" onClick={openLogoChooser}>
                    <CameraAlt />
                  </div>
                  {state?.logoImage ? <img src={state.logoImage} alt="Add Logo" className="logo-banner-image" /> :
                    <div className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openLogoChooser}>Click to add Logo</div>}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="venue-img">
                  <input type="file" name="bannerimg" id="banner-file-chooser" style={{ display: 'none' }}
                    onChange={handleBannerUpload} />
                  <div className="camera-ico bg-white-circle box-shadow" onClick={openBannerChooser}>
                    <CameraAlt />
                  </div>
                  {state?.bannerImage ? <img src={state.bannerImage} alt="Add Banner" className="logo-banner-image" /> :
                    <div className="empty-logo-box w-100 flex items-center justify-center"
                      onClick={openBannerChooser}>Click to add banner image</div>}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="slider edit-image w-100 mt4 mb4 flex flex-wrap justify-between items-center">
            {state?.otherImages?.map((otherImg, index) => (<div className="image-container relative" key={index}>
              <div className="clear-ico" onClick={() => removeOtherImage(index)}>
                <Cancel />
              </div>
              <img src={otherImg} alt="Outlet Images" />
            </div>))}
            <div className="image-container white-txt flex justify-center items-center"
              onClick={openOtherImagesChooser}>
              <input type="file" name="otherimg" id="other-images-file-chooser" style={{ display: 'none' }}
                onChange={handleOtherImagesUpload} />
              <Add />
            </div>
          </div>
          <div className="description pa2 tc border w-100">
            <h3 className="font-18-regular pb2">Description of the venue (Hotel/Area).</h3>
            <textarea rows="5" value={state.description} onChange={evt => setState({ description: evt?.target.value })}
              placeholder="Description"></textarea>
          </div>
          <div className="page-bottom w-100">
            <div className="flex w-100 justify-between items-center">
              <h2 className="h1 weight-semi-bold heading">Keywords</h2>
              <button type="button" className="button-sm bg-purple flex items-center" onClick={() => setShowTag(!showAddTag)}>
                <div className="plus-ico">{showAddTag ? 'x' : <Add />}</div>
                {showAddTag ? 'CLOSE' : 'ADD NEW TAG'}
              </button>
            </div>
            {showAddTag && <div className="add-stuff-wrapper flex items-center justify-center">
              <input type="text" onChange={evt => setTag(evt.target.value)} value={tag} />
              <button
                onClick={() => {
                  if (tag) {
                    setState({ keywords: [...state.keywords, tag] });
                    setTag('');
                  }
                }}
              >Add
              </button>
            </div>}
            {state.keywords?.length > 0 && <div className="description pa4 tc border mt4">
              <Stack direction="row" className="flex flex-wrap" spacing={1}>
                {state.keywords?.map((tag, index) => (
                  <Chip label={tag} variant="outlined" onDelete={() => handleDeleteTag(index)} key={index} />))}
              </Stack>
            </div>}
          </div>
          <div className="page-bottom w-100">
            <div className="flex w-100 justify-between items-center">
              <h2 className="h1 weight-semi-bold heading">Outlets</h2>
              <button type="button" className="button-sm bg-purple flex items-center" onClick={() => setShowRestaurantTag(!showRestaurantAddTag)}>
                <div className="plus-ico">{showRestaurantAddTag ? 'x' : <Add />}</div>
                {showRestaurantAddTag ? 'CLOSE' : 'ADD NEW OUTLETS'}
              </button>
            </div>
            {showRestaurantAddTag && <div className="add-stuff-wrapper flex items-center justify-center">
              <input type="text" onChange={evt => setRestaurantTag(evt.target.value)} value={restaurantTag} />
              <button
                onClick={() => {
                  if (restaurantTag) {
                    setState({ restaurantsOrBars: [...state.restaurantsOrBars, restaurantTag] });
                    setRestaurantTag('');
                  }
                }}
              >Add
              </button>
            </div>}
            {state.restaurantsOrBars?.length > 0 && <div className="description pa4 tc border mt4">
              <Stack direction="row" className="flex flex-wrap" spacing={1}>
                {state.restaurantsOrBars?.map((restaurantTag, index) => (
                  <Chip label={restaurantTag} variant="outlined" onDelete={() => handleDeleteRestaurantTag(index)} key={index} />))}
              </Stack>
            </div>}
          </div>

          <div className="page-bottom w-100">
            <Grid item container spacing={2}>
              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                <h2 className="h1 weight-semi-bold heading">Associated Listings</h2>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={6} lg={6}> */}
              {/*<div className="fl-m fr-ns ma2">
                  <Button type="button" className="button-sm bg-light-green">
                    <div className="plus-ico">+</div>
                    ADD NEW OUTLET
                  </Button>
                </div>*/}
              {/* </Grid> */}
            </Grid>
            {/* <div className="pa4 mt4 w-100">
              <Autocomplete
                disableCloseOnSelect
                multiple
                id={'checkbox-outlets-list'}
                options={outlets ?? []}
                value={state.associatedOutlets}
                getOptionLabel={option => option.outletName}
                filterSelectedOptions={true}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.outletName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="" placeholder="Choose listings by name" />
                )}
                onChange={(evt, obj) => {
                  setState({ associatedOutlets: obj })
                }}
              />
            </div> */}

            <div className="w-100 mt5 tc btn-space">
              <Button type="button" className="btn-primary venue-edit-button" onClick={handleSubmit}>SAVE &amp; UPDATE</Button></div>

            {venue && <div className="tc">
              <Button variant="text" className="delete font-16-semiBold underline" onClick={handleDelete}>
                DELETE VENUE (HOTEL/AREA)
              </Button></div>}
          </div>
        </Grid>
      </div>
    </div>
  )
}
export default EditVenue