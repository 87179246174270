import {
  DASHBOARD_ERROR, DASHBOARD_LOADING, DASHBOARD_FEATURED, FETCH_APP_UPDATE_SETTING, UPDATE_APP_UPDATE_SETTING
} from '../state-constants';
import api, { DASHBOARD_APIS } from '../../config/api-config';
import { handle400Rejections, COMMON_API_ERR_MSG } from '../../config/constants';

const initialState = {
  error: null,
  isError: false,
  isLoading: true,
  dashboardFeatured: null
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;

    case DASHBOARD_ERROR:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };

    case DASHBOARD_FEATURED:
      return {
        ...state,
        dashboardFeatured: action.dashboardFeatured
      };

    case FETCH_APP_UPDATE_SETTING:
      return {
        ...state,
        appUpdateSetting: action.appUpdateSetting
      }

    case UPDATE_APP_UPDATE_SETTING:
      return {
        ...state,
        appUpdateSetting: state.appUpdateSetting.map((setting) => (setting.id === action.appUpdateSetting.id ? action.appUpdateSetting : setting))
      }

    case DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: action.loading,
        isError: false,
        error: null,
      };
  }
}

export default reducer;

export const handleErr = (dispatch, error) => {
  dispatch({ type: DASHBOARD_ERROR, error: error?.response?.data?.message ?? error });
};

export const setDashboardLoading = loading => {
  return dispatch => dispatch({ type: DASHBOARD_LOADING, loading });
};

export const fetchAllFeatured = () => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setDashboardLoading(true));
      api.get(DASHBOARD_APIS.dashboard)
        .then(response => {
          if (response.status > 399) {
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: DASHBOARD_FEATURED,
            dashboardFeatured: data?.data
          });

          dispatch(setDashboardLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setDashboardLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setDashboardLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
};


export const createAppUpdateSetting = (payload) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setDashboardLoading(true));
      api.post(DASHBOARD_APIS.appUpdateSetting, payload)
        .then(response => {
          if (response.status > 399) {
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          // console.log("api res->", data);

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: FETCH_APP_UPDATE_SETTING,
            appUpdateSetting: data?.data
          });

          dispatch(setDashboardLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setDashboardLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setDashboardLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
}

export const updateAppUpdateSetting = (payload) => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setDashboardLoading(true));
      api.put(DASHBOARD_APIS.appUpdateSetting, payload)
        .then(response => {
          if (response.status > 399) {
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          // console.log("update api res->", data);

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: UPDATE_APP_UPDATE_SETTING,
            appUpdateSetting: data?.data
          });

          dispatch(setDashboardLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setDashboardLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setDashboardLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
}


export const getAppUpdateSetting = () => {
  return dispatch => new Promise((resolve, reject) => {
    try {
      dispatch(setDashboardLoading(true));
      api.get(DASHBOARD_APIS.appUpdateSetting)
        .then(response => {
          if (response.status > 399) {
            handleErr(dispatch, COMMON_API_ERR_MSG);
            handle400Rejections(response.status, reject);
            return;
          }

          const { data } = response;

          // console.log("get api res->", data);

          if (!data?.data) {
            resolve({});
            return;
          }

          dispatch({
            type: FETCH_APP_UPDATE_SETTING,
            appUpdateSetting: data?.data
          });

          dispatch(setDashboardLoading(false));
          resolve(data?.data);
        })
        .catch(err => {
          dispatch(setDashboardLoading(false));
          handleErr(dispatch, err);
          reject(err);
        });
    } catch (error) {
      dispatch(setDashboardLoading(false));
      handleErr(dispatch, error);
      reject(error);
    }
  });
}