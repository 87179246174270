import React, { useEffect, useState } from "react";
import { Grid, Button, TableCell, TableRow, TableContainer, Table, TableHead, TableBody } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { Add } from "@mui/icons-material";
import Swal from "sweetalert2";
import jwt_decode from 'jwt-decode';

import { fetchAllEvents, searchEvents, setEventItem } from "../../redux/reducers/events";
import { fetchUserDetails } from "../../redux/reducers/users";
import { storageToken } from "../../config/constants";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import api, { EXPORT_APIS } from '../../config/api-config';
import { setOfferItem } from "../../redux/reducers/offers";
import Navbar from "../../components/navbar";
import Loader from "../../components/loader";
import TableList from "./table-events";
import "./events.scss";
// import { useSortableData } from "../../components/SortTable";
// import moment from "moment";

const Events = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [cnt, setcnt] = useState(0)

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');
  const [search, setSearch] = useState(searchQuery ?? "");
  const [offset, setoffset] = useState(0);
  const [sortConfig, setSortConfig] = useState(null);
  const [key, setKey] = useState(null);
  // const [searchArry, setsearchArry] = useState([]);

  const { events, isLoading, user } = useSelector(
    (store) => ({
      events: store.events.eventsList,
      isLoading: store.events.isLoading,
      user: store.auth.user
    }),
    shallowEqual
  );

  useEffect(() => {
    const userToken = window.localStorage.getItem(storageToken);
    const userDetail = jwt_decode(userToken);
    if (userDetail) {
      dispatch(fetchUserDetails(userDetail.id));
    }
  }, []);

  useEffect(() => {
    if (search?.length) {
      dispatch(searchEvents(search, key, sortConfig)).then((res) => {
        setcnt(res.length)
      });
    } else {
      dispatch(fetchAllEvents(null, offset, key, sortConfig)).then((res) => {
        setcnt(res.count)
      });
    }
  }, [offset, key, sortConfig]);

  const handleSearch = () => {
    if (search?.length) {
      dispatch(searchEvents(search, key, sortConfig)).then((res) => {
        setcnt(res.length)
      });
    } else {
      dispatch(fetchAllEvents(null, 0, key, sortConfig)).then((res) => {
        setcnt(res.count)
      });
    }
  };

  useEffect(() => {
    if (search.length === 0)
      handleSearch(search);
  }, [search]);
  // const { items, requestSort, sortConfig } = useSortableData(events);
  // const getClassNamesFor = (name) => {
  //   if (!sortConfig) {
  //     return;
  //   }
  //   return sortConfig.key === name ? sortConfig.direction : undefined;
  // };
  // const handleSearch = (e) => {
  //   let txt = e.target.value;
  //   setSearch(txt);
  //   if (txt === "") setsearchArry([...events]);
  //   else {
  //     let filterData1 = searchArry.filter((item) => {
  //       return item?.eventName?.toLowerCase().includes(txt.toLowerCase());
  //     });
  //     let filterData2 = searchArry.filter((item) => {
  //       return item?.offer?.offerName
  //         ?.toLowerCase()
  //         .includes(txt.toLowerCase());
  //     });
  //     let cpy = [...filterData1, ...filterData2];
  //     function onlyUnique(value, index, self) {
  //       return self.indexOf(value) === index;
  //     }
  //     setsearchArry(cpy.filter(onlyUnique));
  //   }
  // };

  const exportData = () => {
    api.get(EXPORT_APIS.deal)
      .then(response => {
        if (response?.data?.data) {
          response?.data?.data.map((list) => {
            delete list._id;
            delete list.id;
            delete list.associatedOffers;
            delete list.bannerImage;
            delete list.logoImage;
            delete list.orderStatus;
            delete list.otherImages;
            delete list.redemption;
            delete list.redemptions;
            delete list.updatedAt;
            list.outletName = list?.eventName;
            delete list.outlet;
            list.offerName = list.offer.offerName;
            list.offerDescription = list.offer.description;
            delete list.coords;
            delete list.createdAt;
          });
          exportFromJSON({
            data: response?.data?.data,
            fileName: "Deals",
            exportType: exportFromJSON.types.csv,
          });
        } else {
          Swal.fire(
            "Error",
            "Something went wrong. Please try again",
            "error"
          );
        }
      })
      .catch(err => {
        console.log(err)
        Swal.fire(
          "Error",
          "Something went wrong. Please try again",
          "error"
        );
      });
  }

  return (
    <div>
      <Navbar />
      {(user && user.role === "admin") ? <BreadcrumbDouble breadFirst="Home" breadLast="Deals" linkFirst="/" /> : ""}
      {isLoading && <Loader />}
      <div className="container-main">
        <Grid container className="m-auto">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className="flex items-center">
              <input
                type="text"
                className="inpt-width"
                placeholder="Search by Deals or Listings name..."
                onChange={(evt) => {
                  // handleSearch(evt);
                  setSearch(evt.target.value)
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
                value={search}
              />
              <Button className="search flex justify-center items-center" onClick={() => { handleSearch() }}>
                <img src="/images/search.png" alt="search" />
              </Button>
            </div>
              <div className="text-grey font-16-semiBold ml3">
                <span>{cnt} </span>Results
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className="tr-ns tl-m ma1">
              <Button
                type="button"
                style={{ marginRight: "10px" }}
                className="button-sm normal-btn-space"
                onClick={() => exportData()}
              >
                EXPORT
              </Button>
              <Button
                type="button"
                className="button-sm bg-purple"
                onClick={() => {
                  dispatch(setEventItem(null));
                  dispatch(setOfferItem(null));
                  navigate("/events/add");
                }}
              >
                <div className="plus-ico">
                  <Add />
                </div>
                ADD NEW
              </Button>
            </div>
          </Grid>
        </Grid>

        <div className="mt4 table-main">
          <TableContainer>
            <Table className="w-100" sx={{ minWidth: 991 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("eventName");
                  // }}
                  >
                    Deal Name
                    {/* {sortConfig && key === "eventName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("eventName");
                    }}
                  >
                    Listing
                    {sortConfig && key === "eventName" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("expiryDate");
                  // }}
                  >
                    Expiry Date
                    {/* {sortConfig && key === "expiryDate" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //   setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                  //   setKey("redemptions");
                  // }}
                  >
                    Redemptions
                    {/* {sortConfig && key === "redemptions" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>} */}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSortConfig(sortConfig === "ASC" ? "DESC" : "ASC");
                      setKey("status");
                    }}
                  >
                    Status
                    {sortConfig && key === "status" ? (sortConfig === "ASC" ? <i className="fa-solid fa-sort-up ml"></i> : <i className="fa-solid fa-sort-down ml"></i>) : <i className="fa-solid fa-sort ml"></i>}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events?.map((item, index) => (
                  <TableList item={item} key={index} search={search} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="button"
            className="btn"
            onClick={() => {
              if (offset < 0) {
                setoffset(0);
              } else {
                setoffset(offset - 10);
              }
            }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            type="button"
            className="btn"
            onClick={() => {
              setoffset(offset + 10);
            }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Events;
